import React, {useEffect, useState} from "react";
import { Header, DepartmentSettings, ShiftTypesTable } from "../components";
import { BackButton } from "../components/buttons";
import { dynoapeAPI } from "../api/dynoape";
import { useParams } from "react-router-dom";
import {Button, Card, Spinner, notifications, Checkbox, Input, Flex, ButtonGroup, Modal} from "rendition";
import {
    InputWithValidation,
    MaterialUITabs,
    SettingsContainer,
    SolidInput,
    StyledInputMask
} from "../lib/styled-components";
import Tooltip from "../lib/tooltip";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  dateInNorwegianTimezone,
  hoursToLocalTime,
  localTimeToHours,
  maxNrOfDecimalRegex,
} from "../lib/common";
import moment from "moment";
import styled from "styled-components";
import _ from "lodash";
import {DepartmentSharing} from "../components/department-sharing";
import {FeatureToggles} from "../lib/feature-toggles";
import {useForm} from "react-hook-form";
import {RouterPrompt} from "../lib/confirm/router-prompt";
import {Box, Typography} from '@mui/material';

const DateDot = () => {
  return <div
      style={{
        height: "5px",
        width: "5px",
        borderRadius: "100%",
        background: "red",
        position: "relative",
        float: "right",
        top: "9px",
        right: "7px"
      }}
  />
}

const TabPanel = ({ children, value, index }) => {
  return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && (
            <Box p={2}>
              <Typography>{children}</Typography>
            </Box>
        )}
      </div>
  );
};

const Department = () => {
  const { register} = useForm({});
  const [activeIndex, setActiveIndex] = useState("1")
  const [desiredActiveIndex, setDesiredActiveIndex] = useState(undefined)

  const [department, setDepartment] = useState({});
  const [license, setLicense] = useState();
  const [licensePeriod, setLicensePeriod] = useState();
  const [holidays, setHolidays] = useState({});
  const [countryCodeRules, setCountryCodeRules] = useState([]);
  const [fullVacancyRateHours, setFullVacancyRateHours] = useState();
  const [displayNightShiftOnNextDayInResult, setDisplayNightShiftOnNextDayInResult] = useState();
  const [showF3MinVacancyRate, setShowF3MinVacancyRate] = useState(true);
  const [f3MinVacancyRate, setF3MinVacancyRate] = useState();
  const [f3WorkHours, setF3WorkHours] = useState();
  const [f1FreeHours, setF1FreeHours] = useState();
  const [criticalHours, setCriticalHours] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { departmentId } = useParams();
  const [dirty, setIsDirty] = useState(false);

  const getData = async () => {
    setIsLoading(true)
    const [
      { name, config, countryRules },
      countryCodeRules,
      licenseData
    ] = await Promise.all([
      dynoapeAPI.get(`/api/v1/department/${departmentId}`),
      dynoapeAPI.get(`/api/v1/countryrules`),
      dynoapeAPI.get(`/api/v1/department/${departmentId}/active-license`)
    ]);

    setDepartment({ name, config, countryRulesId: countryRules ? countryRules.id : countryCodeRules[0].id });
    setCountryCodeRules(countryCodeRules);
    setFullVacancyRateHours(config.fullVacancyRateHours
        ? validVacancyRateValues.includes(config.fullVacancyRateHours.toString()) ? config.fullVacancyRateHours
            : ""  : 35.5);
    setDisplayNightShiftOnNextDayInResult(config.displayNightShiftOnNextDayInResult || false);
    setF3MinVacancyRate(config.f3MinVacancyRate ?? 50.0);
    setShowF3MinVacancyRate(!(parseFloat(config.f3MinVacancyRate) >= 100.01))
    setF3WorkHours(config.f3WorkHours ?? 7.1);
    setF1FreeHours(config.f1FreeHours ?? 35);
    setCriticalHours(!config.criticalHours ? {} :
        Object.fromEntries(Object.entries(config.criticalHours)
            .map(([name, interval]) => {
              interval.end = hoursToLocalTime((localTimeToHours(interval.start) * 3600000 + moment.duration(interval.duration)) / 3600000);
              return [name, interval]
            }).sort())
    );
    if (countryRules)
      setHolidays(countryRules.holidays)

    if(licenseData) {
      setLicense(licenseData);
      setLicensePeriod(parsePeriod(licenseData.period));
    }

    setIsLoading(false);
  };

  const parsePeriod = (period) => {
    let dur = moment.duration(period);
    let periodStr = "";
    if(dur.years() > 0) {
      periodStr += dur.years() + " år, ";
    }
    if(dur.months() > 0) {
      periodStr += dur.months() + " måneder, "
    }
    if(dur.weeks() > 0) {
      periodStr += dur.weeks() + " uker, "
    }
    if(dur.days() > 0) {
      periodStr += dur.days() + " dager, ";
    }
    return periodStr.substring(0, periodStr.length -2);
  }

  useEffect(() => {
    getData();
  }, []);

  const setCountryCode = (countryRulesId) => {
    department.countryRulesId = countryRulesId;
  }

  const validVacancyRateValues = ["33.6", "35.5", "37.5", "40"];
  const validF3WorkHoursValues =
      [
        {value: "", label: "-"},
        {value: "0", label: "0"},
        {value: "7", label: "7"},
        {value: "7.1", label: "7.1"},
        {value: "7.5", label: "7.5"},
        {value: "8", label: "8"}
      ];

  const errors = () => {
    return !validVacancyRateValues.includes(fullVacancyRateHours.toString())
        || !_.inRange(parseFloat(f3MinVacancyRate), 0.0, 100.2)
        || !validF3WorkHoursValues.map(op => op.value).includes(f3WorkHours.toString())
        || !_.inRange(parseInt(f1FreeHours), 28, 49)
        || Object.entries(criticalHours).filter(([key, interval]) => {
          return !key
              || isNaN(parseFloat(localTimeToHours(interval.start)))
              || isNaN(parseFloat(localTimeToHours(interval.end)))
              || interval.days.length === 0
        }).length > 0;
  }

  const prepDepartment = async () => {
    if (errors()) return;
    saveDepartment();
  }

  const saveDepartment = async () => {
    setIsSaving(true);
    department.config.displayNightShiftOnNextDayInResult = displayNightShiftOnNextDayInResult;
    department.config.fullVacancyRateHours = fullVacancyRateHours;
    department.config.f3MinVacancyRate = f3MinVacancyRate;
    department.config.f3WorkHours = f3WorkHours;
    department.config.f1FreeHours = f1FreeHours;
    department.config.criticalHours = Object.fromEntries(Object.entries(_.cloneDeep(criticalHours))
        .map(([name, interval]) => {
          const start = localTimeToHours(interval.start) * 3600000;
          let end = localTimeToHours(interval.end) * 3600000;
          if(end <= start) end += 24*3600000;

          interval.duration = moment.duration(end - start).toISOString();
          delete interval.end;
          return [name, interval]
        }));
    const resp = await dynoapeAPI.put(`/api/v1/department/${departmentId}`, department);
    setIsSaving(false);
    getData();
    if(resp) {
      notifications.addNotification({
        content: "Oppsett lagret",
        duration: 2000,
        container: "center",
        type: "success"
      });
      setIsDirty(false);
    }
  }

  var countryCodeOptions = [];
  countryCodeRules.map(c => countryCodeOptions.push(<option key={`opt-${c.id}`} value={c.id}>{c.countryCode}</option>));

  const header = (text, htmlFor) => {
    return (
        <p style={{marginTop: "40px", fontSize: "16px"}}><label htmlFor={htmlFor}><b>{text}</b></label></p>
    )
  }

  return (<>
        {!isLoading && <div>
          <Header title={"Avdelingsoppsett"}
                  withTooltip
                  tooltipId="settings-header"
                  tooltipContent="I avdelingsoppsett kan du definere navnet på stillinger og spesialkompetanser. Du kan også legge inn de bevegelige helligdagene, samt hovedferien i avdelingen">
            <BackButton />
          </Header>
          <div className="content">
              <MaterialUITabs
                  activeIndex={activeIndex}
                  onChange={(event, i) => dirty ? setDesiredActiveIndex(i) : setActiveIndex(i)}
                  tabDescriptions={({
                      "1": "Avdelingsoppsett",
                      "2": `Stillingskategorier & Spesialkompetanser`,
                      "3": "Vaktkoder",
                      "4": "Lisensavtale",
                      "5": "Brukere med tilgang"
                  })}
              ></MaterialUITabs>
            <TabPanel value={activeIndex} index={"1"}>
              <Card style={{ width: "1400px", borderTopLeftRadius: "0px" }}>
                <SettingsContainer>
                  <h3>Avdelingsoppsett</h3>
                  <div>
                    {header("Velg antall timer pr uke som gjelder for din avdeling", "fullVacancyRateHours")}
                    <p><label>
                      <div style={{ color: "rgb(48 105 112)" }}>Hvis du ikke velger timetall, vil det automatisk være 35,5 timer som blir satt for din avdeling</div></label></p>
                    <Select
                        id="fullVacancyRateHours"
                        defaultValue={fullVacancyRateHours}
                        data-for="fullVacancyRateHours"
                        data-tip="Dynamon vil bruke det timetallet du velger beregningsgrunnlag for antall timer pr uke for alle ansatte i avdelingen"
                        style={{ width: "162px" }}
                        onChange={(e) => {
                          setFullVacancyRateHours(e.target.value);
                          setIsDirty(true)
                        }}
                    >
                      <option disabled selected value="">- Velg -</option>
                      <option value={33.6}>33.6</option>
                      <option value={35.5}>35.5</option>
                      <option value={37.5}>37.5</option>
                      <option value={40}>40.0</option>
                    </Select>
                    <Tooltip id="fullVacancyRateHours" />
                    {(!validVacancyRateValues.includes(fullVacancyRateHours.toString())) &&
                        <p style={{ color: "rgb(191, 61, 43)" }}>{fullVacancyRateHours} Uketimetall må være 33.6, 35.5, 37.5 eller 40</p>}

                    {header("Minimum antall (arbeids)timer fri rundt F1", "f1FreeHours")}
                    <InputWithValidation>
                      <Input
                          id={`f1FreeHours`}
                          key={`f1FreeHours`}
                          placeholder="28 til 48"
                          width="160px"
                          name="f1FreeHours"
                          value={f1FreeHours}
                          onChange={(e) => {
                            if(! e.target.value) {
                              setF1FreeHours("");
                            } else {
                              const val = parseInt(e.target.value);
                              setF1FreeHours(isNaN(val) ? "" : val);
                            }
                            setIsDirty(true)
                          }}
                          data-for="f1FreeHours"
                          data-tip="Her angir du minimum antall (arbeids)timer fri rundt F1"
                      />
                    </InputWithValidation>
                    <Tooltip id="f1FreeHours" />
                    {(isNaN(parseInt(f1FreeHours)) || !_.inRange(parseInt(f1FreeHours), 28, 49)) &&
                        <p style={{ color: "rgb(191, 61, 43)" }}>{"'Minimum antall (arbeids)timer fri rundt F1' " +
                            "må være mellom 28 og 48"}</p>}

                    {header("Skru på/av f3-dager i turnus", "showF3MinVacancyRate")}
                    <p><label>
                      <div style={{ color: "rgb(48 105 112)" }}> Her velger du om ansatte kan få f3 dager i turnusplanen</div>
                    </label></p>
                    <Checkbox
                        id={"showF3MinVacancyRate"}
                        data-for="showF3MinVacancyRate"
                        data-tip="Her velger du om ansatte kan få f3 dager i turnusplanen"
                        checked={showF3MinVacancyRate}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setShowF3MinVacancyRate(checked)

                          if(checked) {
                            setF3MinVacancyRate(1)
                          } else {
                            setF3MinVacancyRate(100.01)
                          }
                        }}
                    />

                    {showF3MinVacancyRate &&
                        <>
                          {header("Minimum stillingsprosent for at F3 skal tildeles i turnus", "f3MinVacancyRate")}
                          <p><label>
                            <div style={{color: "rgb(48 105 112)"}}>
                              Her velger du hvilke ansatte som kan få f3 dager i turnusplanen. Ved å skrive inn en
                              minimumsverdi for
                              stillingsprosent, <br/> vil kun ansatte med denne eller høyere stillingsprosent, kunne få f3
                              dager.
                            </div>
                          </label></p>
                          <InputWithValidation>
                            <Input
                                id={`f3MinVacancyRate`}
                                key={`f3MinVacancyRate`}
                                placeholder="0 til 100"
                                step="any"
                                width="160px"
                                name="f3MinVacancyRate"
                                value={f3MinVacancyRate}
                                onChange={(e) => {
                                  setF3MinVacancyRate(e.target.value);
                                  setIsDirty(true);
                                }}
                                data-for="f3MinVacancyRate"
                                data-tip="Her angir du minimum stillingsprosent for at F3 skal tildeles i turnus"
                            />
                          </InputWithValidation>
                          <Tooltip id="f3MinVacancyRate"/>
                          {!(_.inRange(parseFloat(f3MinVacancyRate), 0.0, 100.02) && maxNrOfDecimalRegex(2).test(f3MinVacancyRate)) &&
                              <p style={{color: "rgb(191, 61, 43)"}}>{"'Minimum stillingsprosent for at F3 skal tildeles i turnus' " +
                                  "må være mellom 0 og 100 og kan maks ha 2 desimaler"}</p>}

                        {header("Helgedagsfri (F3) skal telles som i timer", "f3WorkHours")}
                        <p><label>
                          <div style={{color: "rgb(48 105 112)"}}>Hvis du ikke velger et timetall, beregner vi det for deg med utgangspunkt i 7.1</div>
                        </label></p>
                        <Select
                            id="f3WorkHours"
                            defaultValue={f3WorkHours.toString()}
                            data-for="f3WorkHours"
                            data-tip="Her legger du inn den avtalte tiden på helgedagsfri (F3)."
                            style={{width: "162px"}}
                            onChange={(e) => {
                              setF3WorkHours(e.target.value);
                              setIsDirty(true);
                            }}
                        >
                          {validF3WorkHoursValues.map(op => (<option key={op.value} value={op.value}>{op.label}</option>))}
                        </Select>
                        <Tooltip id="f3WorkHours" />
                        {(!validF3WorkHoursValues.map(op => op.value).includes(f3WorkHours.toString())) &&
                            <p style={{color: "rgb(191, 61, 43)"}}> Verdi må være en av {validF3WorkHoursValues.map(op => op.label).toString()}</p>
                        }
                      </>
                  }
                  {header("Nattevakt skal vises på dagen den slutter i turnusplanen", "displayNightShiftOnNextDayInResult")}
                  <p><label><div style={{ color: "rgb(48 105 112)" }}>
                    Ved å skru på denne funksjonen, vil alle nattevakter i turnusplanen
                    vises på dagen den slutter, og ikke på dagen den starter.
                    <br/>
                    I bemanningsplanen må du forsatt sette opp kravet for dagen nattevakten starter.
                  </div></label></p>
                  <Checkbox
                      id={"displayNightShiftOnNextDayInResult"}
                      data-for="displayNightShiftOnNextDayInResult"
                      data-tip="Aktiver muligheten for å flytte nattevakter til neste dag i turnusplanen"
                      checked={displayNightShiftOnNextDayInResult}
                      onChange={(e) => {
                        setDisplayNightShiftOnNextDayInResult(e.target.checked);
                        setIsDirty(true);
                      }}
                  />
                  {FeatureToggles.FEATURE_TOGGLE_CRITICAL_HOURS_ENABLED.isEnabled() &&
                      <>
                        {header("Beregning av timer og tillegg", "")}
                        <p><label><div style={{color: "rgb(48 105 112)"}}>
                          Definer hvilke dager og tidsperioder som skal beregnes for ansatte. Funksjonen regner ut hvor mange timer som er arbeidet innenfor
                          <br/> definerte tidsrom (som natt- og kveldstid) og dager (som lørdag og søndag) i en generert turnus. Resultatene viser en oppsummering
                          <br/>av arbeidstimer for hver ansatt i turnusplanene.
                        </div></label></p>
                        <Flex>
                          <label style={{width: "240px", marginBottom: "10px", marginTop: "10px"}}>Navn</label>
                          <label style={{width: "120px", marginBottom: "10px", marginTop: "10px"}}>Starttidspunkt</label>
                          <label style={{width: "120px", marginBottom: "10px", marginTop: "10px"}}>Sluttidspunkt</label>
                          <label style={{width: "120px", marginBottom: "10px", marginTop: "10px"}}>Dager</label>
                        </Flex>
                        {Object.entries(criticalHours).map(([name, interval]) => {
                              let localName = name;
                              return (
                                  <>
                                    <Flex key={`criticalHours-${name}`} flexWrap={"wrap"} justifyContent={'flex-start'}>
                                      <div style={{width: "240px"}}>
                                        <SolidInput
                                            id={`name-${name}`}
                                            style={{width: "200px"}}
                                            defaultValue={localName}
                                            maxLength="20"
                                            data-for={`name-${name}`}
                                            data-tip="Her setter du navnet"
                                            onChange={(e) => {localName = e.target.value}}
                                            onBlur={() => {
                                              const newCriticalHours = Object.fromEntries(
                                                  Object.entries(criticalHours)
                                                      .map(([key, interval]) => key === name ? [localName, interval] : [key, interval])
                                              );
                                              setCriticalHours(newCriticalHours);
                                              setIsDirty(true);
                                            }}
                                        />
                                        <Tooltip id={`name-${name}`}/>
                                      </div>
                                      <div style={{width: "120px"}}>
                                        <StyledInputMask
                                            id={`criticalHoursStart-${name}`}
                                            defaultValue={interval.start}
                                            mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                                            maskPlaceholder="-"
                                            alwaysShowMask={true}
                                            {...register(`criticalHoursStart-${name}`)}
                                            data-for={`criticalHoursStart-${name}`}
                                            data-tip="Her angir du starttidspunktet"
                                            onChange={(e) => {
                                              const newVal = {...criticalHours[name], start: e.target.value};
                                              setCriticalHours(
                                                  Object.fromEntries(
                                                      Object.entries(criticalHours)
                                                          .map(([key, interval]) => key === name ? [key, newVal] : [key, interval])
                                                  )
                                              );
                                              setIsDirty(true);
                                            }}
                                        />
                                        <Tooltip id={`criticalHoursStart-${name}`}/>
                                      </div>
                                      <div style={{width: "120px", marginBottom: "20px"}}>
                                        <StyledInputMask
                                            id={`criticalHoursEnd-${name}`}
                                            defaultValue={interval.end}
                                            mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                                            maskPlaceholder="-"
                                            alwaysShowMask={true}
                                            {...register(`criticalHoursEnd-${name}`)}
                                            data-for={`criticalHoursEnd-${name}`}
                                            data-tip="Her angir du sluttidspunktet"
                                            onChange={(e) => {
                                              const newVal = {...criticalHours[name], end: e.target.value};
                                              setCriticalHours(
                                                  Object.fromEntries(
                                                      Object.entries(criticalHours)
                                                          .map(([key, interval]) => key === name ? [key, newVal] : [key, interval])
                                                  )
                                              );
                                              setIsDirty(true);
                                            }}
                                        />
                                        <Tooltip id={`criticalHoursEnd-${name}`}/>
                                      </div>
                                      <ButtonGroup style={{marginRight: "20px"}} key={`dayButtonGroup-${name}`}>
                                        {Object.entries(
                                            {"MONDAY": "Man",
                                              "TUESDAY": "Tir",
                                              "WEDNESDAY": "Ons",
                                              "THURSDAY": "Tor",
                                              "FRIDAY": "Fre",
                                              "SATURDAY": "Lør",
                                              "SUNDAY": "Søn"}
                                        ).map(([day, transl]) => {
                                          return <Button key={`dayInput-${name}-${day}`}
                                                         style={{
                                                           padding: "4px 25px",
                                                           backgroundColor: interval.days.includes(day) ? 'rgb(48, 105, 112)': '#fff',
                                                           color: interval.days.includes(day) ? '#fff': '#333',
                                                         }}
                                                         primary={interval.days.includes(day)}
                                                         onClick={() => {
                                                           const newVal = {
                                                             ...criticalHours[name],
                                                             days: criticalHours[name].days.includes(day)
                                                                 ? criticalHours[name].days.filter(d => d !== day)
                                                                 : [...criticalHours[name].days, day],
                                                           };
                                                           setCriticalHours(
                                                               Object.fromEntries(
                                                                   Object.entries(criticalHours)
                                                                       .map(([key, interval]) => key === name ? [key, newVal] : [key, interval])
                                                               )
                                                           )
                                                           setIsDirty(true)
                                                         }}
                                          >{transl}</Button>
                                        })
                                        }
                                      </ButtonGroup>
                                      <Button
                                          style={{height: "20px", marginTop: "15px"}}
                                          danger
                                          underline
                                          onClick={() => {
                                            setCriticalHours(Object.fromEntries(Object.entries(criticalHours).filter(([key]) => key !== name)));
                                            setIsDirty(true);
                                          }}>Fjern</Button>
                                    </Flex>
                                    {!name &&
                                        <p style={{color: "rgb(191, 61, 43)"}}>Navn kan ikke være tomt</p>
                                    }
                                    {isNaN(parseFloat(localTimeToHours(interval.start))) &&
                                        <p style={{color: "rgb(191, 61, 43)"}}>Starttidspunkt må ha format HH:MM</p>
                                    }
                                    {isNaN(parseFloat(localTimeToHours(interval.end))) &&
                                        <p style={{color: "rgb(191, 61, 43)"}}>Sluttidspunkt må ha format HH:MM</p>
                                    }
                                    {
                                      interval.days.length === 0 &&
                                        <p style={{color: "rgb(191, 61, 43)"}}>Velg minst en dag.</p>
                                    }
                                  </>
                              )
                            }
                        )}
                        <Button primary underline onClick={() => {
                          const newCritical = {
                            ...criticalHours,
                            [`Tidsintervall ${Object.keys(criticalHours).length + 1}`]: {
                              start: "00:00",
                              end: "00:00",
                              days: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]
                            }
                          };
                          setCriticalHours(newCritical);
                          setIsDirty(true);
                        }}>Legg til</Button>
                      </>
                  }

                    {header("Landekode", "countryCode")}
                    <Select
                        id="countryCode"
                        defaultValue={department.countryRulesId}
                        onChange={(e) => setCountryCode(e.target.value)}
                        data-for="countryCode"
                        data-tip="Sett NO for Norge"
                    >
                      {countryCodeOptions}
                    </Select>
                    <Tooltip id="countryCode" />

                  </div>
                  {Object.keys(holidays).length > 0 && <div>
                    <h3>Helligdager</h3>
                    <Calendar
                        style={{ height: 500 }}
                        tileContent={({ date }) => {
                          date.setDate(date.getDate() + 1)
                          if (new Set(Object.keys(holidays)).has(date.toISOString().split('T')[0]))
                            return <DateDot />
                        }}
                        showWeekNumbers
                        tileDisabled={() => true}
                        minDetail="month"
                        tileClassName={() => "calendar-active"}
                    >
                    </Calendar>
                  </div>}
                  {isSaving ? <Spinner style={{ marginTop: "20px" }} /> : <Button style={{ marginTop: "20px" }} primary onClick={() => prepDepartment()}>Lagre</Button>}
                </SettingsContainer>
              </Card>
            </TabPanel>
            <TabPanel value={activeIndex} index={"2"}>
              <Card style={{ width: "1400px", borderTopLeftRadius: "0px" }}>
                <DepartmentSettings />
              </Card>
            </TabPanel>
            <TabPanel value={activeIndex} index={"3"}>
              <ShiftTypesTable />
            </TabPanel>
            <TabPanel value={activeIndex} index={"4"}>
              {license && <Card style={{ width: "1400px", borderTopLeftRadius: "0px" }}>
                <SettingsContainer>
                  <h3>Aktiv lisensavtale {license.autorenewStatus === 'TERMINATED' ? '(stoppet)' : ''}</h3>
                  <LicenseInfo>Periode: {licensePeriod}</LicenseInfo>
                  <LicenseInfo>Startet: {dateInNorwegianTimezone(license.startDate)}</LicenseInfo>
                  <LicenseInfo>{(license.type === 'RENEWABLE' && license.autorenewStatus === 'ACTIVE') ? "Fornyes: " : "Utløper: "} {dateInNorwegianTimezone(license.endDate)}</LicenseInfo>
                  <LicenseInfo>Antall genereringer: {license.maxGenerations}</LicenseInfo>
                  <LicenseInfo>Antall ansatte: {license.maxEmployees}</LicenseInfo>
                  {(license.addOns && license.addOns.length > 0) &&
                      <>
                        <h4>Tilleggsavtaler (fornyes ikke)</h4>
                        {license.addOns.map(addOn => {
                          return (
                              <>
                                <LicenseInfo>Startet: {dateInNorwegianTimezone(addOn.startDate)}</LicenseInfo>
                                <LicenseInfo>Utløper: {dateInNorwegianTimezone(addOn.endDate)}</LicenseInfo>
                                <LicenseInfo>Antall ekstra genereringer: {addOn.maxGenerations}</LicenseInfo>
                                <LicenseInfo>Antall ekstra ansatte: {addOn.maxEmployees}</LicenseInfo>
                                <hr></hr>
                                <br />
                              </>);
                        })}
                      </>}
                  <br/>
                  <LicenseInfo>Antall registrerte ansatte: {license.employeesInDepartment}</LicenseInfo>
                  <LicenseInfo>Antall gjenværende ansatte: {license.remainingEmployees}</LicenseInfo>
                  <LicenseInfo>Antall kjørte genereringer: {license.generationsInPeriod}</LicenseInfo>
                  <LicenseInfo>Antall gjenværende genereringer: {license.remainingGenerations}</LicenseInfo>
                  <br/>
                  <LicenseInfo><i>Trenger du å oppgradere lisensen din, vennligst kontakt support@dynamon.no</i></LicenseInfo>
                </SettingsContainer>
              </Card>}
            </TabPanel>
            <TabPanel value={activeIndex} index={"5"}>
              <Card style={{ width: "1400px", borderTopLeftRadius: "0px" }}>
                <DepartmentSharing departmentId={departmentId} />
              </Card>
            </TabPanel>
          </div>
          {desiredActiveIndex !== undefined && (
              <Modal
                  title={"Er du sikker på at du vil forlate siden?"}
                  done={() => {
                    setActiveIndex(desiredActiveIndex);
                    setDesiredActiveIndex(undefined);
                    setIsDirty(false)
                  }}
                  cancel={() => setDesiredActiveIndex(undefined)}
                  action="Ja, forlat siden"
                  cancelButtonProps={{
                    children: 'Nei, bli på siden'
                  }}
              >
                <p style={{ color: "red" }}>Alle endringer vil gå tapt</p>
              </Modal>
          )}
          <RouterPrompt
              when={dirty}
              title="Er du sikker på at du vil forlate siden?"
              onOK={() => true}
              onCancel={() => false}
          />
        </div>
        }
      </>
  );
};
const LicenseInfo = styled.div`
  font-family: Montserrat Alternates;
  font-size: 16px;
`;
const Select = styled.select`
  border: 1px solid #E5E7f0;
`;

export default Department;
