/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { isMacOs } from 'react-device-detect';
import {
  Button,
  Modal,
  Input,
  notifications,
  ProgressBar,
  Spinner, Flex
} from "rendition";
import {
  dynoapeAPI,
  getSortedCompetences,
  getSortedPositions,
  getSortedShiftTypes
} from "../api/dynoape";
import { useParams, useHistory } from "react-router-dom";
import { RouterPrompt } from "../lib/confirm/router-prompt";
import { ReactGrid } from "@silevis/reactgrid";
import "./grid-components/grid-styles.css";
import { DropdownCellTemplate } from "./grid-components/dropdown-cell-template";
import {
  DropdownSelectCellTemplate,
  selectAllFromDropdown
} from "./grid-components/dropdown-select-cell-template";
import { CheckboxCellTemplate } from "./grid-components/checkbox-cell-template";
import {
  shiftOptions,
  longShiftOptions,
  lateShiftOptions,
  weekendOptions, daySegmentMaxOptions,
} from "../lib/options";
import Tooltip from "../lib/tooltip";
import {
  convertToExtendedEmployeeConfig,
  dynomiteErrorPanel,
  sortShiftCodesByDaySegment,
  validCharactersRegex
} from "../lib/common";
import dynomiteContext from "./dynomite";
import Select from "react-select";

const EmployeeTable = ({setRefresh}) => {
  const dynomite = useContext(dynomiteContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [showSavingModal, setShowSavingModal] = useState({ display: false, saveCount: 0, toSave: 0, empl: null });
  const [showMultiAddModal, setShowMultiAddModal] = useState({ display: false, amount: 0, error: false });
  const [showDeleteModal, setShowDeleteModal] = useState({ display: false, rows: [] });
  const [showHelp, setShowHelp] = useState(false);

  const { departmentId } = useParams();
  const [department, setDepartment] = useState({});
  let [positions, setPositions] = useState([]);
  let [competences, setCompetences] = useState([]);
  let [shiftTypes, setShiftTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const [employees, setEmployees] = useState([]);
  const [changedIndexes, setChangedIndexes] = useState([]);
  const [employeeStats, setEmployeeStats] = useState({
    totalVacancy: 0,
    totalMaxDayShifts: 0,
    totalMaxEveningShifts: 0,
    totalMaxNightShifts: 0,
    totalMaxLongShifts: 0,
  });
  const [cellChangesIndex, setCellChangesIndex] = useState(() => -1);
  const [cellChanges, setCellChanges] = useState(() => []);
  const [openedDropdownLocation, setOpenedDropdownLocation] = useState({ columnId: "", rowId: "" });
  const [focus, setFocus] = useState();

  const [columnVisibility, setColumnVisibility] = useState({});

  const [searchFilters, setSearchFilters] = useState({
    employee: undefined,
    positions: [],
    traits: [],
    daySegments: []
  });

  const searchDebounceTimeout = useRef(null);
  const handleSearchChange = (e) => {
    if (searchDebounceTimeout.current) {
      clearTimeout(searchDebounceTimeout.current);
    }
    searchDebounceTimeout.current = setTimeout(() => {
      setSearchFilters(e);
    }, 300);
  };

  const isVisible = columnId => !(columnId in columnVisibility) || columnVisibility[columnId];
  const setVisibles = (columnIds, visibility) => {
    const newVisibility = { ...columnVisibility };
    columnIds.forEach(columnId => newVisibility[columnId] = visibility);
    setColumnVisibility(newVisibility);
  };

  useEffect(() => {
    let activeEmployees = applySearchFiltersToEmployees(employees)?.filter(function (emp) { return emp.enabled });

    setEmployeeStats({
      totalVacancy: (activeEmployees.reduce((acc, emp) => { return acc + firstNonNegative(emp.vacancyRate) }, 0) / 100).toFixed(2),
      totalMaxDayShifts: activeEmployees.reduce((acc, emp) => { return acc + firstNonNegative(emp.maxDayShifts) }, 0),
      totalMaxEveningShifts: activeEmployees.reduce((acc, emp) => { return acc + firstNonNegative(emp.maxEveningShifts) }, 0),
      totalMaxNightShifts: activeEmployees.reduce((acc, emp) => { return acc + firstNonNegative(emp.maxNightShifts) }, 0),
      totalMaxLongShifts: activeEmployees.reduce((acc, emp) => { return acc + firstNonNegative(emp.maxLongShifts) }, 0),
    });
    setRows(getRows(employees));
    validate(department, applySearchFiltersToEmployees(employees), positions, competences, shiftTypes)
  }, [employees, searchFilters]);

  const firstNonNegative = (nr) => {
    return nr ? (nr < 0 ? 0 : nr) : 0;
  }

  useEffect(() => {
    getDepartmentEmployees();
  }, []);

  const handleKeyDown = (e) => {
    if ((!isMacOs && e.ctrlKey) || e.metaKey) {
      switch (e.key) {
        case "z":
          handleUndoChanges();
          return;
        case "y":
          handleRedoChanges();
          return;
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const getDepartmentEmployees = async () => {
    const [
      employeesData,
      departmentData,
      positionsData,
      traitsData,
      shiftTypesData
    ] = await Promise.all([
      dynoapeAPI.get(`/api/v1/department/${departmentId}/employees?includeBlockedPatterns=true&includeWeekendPatterns=true&includeVacationPatterns=true&includeHolidayPatterns=true&includeBlockedPatterns=true&includePaidLeavePatterns=true`),
      dynoapeAPI.get(`/api/v1/department/${departmentId}`),
      getSortedPositions(departmentId),
      getSortedCompetences(departmentId),
      getSortedShiftTypes(departmentId)
    ]);

    validate(departmentData, employeesData, positionsData, traitsData, shiftTypesData);

    if (positionsData.length === 0)
      setShowModal(true);

    const sortedEmployees = employeesData?.sort(function (a, b) {
      if(a.priority === b.priority) {
        return new Date(a.registered) - new Date(b.registered);
      } else {
        return a.priority - b.priority;
      }
    });

    sortedEmployees.forEach(employee => {
      employee.positionName = employee.restPosition ? employee.restPosition.name : "";
      employee.traitNames = Object.entries(employee.traitNames).sort(function (a, b) {
        return a[1].localeCompare(b[1], 'no', { sensitivity: 'base' });
      })?.map(trait => trait[1]).join(", ");
      employee.shiftCodes = sortShiftCodesByDaySegment(employee.restShiftTypes.map(sh => sh.code), employee.restShiftTypes)?.join(", ");
    });

    setDepartment(departmentData);
    setPositions(positionsData);
    setCompetences(traitsData);
    setShiftTypes(shiftTypesData)
    setEmployees(sortedEmployees);
    setRows(getRows(sortedEmployees));
    setIsLoading(false);
  };

  function positionStrToId(position) {
    let idPosition = positions.filter(pos => {
      return pos.name.toLowerCase().trim() === position.toLowerCase().trim()
    });

    if (idPosition.length) {
      return idPosition[0].id;
    }

    return "";
  }

  function competencesStrToIds(competencesString) {
    let idCompetences = [];

    competencesString.split(", ").forEach(cs => {
      let idComp = competences.filter(comp => {
        return comp.name.toLowerCase().trim() === cs.toLowerCase().trim()
      });
      if (idComp[0] !== undefined)
        idCompetences.push(idComp[0].id);
    });

    if (idCompetences.length)
      return idCompetences;

    return [];
  }

  function shiftTypesStrToIds(str) {
    let shiftTypeIds = [];

    str.split(", ").forEach(code => {
      let id = shiftTypes.filter(sh => {
        return sh.code.toUpperCase().trim() === code.toUpperCase().trim()
      });
      if (id[0] !== undefined)
        shiftTypeIds.push(id[0].id);
    });

    if (shiftTypeIds.length)
      return shiftTypeIds;

    return [];

  }

  const autoSize = (field) => {
    return 100 + employees.reduce((max, empl) => {
      return Math.max(max, empl[field]?.length);
    }, 0) * 6;
  }

  const autoSizeList = (field, minWidth=0) => {
    const val = 100 + employees.reduce((max, empl) => {
      return Math.max(max, empl[field]?.length);
    }, 0) * 7;

    return val > minWidth ? val : minWidth;
  }

  const advanced = [
      "dayShiftMinSequence", "dayShiftMaxSequence",
      "eveningShiftMinSequence", "eveningShiftMaxSequence",
      "nightShiftMinSequence", "nightShiftMaxSequence",
      "longShiftMinSequence", "longShiftMaxSequence",
      "shiftMinSequence", "maxShifts",
      "dayShiftsMaxPerWeek", "maxDayShifts",
      "eveningShiftsMaxPerWeek", "maxEveningShifts",
      "nightShiftsMaxPerWeek", "maxNightShifts", "maxNightShiftWeekends",
      "longShiftsMaxPerWeek", "maxLongShifts"
  ]

  const optimizableSettings = [
    "shiftMinSequence", "maxShifts",
    "dayShiftMinSequence", "eveningShiftMinSequence", "longShiftMinSequence", "nightShiftMinSequence",
    "dayShiftsMaxPerWeek", "maxDayShifts",
    "eveningShiftsMaxPerWeek", "maxEveningShifts",
    "nightShiftsMaxPerWeek", "maxNightShifts", "maxNightShiftWeekends",
    "longShiftsMaxPerWeek", "maxLongShifts"
  ]

  const columnDescs = [
    { columnId: "nr", included: true, width: 40, text: "Rad", item: ({ idx }) => ({ type: "header", text: `${idx + 1}` }), footer: "" },
    { columnId: "name", included: true, width: autoSize("name"), text: "Navn", item: ({ employee }) => ({ type: "text", text: employee.name, validator: (text) => { return text !== "error" } }), footer: "" },
    { columnId: "positionName", included: true, width: autoSize("positionName"), text: "Stilling", item: ({ employee, idx }) => ({ colId: "position", type: "dropdown", selectedValue: employee.positionName, values: positions?.map((position) => ({ label: position.name, value: position.name })), isOpen: openedDropdownLocation?.columnId === "positionName" && openedDropdownLocation?.rowId === idx }), footer: "" },
    { columnId: "vacancyRate", included: true, width: 120, text: "St. prosent", item: ({ employee }) => ({ colId: "vacancy_rate", type: "number", value: employee.vacancyRate }), footer: `Årsverk: ${employeeStats.totalVacancy}` },
    { columnId: "shiftCodes", included: true, width: autoSizeList('shiftCodes'), text: "Vaktkoder", item: ({ employee, idx }) => ({ colId: "shift_types", showAll: true, type: "dropdown", selectedValue: employee.shiftCodes, values: [selectAllFromDropdown].concat(sortShiftCodesByDaySegment(shiftTypes.map(sh => sh.code), shiftTypes)?.map((sh) => ({ label: sh, value: sh }))), isOpen: openedDropdownLocation?.columnId === "shiftCodes" && openedDropdownLocation?.rowId === idx, isMulti: true}), footer: "" },
    { columnId: "enabled", included: true, width: 120, text: "Tilgjengelig for vakter", item: ({ employee }) => ({ type: "checkbox", checked: employee.enabled, checkedText: "Ja", uncheckedText: "Nei" }), footer: "" },
    { columnId: "shiftMaxSequence", included: true, width: 150, text: "Maks. antall vakter på rad", item: ({ employee }) => ({ colId: "shift_max_sequence", type: "dropdown-number", text: employee.shiftMaxSequence, values: shiftOptions() }), footer: "" },
    { columnId: "nrOfQuickReturnsPerWeekOnWeekend", included: true, width: 150, text: "Maks. antall sein-tidlig vakter - helg", item: ({ employee }) => ({ type: "dropdown-number", text: employee.nrOfQuickReturnsPerWeekOnWeekend, values: weekendOptions }), footer: "" },
    { columnId: "nrOfQuickReturnsPerWeekOnWeekdays", included: true, width: 150, text: "Maks. antall sein-tidlig vakter - ukedag", item: ({ employee }) => ({ type: "dropdown-number", text: employee.nrOfQuickReturnsPerWeekOnWeekdays, values: weekendOptions }), footer: "" },
    { columnId: "traitNames", included: true, width: autoSizeList("traitNames", 300), text: "Spesialkompetanse", item: ({ employee, idx }) => ({ showAll: true, type: "dropdown", selectedValue: employee.traitNames, values: [selectAllFromDropdown].concat(competences?.map((competence) => ({ label: competence.name, value: competence.name }))), isOpen: openedDropdownLocation?.columnId === "traitNames" && openedDropdownLocation?.rowId === idx, isMulti: true }), footer: "" },
    { columnId: "comment", included: true, width: 230, text: "Kommentar \n Ikke-sensitiv informasjon", item: ({ employee }) => ({ type: "text", text: employee.comment }), footer: "" },
    { columnId: "dayShiftMinSequence", included: true, width: 150, text: "Min. dagvakter på rad", item: ({ employee }) => ({ colId: "day_shift_min_sequence", type: "dropdown-number", text: employee.dayShiftMinSequence, values: lateShiftOptions }), footer: "" },
    { columnId: "dayShiftMaxSequence", included: true, width: 150, text: "Maks. dagvakter på rad", item: ({ employee }) => ({colId: "day_shift_max_sequence", type: "dropdown-number", text: employee.dayShiftMaxSequence, values: daySegmentMaxOptions }), footer: "" },
    { columnId: "eveningShiftMinSequence", included: true, width: 150, text: "Min. kveldsvakter på rad", item: ({ employee }) => ({ colId: "evening_shift_min_sequence", type: "dropdown-number", text: employee.eveningShiftMinSequence, values: lateShiftOptions }), footer: "" },
    { columnId: "eveningShiftMaxSequence", included: true, width: 150, text: "Maks. kveldsvakter på rad", item: ({ employee }) => ({ colId: "evening_shift_max_sequence", type: "dropdown-number", text: employee.eveningShiftMaxSequence, values: daySegmentMaxOptions }), footer: "" },
    { columnId: "nightShiftMinSequence", included: true, width: 120, text: "Min. nattevakter på rad", item: ({ employee }) => ({ colId: "night_shift_min_sequence", type: "dropdown-number", text: employee.nightShiftMinSequence, values: lateShiftOptions }), footer: "" },
    { columnId: "nightShiftMaxSequence", included: true, width: 130, text: "Maks. nattevakter på rad", item: ({ employee }) => ({ colId: "night_shift_max_sequence", type: "dropdown-number", text: employee.nightShiftMaxSequence, values: daySegmentMaxOptions }), footer: "" },
    { columnId: "longShiftMinSequence", included: true, width: 150, text: "Min. langvakter/ mellomvakter på rad", item: ({ employee }) => ({ colId: "long_shift_min_sequence", type: "dropdown-number", text: employee.longShiftMinSequence, values: longShiftOptions }), footer: "" },
    { columnId: "longShiftMaxSequence", included: true, width: 150, text: "Maks. langvakter/ mellomvakter på rad", item: ({ employee }) => ({ colId: "long_shift_max_sequence", type: "dropdown-number", text: employee.longShiftMaxSequence, values: daySegmentMaxOptions }), footer: "" },
    { columnId: "shiftMinSequence", included: true, width: 100, text: "Min. antall vakter på rad", item: ({ employee }) => ({ type: "dropdown-number", text: employee.shiftMinSequence, values: lateShiftOptions }), footer: "" },
    { columnId: "maxShifts", included: true, width: 150, text: "Maks. antall vakter i turnus", item: ({ employee }) => ({ type: "number", value: employee.maxShifts > -1 ? employee.maxShifts : null }), footer: ""},
    { columnId: "dayShiftsMaxPerWeek", included: true, width: 150, text: "Maks. dagvakter per uke", item: ({ employee }) => ({ type: "number", value: employee.dayShiftsMaxPerWeek > -1 ? employee.dayShiftsMaxPerWeek : null }), footer: "" },
    { columnId: "maxDayShifts", included: true, width: 150, text: "Maks. dagvakter i turnus", item: ({ employee }) => ({ type: "number", value: employee.maxDayShifts > -1 ? employee.maxDayShifts : null }), footer: `Totalt: ${employeeStats.totalMaxDayShifts}` },
    { columnId: "eveningShiftsMaxPerWeek", included: true, width: 150, text: "Maks. kveldsvakter per uke", item: ({ employee }) => ({ type: "number", value: employee.eveningShiftsMaxPerWeek > -1 ? employee.eveningShiftsMaxPerWeek : null }), footer: "" },
    { columnId: "maxEveningShifts", included: true, width: 150, text: "Maks. kveldsvakter i turnus", item: ({ employee }) => ({ type: "number", value: employee.maxEveningShifts > -1 ? employee.maxEveningShifts : null }), footer: `Totalt: ${employeeStats.totalMaxEveningShifts}` },
    { columnId: "nightShiftsMaxPerWeek", included: true, width: 150, text: "Maks. nattevakter per uke", item: ({ employee }) => ({ type: "number", value: employee.nightShiftsMaxPerWeek > -1 ? employee.nightShiftsMaxPerWeek : null }), footer: "" },
    { columnId: "maxNightShifts", included: true, width: 130, text: "Maks. nattevakter i turnus", item: ({ employee }) => ({ type: "number", value: employee.maxNightShifts > -1 ? employee.maxNightShifts : null }), footer: `Totalt: ${employeeStats.totalMaxNightShifts}` },
    { columnId: "maxNightShiftWeekends", included: true, width: 150, text: "Maks. nattevakthelger i turnus", item: ({ employee }) => ({ type: "number", value: employee.maxNightShiftWeekends > -1 ? employee.maxNightShiftWeekends : null }), footer: "" },
    { columnId: "longShiftsMaxPerWeek", included: true, width: 150, text: "Maks. langvakter/ mellomvakter per uke", item: ({ employee }) => ({ type: "number", value: employee.longShiftsMaxPerWeek > -1 ? employee.longShiftsMaxPerWeek : null }), footer: "" },
    { columnId: "maxLongShifts", included: true, width: 150, text: "Maks. langvakter/ mellomvakter i turnus", item: ({ employee }) => ({ type: "number", value: employee.maxLongShifts > -1 ? employee.maxLongShifts : null }), footer: `Totalt: ${employeeStats.totalMaxLongShifts}` },
  ];
  const filteredColumnDescs = columnDescs.filter(colDesc => (isVisible(colDesc.columnId) && colDesc.included));
  const onlyStickyColumns = filteredColumnDescs.length <= 4;

  const getColumns = () => filteredColumnDescs.map(colDesc => ({ columnId: colDesc.columnId, width: colDesc.width }));

  const headerRow = {
    rowId: "header",
    height: 50,
    cells: filteredColumnDescs.map(colDesc =>
        (
            {
              type: "header",
              text: colDesc.text,
              style: {background: optimizableSettings.includes(colDesc.columnId) ? "#E6E6FA" : "rgba(128, 128, 128, 0.1)"}
            }
        )
    ),
  };

  const footerRow = {
    rowId: "footer",
    height: 30,
    cells: filteredColumnDescs.map(colDesc => ({ type: "header", text: colDesc.footer })),
  };

  const getRows = (employees) => applySearchFiltersToEmployees(employees.map((employee, idx) => {employee.idx = idx; return employee})).map(employee => ({
    rowId: employee.idx,
    height: 30,
    reorderable: true,
    cells: filteredColumnDescs.map(colDesc => colDesc.item({ idx: employee.idx, employee: employee })),
    _employee: employee,
  }));

  const applySearchFiltersToEmployees = (employees) => {
    return employees
        .filter(e => {
          if(e.id === undefined) return true;

          const nameOk = searchFilters.employee === undefined
              || searchFilters.employee.trim() === ""
              || e.name.toLowerCase().includes(searchFilters.employee.toLowerCase());

          const positionOk = searchFilters.positions === undefined
              || searchFilters.positions.length === 0
              || searchFilters.positions.includes(e.position)

          const traitOk = searchFilters.traits === undefined
              || searchFilters.traits.length === 0
              || searchFilters.traits.some(t => e.traits?.includes(t))

          const daySegmentsOk = searchFilters.daySegments === undefined
              || searchFilters.daySegments.length === 0
              || searchFilters.daySegments.some(ds => e.restShiftTypes?.map(sh => sh.daySegment)?.includes(ds))

          return nameOk && positionOk && traitOk && daySegmentsOk;
        })
  }

  const placeholderId = "placeholder";

  const addRow = (idx) => {
    idx = idx ?? employees.length;
    setEmployees(employees => [...employees, {
      id: placeholderId.concat(idx+1),
      name: "Ny ansatt ".concat(idx+1),
      positionName: positions[0].name,
      position: positions[0].id,
      shiftCodes: "",
      shiftTypes: {},
      vacancyRate: 100,
      enabled: true,
      shiftMinSequence: 0,
      shiftMaxSequence: 5,
      dayShiftMinSequence: 0,
      dayShiftMaxSequence: 0,
      maxDayShifts: null,
      eveningShiftMinSequence: 0,
      eveningShiftMaxSequence: 0,
      maxEveningShifts: null,
      nightShiftMinSequence: 0,
      nightShiftMaxSequence: 0,
      maxNightShifts: null,
      longShiftMinSequence: 0,
      longShiftMaxSequence: 0,
      maxLongShifts: null,
      worksOnlyWeekend: false,
      traitNames: "",
      traits: [],
      nrOfQuickReturnsPerWeekOnWeekend: 1,
      nrOfQuickReturnsPerWeekOnWeekdays: 1,
      redDaysMaxSequence: 5,
      movableHolidaysMaxPerTurnus: 5,
      comment: "",
      weekendShiftMaxSequence: -1,
      priority: idx + 1,
      sundaysMaxSequence: 2,
      weekendPatterns: [],
      vacationPatterns: [],
      paidLeavePatterns: [],
      holidayPatterns: [],
      blockedPatterns: []
    }]);
    setChangedIndexes(changedIndexes.includes(idx) ? changedIndexes : [...changedIndexes, idx])
  };

  function findOpenedDropdownCellLocation(changes) {
    const openedDropdownChanges = changes.filter(
        (change) => change.type === "dropdown" && change.newCell.isOpen
    );
    if (openedDropdownChanges.length >= 1) {
      const cell = openedDropdownChanges[0];
      return { rowId: cell.rowId, columnId: cell.columnId };
    }
    return undefined;
  }

  const applyNewValue = (changes, prevEmployees, usePrevValue) => {
    changes.forEach((change) => {
      const employeeIndex = change.rowId;
      const fieldName = change.columnId;
      const cell = usePrevValue ? change.previousCell : change.newCell;

      if(cell.text === '' && cell.type === 'dropdown-number') {
        cell.text = "0";
      }

      if (!isNaN(cell.value) && fieldName === "vacancyRate") {
        if (cell.value > 100)
          cell.value = 100;
        if (cell.value < 0)
          cell.value = 0;
          prevEmployees[employeeIndex][fieldName] = cell.value
      }
      let segments = new Set(
          prevEmployees[employeeIndex]['shiftCodes']?.split(", ")?.flatMap(
              code => shiftTypes.filter(sh => sh.code === code).map(sh => sh.daySegment)
          )
      );

      const resolveMaxSeqValueBasedOnVacancyRate = (segments, vacancyRate, seqField, defaultValue) => {
        const _vacRate = parseFloat(vacancyRate)
        if(seqField === 'shiftMaxSequence' && _vacRate >= 10 && _vacRate <= 59) return 3;
        if(seqField === 'shiftMaxSequence' && _vacRate >= 60 && _vacRate <= 79) return 4;
        if(seqField === 'shiftMaxSequence' && _vacRate >= 80) return 5;

        if((seqField === 'dayShiftMaxSequence' && segments.includes('D')) || seqField === 'shiftMaxSequence') {
          if(_vacRate <= 59) return 3;
          if(_vacRate >= 60 && _vacRate <= 79) return 4;
          if(_vacRate >= 80) return 5;
        } else if(seqField === 'eveningShiftMaxSequence' && segments.includes('A')) {
          return 2;
        } else if(seqField === 'nightShiftMaxSequence' && segments.includes('N')) {
          return 3;
        } else if(seqField === 'longShiftMaxSequence' && segments.includes('L')) {
          return 2;
        }
        return defaultValue;
      }

      const shiftMaxSeq = parseInt(prevEmployees[employeeIndex]['shiftMaxSequence']);

      /* Start automatically setting max sequences if a new daySegment is selected */
      if(change.columnId === "shiftCodes") {
        const codes = change.newCell.selectedValue?.split(", ").filter(Boolean);
        prevEmployees[employeeIndex]['shiftTypes'] = Object.fromEntries((codes || [])
            .map(code => [
                  code,
                  Object.hasOwn(prevEmployees[employeeIndex]['shiftTypes'], code) ?
                      {...prevEmployees[employeeIndex]['shiftTypes'][code]} :
                      {maxPerWeek: null, daySegment: shiftTypes.filter(sh => sh.code === code)[0].daySegment}
                ]
            ))
        const allSegments = [
          ...new Set(
              codes?.flatMap(
                  code => shiftTypes.filter(sh => sh.code === code).map(sh => sh.daySegment)
              )
          )
        ];
        const newlyAddedSegments = allSegments.filter(ds => ![...segments].includes(ds));
        const newlyRemovedSegments = [...segments].filter(ds => !allSegments.includes(ds));
        ['shiftMaxSequence', 'dayShiftMaxSequence', 'eveningShiftMaxSequence', 'nightShiftMaxSequence', 'longShiftMaxSequence']
            .forEach(seq => {
              prevEmployees[employeeIndex][seq] = Math.min(
                  resolveMaxSeqValueBasedOnVacancyRate(
                      newlyAddedSegments,
                      prevEmployees[employeeIndex]['vacancyRate'],
                      seq, prevEmployees[employeeIndex][seq]
                      ),
                  shiftMaxSeq
              )
            })

        if(newlyRemovedSegments.includes('D')) {
          prevEmployees[employeeIndex]['dayShiftMinSequence'] = 0;
          prevEmployees[employeeIndex]['dayShiftMaxSequence'] = 0;
        }
        if(newlyRemovedSegments.includes('A')) {
          prevEmployees[employeeIndex]['eveningShiftMinSequence'] = 0;
          prevEmployees[employeeIndex]['eveningShiftMaxSequence'] = 0;
        }
        if(newlyRemovedSegments.includes('N')) {
          prevEmployees[employeeIndex]['nightShiftMinSequence'] = 0;
          prevEmployees[employeeIndex]['nightShiftMaxSequence'] = 0;
        }
        if(newlyRemovedSegments.includes('L')) {
          prevEmployees[employeeIndex]['longShiftMinSequence'] = 0;
          prevEmployees[employeeIndex]['longShiftMaxSequence'] = 0;
        }
      }
      /* End automatically setting max sequences if a new daySegment is selected */

      /* Start automatically setting day segment max sequence if shiftMaxSequence has been lowered */
      if(change.columnId === "shiftMaxSequence") {
        const newShiftMax = parseInt(change.newCell.text);
        prevEmployees[employeeIndex]['dayShiftMinSequence'] = Math.min(prevEmployees[employeeIndex]['dayShiftMinSequence'], newShiftMax);
        prevEmployees[employeeIndex]['dayShiftMaxSequence'] = Math.min(prevEmployees[employeeIndex]['dayShiftMaxSequence'], newShiftMax);
        prevEmployees[employeeIndex]['eveningShiftMinSequence'] = Math.min(prevEmployees[employeeIndex]['eveningShiftMinSequence'], newShiftMax);
        prevEmployees[employeeIndex]['eveningShiftMaxSequence'] = Math.min(prevEmployees[employeeIndex]['eveningShiftMaxSequence'], newShiftMax);
        prevEmployees[employeeIndex]['nightShiftMinSequence'] = Math.min(prevEmployees[employeeIndex]['nightShiftMinSequence'], newShiftMax);
        prevEmployees[employeeIndex]['nightShiftMaxSequence'] = Math.min(prevEmployees[employeeIndex]['nightShiftMaxSequence'], newShiftMax);
        prevEmployees[employeeIndex]['longShiftMinSequence'] = Math.min(prevEmployees[employeeIndex]['longShiftMinSequence'], newShiftMax);
        prevEmployees[employeeIndex]['longShiftMaxSequence'] = Math.min(prevEmployees[employeeIndex]['longShiftMaxSequence'], newShiftMax);
        prevEmployees[employeeIndex]['weekendShiftMaxSequence'] = Math.min(prevEmployees[employeeIndex]['weekendShiftMaxSequence'], newShiftMax);
      }
      /* End automatically setting day segment max sequence if shiftMaxSequence has been lowered */

      /* Start automatically setting max sequences based on vacancy rate */
      const updatedSegments = [... new Set(
          Object.keys(prevEmployees[employeeIndex]['shiftTypes'])?.map(code => shiftTypes.filter(sh => sh.code === code)[0].daySegment)
      )]
      if(['vacancyRate'].includes(change.columnId)) {
        ['shiftMaxSequence', 'dayShiftMaxSequence', 'eveningShiftMaxSequence', 'nightShiftMaxSequence', 'longShiftMaxSequence']
            .forEach(seq => {
              prevEmployees[employeeIndex][seq] = resolveMaxSeqValueBasedOnVacancyRate(
                  updatedSegments,
                  prevEmployees[employeeIndex]['vacancyRate'],
                  seq, prevEmployees[employeeIndex][seq]
              )
            })
      }
      /* End automatically setting max sequences based on vacancy rate */

      /* Start handling pasting in invalid values */
      const maxConsecOptions = shiftOptions();
      const maxSeqFields = ["shiftMaxSequence", "dayShiftMaxSequence", "eveningShiftMaxSequence", "longShiftMaxSequence", "nightShiftMaxSequence"]
      const minSeqFields = ["shiftMinSequence", "dayShiftMinSequence", "eveningShiftMinSequence", "nightShiftMinSequence"];
      if(maxSeqFields.includes(fieldName) && !(['shiftMaxSequence'].includes(fieldName) ? maxConsecOptions : daySegmentMaxOptions).includes(parseInt(cell.text))) {
        cell.value = lastValue(fieldName === ['shiftMaxSequence', 'nightShiftMaxSequence'].includes(fieldName) ? maxConsecOptions : daySegmentMaxOptions);
        return prevEmployees[employeeIndex][fieldName] = cell.value;
      }
      if(minSeqFields.includes(fieldName) && !lateShiftOptions.includes(parseInt(cell.text))) {
        return prevEmployees[employeeIndex][fieldName] = 0;
      }
      if(fieldName === "longShiftMinSequence" && !longShiftOptions.includes(parseInt(cell.text))) {
        return prevEmployees[employeeIndex][fieldName] = 0;
      }
      if(["nrOfQuickReturnsPerWeekOnWeekend", "nrOfQuickReturnsPerWeekOnWeekdays"].includes(fieldName)&& !weekendOptions.includes(parseInt(cell.text))) {
        return prevEmployees[employeeIndex][fieldName] = 1;
      }
      if(['name', 'comment'].includes(fieldName) && !validCharactersRegex().test(cell.text)) {
        return prevEmployees[employeeIndex][fieldName] = cell.text.split('').filter(c => validCharactersRegex().test(c)).join('');
      }
      /* End handling pasting in invalid values */

      /* Start handle setting invalid vacancyRte */
      if (change.type === "number" && fieldName !== "vacancyRate") {
        let val = Math.floor(cell.value);
        if (val > 999)
          return prevEmployees[employeeIndex][fieldName] = 999;
        if (val < 0)
          return prevEmployees[employeeIndex][fieldName] = "";
        return prevEmployees[employeeIndex][fieldName] = val;
      }
      /* End handle setting invalid vacancyRte */

      if (change.type === "dropdown" && cell.selectedValue !== undefined && (cell.values.filter(e => e.value === cell.selectedValue.split(", ")[0].trim() || cell.selectedValue === "")).length > 0)
        return prevEmployees[employeeIndex][fieldName] = cell.selectedValue.trim();
      if (change.type === "dropdown" && cell.selectedValue === undefined && cell.values.filter(e => e.value === cell.text.split(", ")[0].trim()).length > 0)
        return prevEmployees[employeeIndex][fieldName] = cell.text.trim();

      if (change.type === "dropdown-number" && (!isNaN(parseInt(cell.text)) || (cell.text === "-")))
        return prevEmployees[employeeIndex][fieldName] = parseInt(cell.text);

      if (change.type === "dropdown-number" && cell.text === "")
        return prevEmployees[employeeIndex][fieldName] = 0;

      switch (change.type) {
        case "checkbox": return prevEmployees[employeeIndex][fieldName] = cell.checked;
        case "text": return prevEmployees[employeeIndex][fieldName] = cell.text;
        default:
      }
    });
    return [...prevEmployees];
  };

  const applyChangesToEmployees = (changes, prevEmployees) => {
    const updated = applyNewValue(changes, prevEmployees);
    setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes]);
    setCellChangesIndex(cellChangesIndex + 1);
    setIsDirty(true);
    return updated;
  };

  const undoChanges = (changes, prevEmployees) => {
    const updated = applyNewValue(changes, prevEmployees, true);
    setFocus({ columnId: changes[0].columnId, rowId: changes[0].rowId });
    setTimeout(() => { setFocus(false); }, 1000);
    setCellChangesIndex(cellChangesIndex - 1);
    setIsDirty(true);
    return updated;
  };

  const redoChanges = (changes, prevEmployees) => {
    const updated = applyNewValue(changes, prevEmployees);
    setFocus({ columnId: changes[0].columnId, rowId: changes[0].rowId });
    setTimeout(() => { setFocus(false); }, 1000);
    setCellChangesIndex(cellChangesIndex + 1);
    setIsDirty(true);
    return updated;
  };

  const handleChanges = (changes) => {
    setChangedIndexes([...changedIndexes, ...changes.filter(c => !changedIndexes.includes(c.rowId)).map(c => c.rowId)])
    setOpenedDropdownLocation(findOpenedDropdownCellLocation(changes));
    setEmployees((prevEmployees) => applyChangesToEmployees(changes, prevEmployees));
  };

  const handleRowsReorder = (targetRowId, rowIds, dropPosition) => {
    if(applySearchFiltersToEmployees(employees).length !== employees.length) return;
    setIsDirty(true);
    setChangedIndexes(employees.map((e, i) => i));
    setRows((prevRows) => {
      const to = rows.findIndex(row => row.rowId === targetRowId);
      const columnIdxs = rowIds.map(id => rows.findIndex(r => r.rowId === id));
      let newOrder = reorderArray(prevRows, columnIdxs, to);
      let idx = 1;
      setEmployees(newOrder.map(r => {
        r._employee.priority = idx++;
        return r._employee;
      }));
      return newOrder
    });
    setCellChangesIndex(-1);
  }
  const reorderArray = (arr, idxs, to) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx));
    to = Math.min(...idxs) < to ? to += 1 : to -= idxs.filter(idx => idx < to).length;
    const leftSide = arr.filter((_, idx) => idx < to && !idxs.includes(idx));
    const rightSide = arr.filter((_, idx) => idx >= to && !idxs.includes(idx));
    return [...leftSide, ...movedElements, ...rightSide];
  }

  const [rows, setRows] = useState(getRows(employees));
  const columns = getColumns();

  const handleContextMenu = (
      selectedRowIds,
      selectedColIds,
      selectionMode,
      menuOptions
  ) => {
    if (selectionMode === "row") {
      menuOptions = [
        ...menuOptions,
        {
          id: "deleteEmployee",
          label: "Slett rad",
          handler: () => {
            setShowDeleteModal({ display: true, rows: selectedRowIds })
          }
        }
      ];
    }
    menuOptions[2].handler = () => { setShowHelp(true) };
    return menuOptions;
  }

  const handleUndoChanges = () => {
    if (cellChangesIndex >= 0) {
      setEmployees((prevEmployees) =>
          undoChanges(cellChanges[cellChangesIndex], prevEmployees)
      );
    }
  };

  const handleRedoChanges = () => {
    if (cellChangesIndex + 1 <= cellChanges.length - 1) {
      setEmployees((prevEmployees) =>
          redoChanges(cellChanges[cellChangesIndex + 1], prevEmployees)
      );
    }
  };

  const lastValue = (options) => {
    return options[options.length - 1];
  }

  const timer = ms => new Promise(res => setTimeout(res, ms));

  const saveSheet = async () => {
    let errors = [];
    const rowsChanged = [...new Set(changedIndexes)]
    setShowSavingModal({ display: true, saveCount: 0, toSave: rowsChanged.length });

    for (const [index, employee] of employees.entries()) {
      if(!changedIndexes.includes(index)) continue;
      setShowSavingModal({ display: true, saveCount: showSavingModal.saveCount++, toSave: rowsChanged.length, empl: employee.name});
      await saveEmployee(employee).catch(error => {
        console.error(error);
        errors.push({ message: `Feil på rad ${index}` });
      });
      await timer(500);
      setShowSavingModal({ display: true, saveCount: showSavingModal.saveCount, toSave: rowsChanged.length, empl: employee.name });
    }

    setIsDirty(false);
    setShowSavingModal({ display: false, saveCount: 0, toSave: 0 });
    notifications.addNotification({
      content: "Ansatte lagret",
      duration: 2000,
      container: "center",
      type: "success"
    });
    getDepartmentEmployees();
    setRefresh(Math.random())
    setChangedIndexes([]);
  };

  const saveEmployee = async (employee) => {
    const employeeData = {
      "name": employee.name,
      "position": positionStrToId(employee.positionName.trim()),
      "shiftTypes": Object.fromEntries(Object.entries(employee.shiftTypes)?.map(([code, sh]) => [shiftTypesStrToIds(code)[0], {maxPerWeek: sh.maxPerWeek}])),
      "vacancyRate": employee.vacancyRate,
      "enabled": employee.enabled,
      "dayShiftMinSequence": employee.dayShiftMinSequence,
      "dayShiftMaxSequence": employee.dayShiftMaxSequence,
      "maxDayShifts": employee.maxDayShifts,
      "shiftMinSequence": employee.shiftMinSequence,
      "shiftMaxSequence": employee.shiftMaxSequence,
      "eveningShiftMinSequence": employee.eveningShiftMinSequence,
      "eveningShiftMaxSequence": employee.eveningShiftMaxSequence,
      "maxEveningShifts": employee.maxEveningShifts,
      "nightShiftMinSequence": employee.nightShiftMinSequence,
      "nightShiftMaxSequence": employee.nightShiftMaxSequence,
      "maxNightShifts": employee.maxNightShifts,
      "longShiftMinSequence": employee.longShiftMinSequence,
      "longShiftMaxSequence": employee.longShiftMaxSequence,
      "maxLongShifts": employee.maxLongShifts,
      "worksOnlyWeekend": employee.worksOnlyWeekend === true,
      "nrOfQuickReturnsPerWeekOnWeekend": employee.nrOfQuickReturnsPerWeekOnWeekend,
      "nrOfQuickReturnsPerWeekOnWeekdays": employee.nrOfQuickReturnsPerWeekOnWeekdays,
      "traits": employee.traitNames.trim().length > 0 ? competencesStrToIds(employee.traitNames.trim()) : [],
      "redDaysMaxSequence": employee.redDaysMaxSequence,
      "movableHolidaysMaxPerTurnus": employee.movableHolidaysMaxPerTurnus,
      "comment": employee.comment.trim(),
      "weekendShiftMaxSequence": employee.weekendShiftMaxSequence !== null
          ? Math.min(employee.weekendShiftMaxSequence, employee.shiftMaxSequence)
          : -1,
      "dayShiftsMaxPerWeek": employee.dayShiftsMaxPerWeek !== null
          ? employee.dayShiftsMaxPerWeek
          : -1,
      "eveningShiftsMaxPerWeek": employee.eveningShiftsMaxPerWeek !== null
          ? employee.eveningShiftsMaxPerWeek
          : -1,
      "longShiftsMaxPerWeek": employee.longShiftsMaxPerWeek !== null
          ? employee.longShiftsMaxPerWeek
          : -1,
      "nightShiftsMaxPerWeek": employee.nightShiftsMaxPerWeek !== null
          ? employee.nightShiftsMaxPerWeek
          : -1,
      "maxShifts": employee.maxShifts !== null
          ? employee.maxShifts
          : -1,
      "maxNightShiftWeekends": employee.maxNightShiftWeekends !== null
          ? employee.maxNightShiftWeekends
          : -1,
      "priority": employee.priority,
      "sundaysMaxSequence": employee.sundaysMaxSequence
    };

    if (employee.id !== undefined && !employee.id.includes(placeholderId)) {
      await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${employee.id}`, employeeData);
    } else {
      let response = await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee`, employeeData);
      employee.id = response?.id;
    }
  };

  const deleteEmployees = async (employeeIds) => {
    employeeIds.forEach(async (idx) => {
      if (employees[idx].id !== undefined && !employees[idx].id.includes(placeholderId)) {
        await dynoapeAPI.delete(`/api/v1/department/${departmentId}/employee/${employees[idx].id}`);
      }
    });
    setEmployees(prevEmployees => {
      return [...prevEmployees.filter((person, idx) => !employeeIds.includes(idx))];
    });
  };

  const [showAdvanced, setShowAdvanced] = useState(() => {
    setVisibles(advanced, false);
    return false;
  });

  useEffect(() => {
    setIsLoading(true)
    setEmployees(employees);
    setRows(getRows(employees));
    setIsLoading(false);
  }, [showAdvanced]);

  const [configErrors, setConfigErrors] = useState([]);
  const [configWarnings, setConfigWarnings] = useState([])
  const [dynomiteInternalError, setDynomiteInternalError] = useState(false);

  const validate = (department, employees, positions, traits, shiftTypes) => {
    if((employees ?? []).length === 0) return;
    try {
      const config = convertToExtendedEmployeeConfig({department: department, employees: employees, restTraits: traits, restPositions: positions, restShiftTypes: shiftTypes});
      const conf = dynomite.dynomite.parseEmployeesExtended(JSON.stringify(config));
      setConfigWarnings(conf.warnings ?? [])
      setConfigErrors([]);
      setDynomiteInternalError(undefined);
    } catch (err) {
      if(err.payload) {
        setConfigErrors(err.payload.filter(e => !e.tag.includes('Warning::')));
        setConfigWarnings(err.payload.filter(e => e.tag.includes('Warning::')));
      } else {
        setDynomiteInternalError(true);
      }
    }
  }

  const inputHasError = (id) => {
    return configErrors.filter(err => err.path.includes(`/${id}`)).length > 0
  }

  return (
      <div>
        {isLoading ?
            <Spinner show /> : !employees.length ?
                <div><Button primary onClick={() => { addRow(); setFocus({ columnId: 'name', rowId: employees.length }); setTimeout(() => { setFocus(false); }, 1000); }}
                             data-for="add-row"
                             data-tip="Dersom du ønsker å legge til en ansatt klikker du her. Det blir da automatisk lagt til en linje som du kan fylle ut med spesifikasjoner for den ansatte">Ny rad</Button>
                  <Tooltip id="add-row" />
                  <Button primary style={{ marginLeft: "10px" }} onClick={() => setShowMultiAddModal({ display: true, amount: 0, error: false })}
                          data-for="add-rows"
                          data-tip="Dersom du ønsker å legge til flere ansatt klikker du her, og angir antall ansatte du ønsker å legge til. Det blir da automatisk lagt til det gitte antall linjer som du kan fylle ut med spesifikasjoner for de ansatte">Legg til flere rader</Button>
                  <Tooltip id="add-rows" />
                  <h4>Ingen ansatte funnet</h4></div>
                : <div><Flex flexDirection={"row"}>
                  <Button primary onClick={() => { addRow(); setFocus({ columnId: 'name', rowId: employees.length }); setTimeout(() => { setFocus(false); }, 1000); }}
                          data-for="add-row"
                          data-tip="Dersom du ønsker å legge til en ansatt klikker du her. Det blir da automatisk lagt til en linje som du kan fylle ut med spesifikasjoner for den ansatte">Ny rad</Button>
                  <Tooltip id="add-row" />
                  <Button primary style={{ marginLeft: "10px" }} onClick={() => setShowMultiAddModal({ display: true, amount: 0, error: false })}
                          data-for="add-rows"
                          data-tip="Dersom du ønsker å legge til flere ansatt klikker du her, og angir antall ansatte du ønsker å legge til. Det blir da automatisk lagt til det gitte antall linjer som du kan fylle ut med spesifikasjoner for de ansatte">Legg til flere rader</Button>
                  <Tooltip id="add-rows" />
                  <Button warning style={{ marginLeft: "10px" }} onClick={() => handleUndoChanges()}
                          data-for="undo"
                          data-tip="Angre endringer som er gjort i listen">Angre</Button>
                  <Tooltip id="undo" />
                  <Button tertiary
                          data-for="column-visibility"
                          data-tip="Vis/skjul avanserte innstillinger"
                          style={{ marginLeft: "10px" }} onClick={() => {
                    let adv = !showAdvanced;
                    setVisibles(advanced, adv);
                    setShowAdvanced(adv);
                  }}
                  >
                    {showAdvanced ? "Skjul" : "Vis"} avanserte innstillinger
                  </Button>
                  <Tooltip id="column-visibility" />
                  <Button primary style={{ marginLeft: "10px" }} onClick={() => saveSheet()}
                          data-for="save"
                          data-tip="Lagre dine spesifikasjoner i ansattlisten"
                  >Lagre</Button>
                  <Tooltip id="save" />
                  <Tooltip id="employee-search" />
                  <Flex style={{marginLeft: "20px", width: "160px"}}>
                    <Input
                        data-for="employee-search"
                        data-tip="Søk på ansatt"
                        placeholder="Søk på ansatt"
                        style={{width: "160px", borderColor: "hsl(0, 0%, 80%)", background: "white"}}
                        onChange={(e) => handleSearchChange({...searchFilters, employee: e.target.value})} />
                  </Flex>
                  <Tooltip id="position-search" />
                  <Flex
                      data-for="position-search"
                      data-tip="Søk på stilling"
                      style={{marginLeft: "20px"}}>
                    <Select
                        placeholder="Søk på stilling"
                        options={positions.map(p => ({label: p.name, value: p.id}))}
                        style={{width: "160px", maxWidth: "160px", zIndex: "1000"}}
                        isMulti={true}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '160px',
                            maxWidth: '160px',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 2000,
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            maxWidth: 'calc(100% - 5px)',
                          }),
                        }}
                        onChange={(e) => handleSearchChange({...searchFilters, positions:  e.map(p => p.value)})}
                    />
                  </Flex>
                  <Tooltip id="trait-search" />
                  <Flex
                      data-for="trait-search"
                      data-tip="Søk på kompetanse"
                      style={{marginLeft: "20px"}}>
                    <Select
                        placeholder="Søk på kompetanse"
                        options={competences.map(p => ({label: p.name, value: p.id}))}
                        style={{width: "200px", maxWidth: "200px", zIndex: "1000"}}
                        isMulti={true}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '200px',
                            maxWidth: '200px',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 2000,
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            maxWidth: 'calc(100% - 5px)',
                          }),
                        }}
                        onChange={(e) => handleSearchChange({...searchFilters, traits:  e.map(p => p.value)})}
                    />
                  </Flex>
                  <Tooltip id="daySegment-search" />
                  <Flex
                      data-for="daySegment-search"
                      data-tip="Søk på vakttype"
                      style={{marginLeft: "20px"}}>
                    <Select
                        placeholder="Søk på vakttype"
                        options={["D", "A", "N", "L"].map(ds => ({label: ds, value: ds}))}
                        style={{width: "170px", maxWidth: "170px", zIndex: "1000"}}
                        isMulti={true}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '170px',
                            maxWidth: '170px',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 2000,
                          }),
                          multiValue: (provided) => ({
                            ...provided,
                            maxWidth: 'calc(100% - 20px)',
                          }),
                        }}
                        onChange={(e) => handleSearchChange({...searchFilters, daySegments:  e.map(p => p.value)})}
                    />
                  </Flex>
                </Flex>
                  <div className="reactgrid-wrapper">
                    <ReactGrid
                        customCellTemplates={{
                          'dropdown-number': DropdownCellTemplate,
                          'dropdown': DropdownSelectCellTemplate,
                          'checkbox': CheckboxCellTemplate
                        }}
                        rows={[
                          headerRow,
                          ...rows.map((row, idx) => idx % 2 !== 0
                              ? { ...row,
                                cells: row.cells.map(cell => (
                                    { ...cell, style: { boxShadow: inputHasError(`${row._employee.id}/${cell.colId}`) ? "0 0 10px 0 red inset" : "",  background: 'rgba(0,0,0,0.07)', color: !row._employee.enabled ? "#888" : "black"} })
                                )
                              }
                              : { ...row,
                                cells: row.cells.map(cell => (
                                    { ...cell, style: {  boxShadow: inputHasError(`${row._employee.id}/${cell.colId}`) ? "0 0 10px 0 red inset" : "", color: !row._employee.enabled ? "#888" : "black"} })
                                )
                              }
                          ),
                          footerRow
                        ]}
                        columns={columns}
                        stickyTopRows={1}
                        stickyBottomRows={1}
                        stickyLeftColumns={onlyStickyColumns ? 1 : 5}
                        onCellsChanged={handleChanges}
                        onContextMenu={handleContextMenu}
                        onRowsReordered={handleRowsReorder}
                        highlights={[]}
                        enableRowSelection
                        //enableColumnSelection
                        enableRangeSelection
                        enableFillHandle
                        labels={{
                          copyLabel: 'Kopier',
                          cutLabel: 'Klipp ut',
                          pasteLabel: 'Lim inn'
                        }}
                        focusLocation={focus}
                    />
                  </div>
                </div>
        }
        {showMultiAddModal.display && (
            <Modal
                title="Legg til rader"
                cancel={() => {
                  setShowMultiAddModal({ display: false, amount: 0, error: false });
                }}
                cancelButtonProps={{
                  children: 'Avbryt'
                }}
                done={() => {
                  if (showMultiAddModal.error) {
                    return;
                  }

                  for (let i = 0; i < showMultiAddModal.amount; i++) {
                    const employeesCount = employees.length;
                    addRow(employeesCount + i);
                  }

                  let _changed = changedIndexes;
                  for (let i = employees.length; i < parseInt(employees.length) + parseInt(showMultiAddModal.amount); i++) {
                    _changed = _changed.includes(i) ? _changed : [..._changed, i];
                  }
                  setChangedIndexes(_changed)

                  setFocus({ columnId: 'name', rowId: employees.length });
                  setTimeout(() => { setFocus(false) }, 1000);
                  setShowMultiAddModal({ display: false, amount: 0, error: false });
                }}
                action="Legg til"
            >
              <Input
                  onChange={(e) => {

                    if (e.target.value.length < 0 || parseInt(e.target.value) <= 0 || !Number.isInteger(parseInt(e.target.value))) {
                      setShowMultiAddModal({ display: true, amount: e.target.value, error: true });
                      return;
                    }

                    setShowMultiAddModal({ display: true, amount: e.target.value, error: false });
                  }}
                  defaultValue=""
                  placeholder="Antall rader"
              />
              {showMultiAddModal.error && (
                  <p style={{ color: "#BF3D2B" }}>Må være et tall over 0</p>
              )}
            </Modal>
        )}
        {showSavingModal.display && (
            <Modal
                title="Lagring pågår"
                cancel={() => {

                }}
                cancelButtonProps={{
                  style: { display: "none" }
                }}
                primaryButtonProps={{
                  disabled: true
                }}
                action="Lukk"
            >
              <ProgressBar value={(100 * showSavingModal.saveCount) / showSavingModal.toSave} />
              <p>{showSavingModal.saveCount}/{showSavingModal.toSave} {showSavingModal.empl ? (" (Lagrer " + showSavingModal.empl + ")") : ""}</p>
            </Modal>
        )}
        {showDeleteModal.display && (
            <Modal
                title="Slett rader"
                cancel={() => {
                  setShowDeleteModal({ display: false, rows: [] });
                }}
                cancelButtonProps={{
                  children: 'Avbryt'
                }}
                done={() => {
                  deleteEmployees(showDeleteModal.rows);
                  setShowDeleteModal({ display: false, rows: [] });
                }}
                action="Slett rader"
            >
              <p>Du er i ferd med å slette {showDeleteModal.rows.length} rader - dette kan ikke angres, er du sikker på at du vil fortsette?</p>
            </Modal>
        )}
        {showModal && (
            <Modal
                title="Ingen stillinger opprettet"
                cancel={() => {

                }}
                cancelButtonProps={{
                  style: { display: "none" }
                }}
                done={() => {
                  history.push(`/avdelinger/${departmentId}/avdelingsoppsett`);
                }}
                action="Ja, gå til avdelingsoppsett"
                secondaryButtonProps={{
                  children: 'Nei, gå tilbake',
                  onClick: () => {
                    history.goBack();
                  }
                }}
            >
              <p>Denne avdelingen har ingen stillinger opprettet, vil du opprette disse nå?</p>
            </Modal>
        )}
        {showHelp && (
            <Modal
                title="Lim inn"
                done={() => {
                  setShowHelp(false);
                }}
                action="Lukk"
            >
              <ShortcutHelper>
                Denne handlingen er utilgjengelige via Rediger-menyene, men du kan fortsatt bruke:
                <Shortcut>Ctrl+V</Shortcut>
                for å lime inn
              </ShortcutHelper>
            </Modal>
        )}
        <RouterPrompt
            when={isDirty}
            title="Er du sikker på at du vil forlate siden?"
            onOK={() => true}
            onCancel={() => false}
        />
        {configErrors.length > 0 && dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError)}
        {configWarnings.length > 0 && dynomiteErrorPanel(dynomite, configWarnings, dynomiteInternalError, 0, () => {}, true)}
      </div>
  );
};

export default EmployeeTable;

const ShortcutHelper = styled.div`
  color: #666;
`;

const Shortcut = styled.div`
  font-size: 30px;
  font-weight: bold;
`;
