import React, { useEffect, useState } from "react";
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import { dynoapeAPI, getSortedPositions } from "../api/dynoape";
import { useParams } from "react-router-dom";
import Spinner from 'react-spinner-material';
import { CirclePicker } from 'react-color';
import {
    AvatarCardEmployeePosition,
    MaterialUIAddModal,
    MaterialUIDeleteModal,
    MaterialUIRoundAddButton
} from "../lib/styled-components";
import uniqolor from "uniqolor";
import {
    customMaterialReactTableLocalization,
    validCharactersRegex
} from "../lib/common";
import Icon from "../lib/icon";
import {MaterialReactTable} from "material-react-table";

const PositionsTable = () => {
    const defaultPosition = { name: "", color: "000000", originalColor: "000000", priority: 0 };

    const { departmentId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [showPositionModal, setShowModal] = useState({ "show": false, "id": undefined });
    const [showDeleteModal, setShowDeleteModal] = useState({ "show": false, "id": "" });
    const [showInvalidNameError, setShowInvalidNameError] = useState({ "show": false, "msg": "" });
    const [showInvalidAliasError, setShowInvalidAliasError] = useState({ "show": false, "msg": "" });
    const [position, setPositionInput] = useState({ ...defaultPosition });
    const [positions, setPositions] = useState([]);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const columns = [
        {
            accessorKey: 'name',
            header: 'Navn',
            size: 100,
            muiTableBodyCellProps: {
                sx: {
                    paddingLeft: '50px',
                },
            },
            muiTableHeadCellProps: {
                sx: {
                    paddingLeft: '50px',
                },
            },
            Cell: ({ cell, row }) =>
                <AvatarCardEmployeePosition
                    style={{
                        backgroundColor: row.original.color !== "000000"
                            ? `#${row.original.color}`
                            : uniqolor(row.original.id, { format: 'rgb' }).color,
                        color: "white"
                    }}
                >
                    {cell.getValue()}
                </AvatarCardEmployeePosition>
        },
        {
            accessorKey: 'alias',
            header: 'Alias',
            size: 150
        },
        {
            header: 'Handlinger',
            size: 50,
            Cell: ({ row }) => (
                <>
                    <Button
                        variant="contained"
                        data-testid={`update-${row.original.id}`}
                        sx={{backgroundColor: "rgb(48, 105, 112)", borderRadius: "20px"}}
                        onClick={() => {
                            const pos = row.original;
                            if (pos.color !== "000000") {
                                setDisplayColorPicker(true);
                            }
                            setPositionInput({
                                name: pos.name,
                                color: pos.color,
                                originalColor:
                                pos.color, priority:
                                pos.priority,
                                alias: pos.alias
                            });
                            setShowModal({ "show": true, "id": pos.id });
                        }}
                        style={{ marginRight: "5px"}}
                    >
                        <Icon name="edit-w" />
                    </Button>
                    <Button
                        sx={{borderRadius: "20px"}}
                        variant="contained"
                        data-testid={`delete-${row.original.id}`}
                        color="error"
                        onClick={() => setShowDeleteModal({ "show": true, "id": row.original.id })}
                    >
                        <Icon name="trash-w"/>
                    </Button>
                </>
            ),
        }
    ]

    const closeModal = () => {
        setShowInvalidNameError({ "show": false, "msg": "" });
        setPositionInput({ ...defaultPosition });
        setShowModal({ "show": false, "id": undefined });
        setDisplayColorPicker(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            let pos = await getSortedPositions(departmentId);
            setPositions(pos);
            setIsLoading(false);
        }
        fetchData()
    }, []);

    const save = async () => {
        if (positions.length >= 5 && showPositionModal.id === undefined) {
            setShowInvalidNameError({ "show": true, "msg": "Kan ikke ha flere enn 5 aktive stillinger" });
            return;
        }
        if (position.name.trim().length <= 0) {
            setShowInvalidNameError({ "show": true, "msg": "Stillingsnavn er påkrevd" });
            return;
        }
        if (showInvalidNameError.show || showInvalidAliasError.show) {
            return;
        }

        delete position.originalColor;
        showPositionModal.id !== undefined
            ? await dynoapeAPI.put(`/api/v1/department/${departmentId}/position/${showPositionModal.id}`, position)
            : await dynoapeAPI.post(`/api/v1/department/${departmentId}/position`, position);

        setPositions(await getSortedPositions(departmentId));
        closeModal();
    }

    const deletePosition = async (id) => {
        try {
            await dynoapeAPI.delete(`/api/v1/department/${departmentId}/position/${id}`);
            setPositions(positions.filter(p => { return p.id !== id }));
        } catch (error) {
            console.error(error);
        }
        setShowDeleteModal({ show: false, id: "" });
    };

    return (
        <div>
            <MaterialUIRoundAddButton
                onClick={() => setShowModal({ "show": true, "id": undefined })}
                text="Legg til stillingskategori"
            />
            <span style={{ float: "right" }}>* Du kan maks legge til 5 stillinger</span>
            {isLoading
                ? <Spinner radius={120} color={"#333"} stroke={2} visible={true} />
                : !positions?.length
                    ? <div style={{ background: "white", padding: "10px 20px 20px 10px" }}><h4>Ingen stillinger funnet</h4></div>
                    : <MaterialReactTable
                        columns={columns}
                        data={positions}
                        localization={customMaterialReactTableLocalization}
                    />
            }
            <MaterialUIAddModal
                open={showPositionModal.show}
                onClose={() => closeModal()}
                title={showPositionModal.id !== undefined ? "Endre stillingskategori" : "Legg til ny stillingskategori"}
                onConfirm={() => save()}
            >
                <div style={{marginBottom: "10px"}}>
                    <TextField
                        sx={{
                            input: {
                                fontFamily: "Montserrat Alternates",
                                height: "5px",
                                width: "300px"
                            }
                        }}
                        slotProps={{ input: { 'data-testid': 'nameInput' } }}
                        onChange={(e) => {
                            let name = e.target.value.trim();

                            if (name.length <= 0) {
                                setShowInvalidNameError({ "show": true, "msg": "Et navn er påkrevd, skriv inn et navn på stillingen" });
                                return;
                            }

                            if (positions.filter(p => p.name.toLowerCase() === name.toLowerCase() && p.id !== showPositionModal.id).length > 0) {
                                setShowInvalidNameError({ "show": true, "msg": "Det finnes allerede en stilling med det navnet" });
                                return;
                            }

                            if (name.length > 30) {
                                setShowInvalidNameError({ "show": true, "msg": "Navn på stillingen kan ikke være lenger enn 30 tegn" });
                                return;
                            }

                            if (!validCharactersRegex().test(name)) {
                                setShowInvalidNameError({ "show": true, "msg": "Navn på stillingen kan ikke inneholde ugyldige tegn" });
                                return;
                            }

                            setShowInvalidNameError({ "show": false, "msg": "" });
                            setPositionInput({ ...position, name: name });
                        }}
                        defaultValue={position.name}
                        placeholder="Navn"
                    />
                </div>
                <div style={{marginBottom: "10px"}}>
                    <TextField
                        sx={{
                            input: {
                                fontFamily: "Montserrat Alternates",
                                height: "5px",
                                width: "300px"
                            }
                        }}
                        slotProps={{ input: { 'data-testid': 'aliasInput' } }}
                        onChange={(e) => {
                            let alias = e.target.value.trim();

                            if (alias.length > 30) {
                                setShowInvalidAliasError({ "show": true, "msg": "Alias på stillingen kan ikke være lenger enn 30 tegn" });
                                return;
                            }

                            if (!validCharactersRegex().test(alias)) {
                                setShowInvalidNameError({ "show": true, "msg": "Alias på stillingen kan ikke inneholde ugyldige tegn" });
                                return;
                            }
                            if(alias.length === 0) alias = undefined;

                            setShowInvalidAliasError({ "show": false, "msg": "" });
                            setPositionInput({ ...position, alias: alias });
                        }}
                        defaultValue={position.alias}
                        placeholder="Alias"
                    />
                </div>
                <FormControlLabel
                    style={{marginBottom: "10px"}}
                    control={
                        <Checkbox
                            checked={displayColorPicker}
                            onChange={(e) => {
                                if (!e.target.checked)
                                    setPositionInput({ ...position, color: "000000" });
                                setDisplayColorPicker(e.target.checked);
                            }}
                        />
                    }
                    label="Egendefinert farge"
                />
                {displayColorPicker && <div style={{marginBottom: "20px" }}><CirclePicker
                    width="650px"
                    color={`#${position.color}`}
                    colors={[
                        "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5",
                        "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50",
                        "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800"].filter(
                        color => {
                            return !positions.some(p => p.color === color.substring(1) && p.color !== position.originalColor);

                        }
                    )}
                    onChangeComplete={(e) => {
                        setPositionInput({ ...position, color: e.hex.substring(1) });
                    }}
                /></div>}

                {showInvalidNameError.show && (
                    <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
                )}
                {showInvalidAliasError.show && (
                    <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
                )}

            </MaterialUIAddModal>
            <MaterialUIDeleteModal
                open={showDeleteModal.show}
                onClose={() => setShowDeleteModal({ show: false, id: undefined })}
                title={"Fjern stillingskategori"}
                confirmationText={`Er du sikker på at du vil slette stillingskategori ${positions.filter(sh => sh.id === showDeleteModal.id)?.[0]?.name}?`}
                deleteText={"Slett stillingskategori"}
                onConfirm={() => deletePosition(showDeleteModal.id)}
                isLoading={isLoading}
            />
        </div>
    );
};

export default PositionsTable;