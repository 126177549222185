import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Spinner, Modal, Checkbox, Flex, notifications} from "rendition";
import { CombinedCheckbox } from "./checkboxes";
import {
  dynoapeAPI,
  getSortedCompetences, getSortedEmployees,
  getSortedPositions,
  getSortedShiftTypes,
  rawDynoapeAPI
} from "../api/dynoape";
import {useParams, useHistory, NavLink} from "react-router-dom";
import { RouterPrompt } from "../lib/confirm/router-prompt";
import { useForm, Controller } from "react-hook-form";
import {
  CardRow,
  InputDivider,
  InputWithValidation,
  InputCard,
  EmployeeNumberInput,
  SolidInput,
  TextArea, TransparentCard
} from "../lib/styled-components";
import { Collapsible} from "grommet";
import ErrorMessage from './error-message';
import {
  maxNrOfDecimalRegex,
  validCharactersPattern,
  sortShiftTypesByDaySegment, dynomiteErrorPanel, convertToExtendedEmployeeConfig
} from "../lib/common";
import Tooltip from "../lib/tooltip";
import { MultiSelectPicker } from "./multi-select-picker";
import {FeatureToggles} from "../lib/feature-toggles";
import {daySegmentMaxOptions, shiftOptions} from "../lib/options";
import dynomiteContext from "./dynomite";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";

const EmployeePage = ({ initialEmployee, isNew }) => {
  const { departmentId } = useParams();
  const [employee, setEmployee] = useState(initialEmployee)

  const valOrDefault = (val, def=null) => {
    return val !== null && val !== undefined && val > -1 ? val : def;
  }

  const { watch, register, unregister, getValues, handleSubmit, control, formState: { errors, dirtyFields }, setValue } = useForm({
    defaultValues: {
      enabled: employee.enabled,
      worksOnlyWeekend: employee.worksOnlyWeekend,
      comment: employee.comment,
      traits: Object.entries(employee.traitNames)?.map(t => ({ label: t[1], value: t[0] })),
      shiftTypes: employee.shiftTypes,
      maxDayShifts: valOrDefault(employee.maxDayShifts),
      maxEveningShifts: valOrDefault(employee.maxEveningShifts),
      maxLongShifts: valOrDefault(employee.maxLongShifts),
      maxNightShifts: valOrDefault(employee.maxNightShifts),
      weekendShiftMaxSequence: valOrDefault(employee.weekendShiftMaxSequence, -1),
      dayShiftsMaxPerWeek: valOrDefault(employee.dayShiftsMaxPerWeek),
      eveningShiftsMaxPerWeek: valOrDefault(employee.eveningShiftsMaxPerWeek),
      longShiftsMaxPerWeek: valOrDefault(employee.longShiftsMaxPerWeek),
      nightShiftsMaxPerWeek: valOrDefault(employee.nightShiftsMaxPerWeek),
      maxShifts: valOrDefault(employee.maxShifts),
      maxNightShiftWeekends: valOrDefault(employee.maxNightShiftWeekends),
      sundaysMaxSequence: employee.sundaysMaxSequence
    }
  });

  const dynomite = useContext(dynomiteContext);
  const [configErrors, setConfigErrors] = useState([]);
  const [configWarnings, setConfigWarnings] = useState([]);
  const [dynomiteInternalError, setDynomiteInternalError] = useState(false);

  const validate = async (_employee) => {
    const config = convertToExtendedEmployeeConfig({
      department: {},
      employees: [Object.assign(employee, _employee)].map(e => {
        e.id = (employee.id ?? e.name) + "";
        e.enabled = true;
        e.vacancyRate = parseFloat((e.vacancyRate + "").replaceAll(",", "."));
        e.vacancyRate = e.vacancyRate === undefined || e.vacancyRate === "" || isNaN(e.vacancyRate) ? 0 : e.vacancyRate;
        e.shiftTypes = Object.fromEntries(
            selectedShiftTypes.map(opt =>
                Object.fromEntries(
                    Object.entries(
                        [
                          opt.label,
                          {
                            maxPerWeek: parseInt(e.shiftTypes[opt.label].maxPerWeek),
                            daySegment: shiftTypes.filter(sh => sh.code === opt.label)[0].daySegment
                          }
                        ]
                    ))
            )
        );
        e.weekendPatterns = [];
        e.paidLeavePatterns = [];
        e.vacationPatterns = [];
        e.holidayPatterns = [];
        e.blockedPatterns = []
        e.traits = e.traits?.map(tr => tr.value)
        Object.keys(e).forEach(field => {
          const allowedEmpty = [
            'maxDayShifts',
            'maxEveningShifts',
            'maxLongShifts',
            'maxNightShifts',
            'dayShiftsMaxPerWeek',
            'eveningShiftsMaxPerWeek',
            'longShiftsMaxPerWeek',
            'nightShiftsMaxPerWeek',
            'maxShifts',
            'maxNightShiftWeekends'
          ];
          if(allowedEmpty.includes(field) && ["", null, undefined].includes(e[field])) {
            e[field] = -1;
            return;
          }
          if(['name', 'id', 'comment', 'orgEmployeeId'].includes(field)) {
            return;
          }

          if(['true', 'false', true, false, ""].includes(e[field]) || Array.isArray(e[field])) {
            return;
          }
          e[field] = isNaN(Number(e[field])) ? e[field] : Number(e[field])
        })
        return e;
      }),
      restTraits: competences,
      restPositions: positions,
      restShiftTypes: shiftTypes,
      startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
      nrOfWeeks: 52}
    );
    try {
      const conf = dynomite.dynomite.parseEmployeesExtended(JSON.stringify(config));
      setConfigErrors([]);
      setConfigWarnings(conf.warnings ?? [])
      setDynomiteInternalError(undefined);
    } catch (err) {
      if(err.payload) {
        const errs = err.payload
            .filter(e =>
                !e.tag.includes('Warning::')
                && !e.path.includes('/weekend_patterns')
                && !e.path.includes('/vacation_patterns')
                && !e.path.includes('/paid_leave_patterns')
                && !e.path.includes('/holiday_patterns')
                && !e.path.includes('/blocked_patterns'));
        const warnings = err.payload.filter(e => e.tag.includes('Warning::'));
        setConfigErrors(errs)
        setConfigWarnings(warnings)
      } else {
        setDynomiteInternalError(true);
      }
    }
  }

  const getInputErrorBoxShadow = (id) => {
    return inputHasError(id) || (id === 'enabled' && !getValues().enabled)
        ? "0 0 8px 8px rgba(235, 8, 0, 0.17)"
        : "" ;
  }

  const inputHasError = (id) => {
    return configErrors.filter(err => err.path.includes(`/${id}`)).length > 0
  }

  const history = useHistory();
  const isDirty = !!Object.keys(dirtyFields).length;
  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [nextEmployee, setNextEmployee] = useState(undefined);
  const [previousEmployee, setPreviousEmployee] = useState(undefined);
  const [positions, setPositions] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [advShiftsSettingsOpen, setAdvShiftsSettingsOpen] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [orgEmployee, setOrgEmployee] = useState(undefined)
  const [authorizedToSeeOrgEmployee, setAuthorizedToSeeOrgEmployee] = useState(true);

  const [selectedShiftTypes, setSelectedShiftTypes] = useState(
      sortShiftTypesByDaySegment(employee.restShiftTypes)?.map(sh => ({label: sh.code, value: sh.id}))
  )
  const [selectedDaySegments, setSelectedDaySegments] = useState(
      [...new Set(employee.restShiftTypes.map(sh => sh.daySegment))]
  );

  const editable = () => {
    return editMode && getValues().enabled;
  }

  const showEmployeeDisabledWarning = (enabled) => {
    notifications.removeNotification("employee-disabled");
    if(!enabled) {
      notifications.addNotification({
        id: "employee-disabled",
        content: "Den ansatte er ikke tilgjengelig for vakter",
        duration: 100000,
        container: "top-center",
        type: "warning",
        prefix: "",
      });
    } else {
      notifications.removeNotification("employee-disabled");
    }
  }

  const eligibleForQuickReturns = () => {
    return ['A', 'D'].every(ds => selectedDaySegments.includes(ds))
        || ['A', 'L'].every(ds => selectedDaySegments.includes(ds))
        || ['L', 'D'].every(ds => selectedDaySegments.includes(ds))
  }

  useEffect(() => {
    if(!isLoading) {
      validate(getValues())
    }
  }, [selectedShiftTypes]);

  const onSubmit = data => {
    setSubmitted(true);
    putEmployee(data);
  };

  const getData = async () => {
    const [
      employeesData,
      positionsData,
      competencesData,
      shiftTypesData
    ] = await Promise.all([
      getSortedEmployees(departmentId),
      getSortedPositions(departmentId),
      getSortedCompetences(departmentId),
      getSortedShiftTypes(departmentId),
    ]);
    if (positionsData.length === 0)
      setShowModal(true);

    setEmployees(employeesData)
    if(isNew) {
      setEmployee(
          {
            ...employee,
            name: "Ny ansatt" + (employeesData.length +1), priority: (employeesData.length +1)
          }
      )
    } else {
      const currentEmployeeIndex = employeesData.findIndex(e => e.id === employee.id);
      setNextEmployee(
          currentEmployeeIndex < employeesData.length
              ? employeesData[currentEmployeeIndex + 1]
              : undefined
      );
      setPreviousEmployee(
          currentEmployeeIndex > 0
              ? employeesData[currentEmployeeIndex -1]
              : undefined
      );
    }

    setPositions(positionsData);
    setCompetences(competencesData);
    setShiftTypes(shiftTypesData);

    if(employee.orgEmployeeId) {
      const orgEmplResp = await rawDynoapeAPI.get(`/api/v1/org/org-employee/${employee.orgEmployeeId}`);
      if(orgEmplResp && orgEmplResp.status === 200) {
        setAuthorizedToSeeOrgEmployee(true);
        let orgEmpl = orgEmplResp.data.body;
        orgEmpl.employees = orgEmpl.employees.filter(e => e.id !== employee.id)
        setOrgEmployee(orgEmpl);
      } else if(orgEmplResp?.response?.status === 401) {
        setAuthorizedToSeeOrgEmployee(false)
      }
    }
    setIsLoading(false);
    showEmployeeDisabledWarning(employee.enabled)
  };

  useEffect(() => {
    getData();
  }, []);

  let positionOptions = (positions) => {
    return positions?.map((position, index) => <option key={`pos${index}`} value={position.id}>{position.name}</option>);
  };

  const handleFreeNrInputs = (elId, value) => {
    let val = Math.floor(parseInt(value)) || null;
    val = val > 999 ? 999 : val < 0 ? null : val;
    setValue(elId, val);
  };

  const putEmployee = async (data) => {
    delete data['selectedShiftTypes'];
    const employeeData = {
      ...data,
      "traits": data.traits?.map(t => t.value),
      "shiftTypes":  Object.fromEntries(
          selectedShiftTypes.map(opt =>
              Object.fromEntries(Object.entries([opt.value, {maxPerWeek: data.shiftTypes[opt.label].maxPerWeek}]))
          )
      ),
      "name": data.name.trim(),
      "vacancyRate": [data.vacancyRate.replace(/,/g, '.')].map(v => isNaN(v) || v === "" || v === undefined ? 0 : v)[0],
      "enabled": data.enabled === true,
      "nrOfQuickReturnsPerWeekOnWeekdays": data.nrOfQuickReturnsPerWeekOnWeekdays,
      "nrOfQuickReturnsPerWeekOnWeekend": data.nrOfQuickReturnsPerWeekOnWeekend,
      "comment": data.comment ? data.comment.trim() : "",
      "worksOnlyWeekend": data.worksOnlyWeekend === true,
      "dayShiftMinSequence": valOrDefault(data.dayShiftMinSequence, 0),
      "dayShiftMaxSequence": valOrDefault(data.dayShiftMaxSequence, 0),
      "eveningShiftMinSequence": valOrDefault(data.eveningShiftMinSequence, 0),
      "eveningShiftMaxSequence": valOrDefault(data.eveningShiftMaxSequence, 0),
      "nightShiftMinSequence": valOrDefault(data.nightShiftMinSequence, 0),
      "nightShiftMaxSequence": valOrDefault(data.nightShiftMaxSequence, 0),
      "longShiftMinSequence": valOrDefault(data.longShiftMinSequence, 0),
      "longShiftMaxSequence": valOrDefault(data.longShiftMaxSequence, 0),
      "maxDayShifts": valOrDefault(data.maxDayShifts),
      "maxEveningShifts": valOrDefault(data.maxEveningShifts),
      "maxLongShifts": valOrDefault(data.maxLongShifts),
      "maxNightShifts": valOrDefault(data.maxNightShifts),
      "dayShiftsMaxPerWeek": valOrDefault(data.dayShiftsMaxPerWeek),
      "eveningShiftsMaxPerWeek": valOrDefault(data.eveningShiftsMaxPerWeek),
      "longShiftsMaxPerWeek": valOrDefault(data.longShiftsMaxPerWeek),
      "nightShiftsMaxPerWeek": valOrDefault(data.nightShiftsMaxPerWeek),
      "maxShifts": valOrDefault(data.maxShifts),
      "maxNightShiftWeekends": valOrDefault(data.maxNightShiftWeekends),
      "priority": employee.priority,
      "redDaysMaxSequence": valOrDefault(data.redDaysMaxSequence, employee.redDaysMaxSequence),
      "shiftMinSequence": valOrDefault(data.shiftMinSequence, employee.shiftMinSequence),
      "movableHolidaysMaxPerTurnus": valOrDefault(data.movableHolidaysMaxPerTurnus, employee.movableHolidaysMaxPerTurnus),
    };

    const returnData = isNew
        ? await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee`, employeeData)
        : await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${employee.id}`, employeeData);

    if(!returnData) return setSubmitted(false);
    if(isNew) return history.goBack();

    setEmployee(returnData)
    setIsLoading(true)
    await getData();
    notifications.addNotification({
      content: "Ansatt lagret",
      duration: 2000,
      container: "top-center",
      type: "success"
    });
  }

  const maxConsecutiveShiftsOptions = shiftOptions().map(val => (<option key={`opt-${val}`} value={val}>{val}</option>));
  const daySegmentMaxSeqOptions = daySegmentMaxOptions.map(val => (<option key={`opt-${val}`} value={val}>{val}</option>));
  const  maxConsecutiveWeekendShiftsOptions = shiftOptions()
      .filter(val => val > 2)
      .map(val => (<option key={`opt-${val}`} value={val}>{val}</option>));

  const orgEmployeeToggleEnabled = FeatureToggles.FEATURE_TOGGLE_ORG_EMPLOYEE_MODULE.isEnabled();

  const adjustValuesFollowingVacancyRateChange = (vacancyRate) => {
    let _vacRate = parseFloat(vacancyRate);
    if(_vacRate > 100.0) {
      _vacRate = 100;
      setValue('vacancyRate', 100);
    }
    if(!selectedDaySegments.includes("D")) {
      setValue('dayShiftMinSequence', 0);
      setValue('dayShiftMaxSequence', 0);
    }
    if(!selectedDaySegments.includes("A")) {
      setValue('eveningShiftMinSequence', 0);
      setValue('eveningShiftMaxSequence', 0);
    }
    if(!selectedDaySegments.includes("N")) {
      setValue('nightShiftMinSequence', 0);
      setValue('nightShiftMaxSequence', 0);
    }
    if(!selectedDaySegments.includes("L")) {
      setValue('longShiftMinSequence', 0);
      setValue('longShiftMaxSequence', 0);
    }
    if(selectedDaySegments.includes("A")) setValue('eveningShiftMaxSequence', 2);
    if(selectedDaySegments.includes("N")) setValue('nightShiftMaxSequence', 3);
    if(selectedDaySegments.includes("L")) setValue('longShiftMaxSequence', 2);

    if(_vacRate <= 59) {
      if(selectedDaySegments.includes("D")) setValue('dayShiftMaxSequence', 3);
      setValue('shiftMaxSequence', 3);
    } else if(_vacRate <= 79) {
      if(selectedDaySegments.includes("D")) setValue('dayShiftMaxSequence', 4);
      setValue('shiftMaxSequence', 4);
    } else {
      if(selectedDaySegments.includes("D")) setValue('dayShiftMaxSequence', 5);
      setValue('shiftMaxSequence', 5);
    }
    setValue('weekendShiftMaxSequence', watch('shiftMaxSequence'));
  }

  const adjustValuesFollowingShiftCodesChange = (updatedShiftTypes) => {
    const currentSegments = [... new Set(selectedShiftTypes.map(sh => shiftTypes.find(_sh => _sh.id === sh.value).daySegment))];
    const updatedSegments = [... new Set(updatedShiftTypes.map(sh => shiftTypes.find(_sh => _sh.id === sh.value).daySegment))];

    const addedSegments = updatedSegments.filter(segment => !currentSegments.includes(segment));
    const removedSegments = currentSegments.filter(segment => !updatedSegments.includes(segment));

    const vacancyRate = parseFloat(watch('vacancyRate'));

    if(addedSegments.includes("D")) {
      if(vacancyRate <= 59) setValue('dayShiftMaxSequence', Math.min(3, parseInt(watch('shiftMaxSequence'))))
      if(vacancyRate <= 79) setValue('dayShiftMaxSequence', Math.min(4, parseInt(watch('shiftMaxSequence'))))
      if(vacancyRate >= 80) setValue('dayShiftMaxSequence', Math.min(5, parseInt(watch('shiftMaxSequence'))))
    } else if(removedSegments.includes("D")) {
      ['dayShiftMinSequence', 'dayShiftMaxSequence'].forEach(input => setValue(input, 0));
      ['dayShiftsMaxPerWeek', 'maxDayShifts'].forEach(input => setValue(input, null));
    }

    if(addedSegments.includes('A')) {
      setValue('eveningShiftMaxSequence', Math.min(2, parseInt(watch('shiftMaxSequence'))));
    } else if(removedSegments.includes('A')) {
      ['eveningShiftMinSequence', 'eveningShiftMaxSequence'].forEach(input => setValue(input, 0));
      ['eveningShiftsMaxPerWeek', 'maxEveningShifts'].forEach(input => setValue(input, null));
    }

    if(addedSegments.includes('N')) {
      setValue('nightShiftMaxSequence', Math.min(3, parseInt(watch('shiftMaxSequence'))));
    } else if(removedSegments.includes('N')) {
      ['nightShiftMinSequence', 'nightShiftMaxSequence'].forEach(input => setValue(input, 0));
      ['nightShiftsMaxPerWeek', 'maxNightShifts', 'maxNightShiftWeekends'].forEach(input => setValue(input, null));
    }

    if(addedSegments.includes('L')) {
      setValue('longShiftMaxSequence', Math.min(2, parseInt(watch('shiftMaxSequence'))));
    } else if(removedSegments.includes('L')) {
      ['longShiftMinSequence', 'longShiftMaxSequence'].forEach(input => setValue(input, 0));
      ['longShiftsMaxPerWeek', 'maxLongShifts'].forEach(input => setValue(input, null));
    }

    if(!eligibleForQuickReturns()) {
      setValue('nrOfQuickReturnsPerWeekOnWeekend', 1);
      setValue('nrOfQuickReturnsPerWeekOnWeekdays', 1);
    }
  }

  return (
      <>
        <form
            onSubmit={handleSubmit(onSubmit)}
            onChange={(e) => {
              if(e.target.name === 'enabled') {
                showEmployeeDisabledWarning(e.target.checked);
              }
              validate(getValues())
            }}
        >
          {isLoading ? <Spinner show /> :
              <div>
                <RouterPrompt
                    when={!submitted && isDirty && editMode}
                    title="Er du sikker på at du vil forlate siden?"
                    onOK={() => true}
                    onCancel={() => false}
                />

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <CardRow>
                    <TransparentCard>
                      {!isNew && <Checkbox
                          toggle
                          reverse
                          ml="300px"
                          mt="10px"
                          label="Rediger ansatt"
                          checked={editMode}
                          onChange={() => setEditMode(!editMode)}
                      />}
                    </TransparentCard>
                    {!isNew && <Flex justifyContent='left' alignItems='center'>
                      <NavLink
                          onClick={(e) => {
                            if(!previousEmployee) e.preventDefault();
                          }}
                          className="nav-link"
                          to={`./${previousEmployee ? previousEmployee.id : employee.id}`}>
                        <FontAwesomeIcon
                            size="2x"
                            style={{ color: previousEmployee ? "#26565B" : "#E5E7f0", cursor: "pointer"}}
                            icon={faChevronLeft}
                            data-for="previous-employee"
                            data-tip="Gå til forrige ansatt"/>
                      </NavLink>
                      <Tooltip id="previous-employee" />
                      <NavLink
                          onClick={(e) => {
                            if(!nextEmployee) e.preventDefault();
                          }}
                          className="nav-link"
                          to={`./${nextEmployee ? nextEmployee.id : employee.id}`}>
                        <FontAwesomeIcon
                            size="2x"
                            style={{ color: nextEmployee ? "#26565B" : "#E5E7f0", cursor: "pointer"}}
                            icon={faChevronRight}
                            data-for="next-employee"
                            data-tip="Gå til neste ansatt"/>
                      </NavLink>
                      <Tooltip id="next-employee" />
                    </Flex>}
                    <TransparentCard>
                      <div
                          style={{
                            display: "flex",
                          }}
                      >
                        <Flex>
                          <Button
                              primary
                              type="submit"
                              style={{display: editMode ? "block" : "none"}}
                              ml="390px"
                              data-for="employee-save"
                              data-tip="Du må lagre dine endringer før du forlater denne siden"
                          >Lagre</Button>
                          <Tooltip id="employee-save" />
                        </Flex>
                      </div >
                    </TransparentCard>
                  </CardRow>
                  <CardRow>
                    <InputCard>
                      <h3>Personalia</h3>
                      <InputDivider>
                        <label htmlFor={"name"}>Navn</label>
                        <InputWithValidation>
                          <SolidInput
                              name="name"
                              readOnly={!editMode}
                              placeholder="Navn"
                              defaultValue={employee.name}
                              style={{ width: "300px", backgroundColor: editMode ? "white": "WhiteSmoke"}}
                              {...register("name", {
                                required: true,
                                pattern: validCharactersPattern(),
                                maxLength: 40,
                                validate: () => {
                                  return employees.filter(function (e) {
                                    let name = e.name ? e.name.toLowerCase() : "";
                                    let newName = watch("name") ? watch("name").toLowerCase() : "";
                                    return e.id !== employee.id && name.trim() === newName.trim();
                                  }).length === 0
                                }
                              })}
                              data-for="employee-name"
                              data-tip="Her skrives navnet på den ansatte (unngå bruk av for- og etternavn pga personvern)"
                          />
                          <Tooltip id="employee-name" />
                          {ErrorMessage(errors.name, 'required', "Et navn er påkrevd, skriv inn et navn på den ansatte")}
                          {ErrorMessage(errors.name, 'validate', "Det finnes allerede en ansatt med det navnet")}
                          {ErrorMessage(errors.name, 'pattern', "Du har brukt noen ugyldige tegn")}
                          {ErrorMessage(errors.name, 'maxLength', "Navn på ansatte kan ikke være lenger enn 40 tegn")}
                        </InputWithValidation>
                      </InputDivider>
                      <InputDivider>
                        <label htmlFor="position">Stilling</label>
                        <Select
                            style={{
                              width: "inherit",
                              backgroundColor: editable() ? "white": "WhiteSmoke",
                              pointerEvents: editable() ? "auto": "none"
                            }}
                            id="position"
                            defaultValue={employee.position ? employee.position : ""}
                            {...register("position")}
                            data-for="employee-position"
                            data-tip="Velg hvilken stilling den ansatte har i avdelingen (rullegardinsmeny)"
                        >
                          {positionOptions(positions)}
                        </Select>

                        <Tooltip id="employee-position" />
                      </InputDivider>
                      <InputDivider>
                        <label htmlFor={"vacancyRate"}>Stillingsprosent</label>
                        <InputWithValidation>
                          <EmployeeNumberInput
                              id="vacancyRate"
                              defaultValue={employee.vacancyRate}
                              step="any"
                              readOnly={!editable()}
                              placeholder="Stillingsprosent"
                              style={{
                                width: "162px",
                                backgroundColor: editable() ? "white": "WhiteSmoke",
                                boxShadow: getInputErrorBoxShadow('vacancy_rate')
                              }}
                              {...register("vacancyRate", {
                                    pattern: maxNrOfDecimalRegex(2),
                                    onChange: (e) => adjustValuesFollowingVacancyRateChange(e.target.value)
                                  }
                              )}
                              data-for="employee-vacancy"
                              data-tip="Her skrives stillingsprosenten som den ansatte er ansatt i"
                          />

                          <Tooltip id="employee-vacancy" />
                        </InputWithValidation>
                      </InputDivider>
                      {ErrorMessage(errors.vacancyRate, 'pattern', 'Maks to desimaler tillatt')}
                      {!isNew && orgEmployeeToggleEnabled &&
                          <div>
                            <h3 style={{color: "#398089"}}>
                              Arbeid i andre avdelinger
                            </h3>
                            {authorizedToSeeOrgEmployee && orgEmployee &&
                                <ul>
                                  {orgEmployee.employees.map(e =>
                                      <li
                                          style={{fontFamily: "Montserrat Alternates", fontSize: "16px"}}
                                          key={["empl","pers",e.name].join("-")}
                                      >
                                        {e.name}, {e.positionName}, {e.vacancyRate}%, {e.departmentName}
                                      </li>
                                  )}
                                </ul>
                            }
                            {!authorizedToSeeOrgEmployee &&
                                <div style={{fontFamily: "Montserrat Alternates"}}>Du har ikke rettigheter til å se disse opplysningene</div>
                            }
                            {authorizedToSeeOrgEmployee && !orgEmployee &&
                                <div style={{fontFamily: "Montserrat Alternates"}}>Denne ansatte jobber ikke i andre avdelinger</div>
                            }
                            {authorizedToSeeOrgEmployee &&
                                <NavLink className="nav-link" to={`/avdelinger/${departmentId}/ansatte/${employee.id}/knytt`}>
                                  <a className="arrow-link">Se eller endre<span className="arrow"></span></a>
                                </NavLink>
                            }
                          </div>
                      }
                    </InputCard>

                    <InputCard>
                      <h3>Spesialkompetanse</h3>

                      <label>Spesialkompetanse</label>

                      <Tooltip id="employee-traits" />
                      <div data-for="employee-traits" data-tip="Tildel spesialkompetanse til den ansatte fra nedtrekks gardinen. Dette betyr at den ansatte kan prioriteres til vakter der denne spesialkompetansen er et krav">
                        <Controller
                            name="traits"
                            control={control}
                            defaultValue={Object.entries(employee.traitNames)?.map(t => ({ label: t[1], value: t[0] }))}
                            {...register("traits", {onChange: () => validate(getValues())})}
                            render={({ field }) =>
                                <MultiSelectPicker
                                    inputRef={field.ref}
                                    disabled={!editable()}
                                    defaultValue={
                                      Object
                                          .entries(employee.traitNames)
                                          .sort(function (a, b) {
                                            return a[1].localeCompare(b[1], 'no', { sensitivity: 'base' });
                                          })
                                          ?.map(t => ({ label: t[1], value: t[0] }))
                                    }
                                    defaultOptions={competences?.map(p => ({ label: p.name, value: p.id }))}
                                    onChange={(e) => field.onChange(e?.map(val => val.value))}
                                    placeholderPostfix="spesialkompetanser"
                                    {...field}
                                />
                            } />
                      </div>
                      <label>Vaktkoder</label>

                      <Tooltip id="employee-shiftTypes" />
                      <div style={{boxShadow: getInputErrorBoxShadow('shift_types')}}
                           data-for="employee-shiftTypes"
                           data-tip="Tildel vaktkode til den ansatte fra nedtrekks gardinen. Dette betyr at den ansatte kan gå denne vakten."
                      >
                        <Controller
                            name="selectedShiftTypes"
                            control={control}
                            defaultValue={selectedShiftTypes}
                            {...register("selectedShiftTypes", {
                              onChange: (e) => {
                                adjustValuesFollowingShiftCodesChange(e.target.value);
                                for(const sel of selectedShiftTypes) {
                                  if(!e.target.value.map(opt => opt.value).includes(sel.value)) {
                                    unregister(`shiftTypes.${sel.label}.maxPerWeek`)
                                  }
                                }
                                setSelectedDaySegments(
                                    shiftTypes
                                        .filter(sh => e.target.value.map(opt => opt.value).includes(sh.id))
                                        .map(sh => sh.daySegment)
                                );
                                setSelectedShiftTypes(e.target.value)
                              }
                            })}
                            render={({ field }) =>
                                <MultiSelectPicker
                                    inputRef={field.ref}
                                    disabled={!editable()}
                                    defaultValue={selectedShiftTypes}
                                    defaultOptions={
                                      sortShiftTypesByDaySegment(shiftTypes)
                                          ?.map(sh => ({ label: sh.code, value: sh.id }))
                                    }
                                    onChange={(e) => field.onChange(e?.map(val => val.value))}
                                    placeholderPostfix="vaktkoder"
                                    {...field}
                                />
                            } />
                      </div>
                    </InputCard>
                  </CardRow>

                  <CardRow>
                    <InputCard>
                      <h3>Vakter</h3>

                      <InputDivider>
                        <label htmlFor={"shiftMaxSequence"}>Maks. antall vakter på rad</label>
                        <Select
                            id="shiftMaxSequence"
                            style={{
                              backgroundColor: editable() ? "white": "WhiteSmoke",
                              pointerEvents: editable() ? "auto": "none",
                              boxShadow: getInputErrorBoxShadow('shift_max_sequence')
                            }}
                            defaultValue={employee.shiftMaxSequence}
                            {...register("shiftMaxSequence", {
                              onChange: (e) => {
                                if(parseInt(e.target.value) < parseInt(watch('weekendShiftMaxSequence'))) {
                                  setValue(
                                      'weekendShiftMaxSequence',
                                      e.target.value >= 3
                                          ? e.target.value
                                          : "-1"
                                  )
                                }
                              }
                            })}
                            data-for="employee-shiftMaxSequence"
                            data-tip="Her fyller du inn maks antall vakter på rad den ansatte kan ha, uavhengig av vakt type (rullegardinsmeny)"
                        >
                          {maxConsecutiveShiftsOptions}
                        </Select>

                        <Tooltip id="employee-shiftMaxSequence" />
                      </InputDivider>

                      <InputDivider>
                        <label htmlFor={"weekendShiftMaxSequence"}>Maks. antall vakter på rad rundt helg</label>
                        <Select
                            defaultValue={employee.weekendShiftMaxSequence}
                            style={{
                              backgroundColor: editable() ? "white": "WhiteSmoke",
                              pointerEvents: editable() ? "auto": "none",
                              boxShadow: getInputErrorBoxShadow('weekend_shift_max_sequence')
                            }}
                            id="weekendShiftMaxSequence"
                            {...register("weekendShiftMaxSequence", {
                              onChange: (e) => {
                                const shiftMaxSeq = parseInt(watch('shiftMaxSequence'));
                                if(parseInt(e.target.value) > shiftMaxSeq) {
                                  setValue(
                                      'weekendShiftMaxSequence',
                                      shiftMaxSeq >= 3
                                          ? shiftMaxSeq
                                          : -1
                                  );
                                }
                              }
                            })}
                            data-for="employee-weekendShiftMaxSequence"
                            data-tip="Her fyller du inn maks antall vakter på rad rundt helg den ansatte kan ha, uavhengig av vakt type (rullegardinsmeny)"
                        >
                          <option selected="selected" value="-1">-</option>
                          {maxConsecutiveWeekendShiftsOptions}
                        </Select>

                        <Tooltip id="employee-weekendShiftMaxSequence" />
                      </InputDivider>

                      <hr/>

                      {selectedDaySegments.includes('D') &&
                          <>
                            <InputDivider>
                              <label htmlFor={"dayShiftMinSequence"}>Min. dagvakter på rad</label>
                              <Select
                                  id="dayShiftMinSequence"
                                  style={{
                                    backgroundColor: editable() ? "white": "WhiteSmoke",
                                    pointerEvents: editable() ? "auto": "none",
                                    boxShadow: getInputErrorBoxShadow('day_shift_min_sequence')
                                  }}
                                  defaultValue={employee.dayShiftMinSequence}
                                  {...register("dayShiftMinSequence")}
                                  data-for="employee-dayShiftMinSequence"
                                  data-tip="Her fyller du inn minimum antall dagvakter på rad den ansatte skal ha (rullegardinsmeny)."
                              >
                                <option value="0">0</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </Select>

                              <Tooltip id="employee-dayShiftMinSequence" />
                            </InputDivider>

                            <InputDivider>
                              <label htmlFor={"dayShiftMaxSequence"}>Maks. dagvakter på rad</label>
                              <Select
                                  id="dayShiftMaxSequence"
                                  style={{
                                    backgroundColor: editable() ? "white": "WhiteSmoke",
                                    pointerEvents: editable() ? "auto": "none",
                                    boxShadow: getInputErrorBoxShadow('day_shift_max_sequence')
                                  }}
                                  defaultValue={employee.dayShiftMaxSequence}
                                  {...register("dayShiftMaxSequence")}
                                  data-for="employee-dayShiftMaxSequence"
                                  data-tip="Her fyller du inn maks antall dagvakter på rad den ansatte kan ha (rullegardinsmeny)."
                              >
                                {daySegmentMaxSeqOptions}
                              </Select>

                              <Tooltip id="employee-dayShiftMaxSequence" />
                            </InputDivider>
                            {ErrorMessage(errors.dayShiftMaxSequence, 'validate',
                                'Minimum dagvakter på rad kan ikke være høyere enn maks. dagvakter på rad')}
                            <hr/>
                          </>}

                      {selectedDaySegments.includes("A") &&
                          <>
                            <InputDivider>
                              <label htmlFor={"eveningShiftMinSequence"}>Min. kveldsvakter på rad</label>
                              <Select
                                  id="eveningShiftMinSequence"
                                  style={{
                                    backgroundColor: editable() ? "white" : "WhiteSmoke",
                                    pointerEvents: editable() ? "auto" : "none",
                                    boxShadow: getInputErrorBoxShadow('evening_shift_min_sequence')
                                  }}
                                  defaultValue={employee.eveningShiftMinSequence}
                                  {...register("eveningShiftMinSequence")}
                                  data-for="employee-eveningShiftMinSequence"
                                  data-tip="Her fyller du inn minimum antall kveldsvakter på rad den ansatte skal ha (rullegardinsmeny)."
                              >
                                <option value="0">0</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </Select>

                              <Tooltip id="employee-eveningShiftMinSequence"/>
                            </InputDivider>

                            <InputDivider>
                              <label htmlFor={"eveningShiftMaxSequence"}>Maks. kveldsvakter på rad</label>
                              <Select
                                  id="eveningShiftMaxSequence"
                                  style={{
                                    backgroundColor: editable() ? "white" : "WhiteSmoke",
                                    pointerEvents: editable() ? "auto" : "none",
                                    boxShadow: getInputErrorBoxShadow('evening_shift_max_sequence')
                                  }}
                                  defaultValue={employee.eveningShiftMaxSequence}
                                  {...register("eveningShiftMaxSequence")}
                                  data-for="employee-eveningShiftMaxSequence"
                                  data-tip="Her fyller du inn maks antall kveldsvakter på rad den ansatte kan ha (rullegardinsmeny)."
                              >
                                {daySegmentMaxSeqOptions}
                              </Select>

                              <Tooltip id="employee-eveningShiftMaxSequence"/>
                            </InputDivider>
                            {ErrorMessage(errors.eveningShiftMaxSequence, 'validate',
                                'Minimum kveldsvakter på rad kan ikke være høyere enn maks kveldsvakter på rad')}
                            <hr/>
                          </>
                      }

                      {selectedDaySegments.includes("N") &&
                          <>
                            <InputDivider>
                              <label htmlFor={"nightShiftMinSequence"}>Min. nattevakter på rad</label>
                              <Select
                                  id="nightShiftMinSequence"
                                  style={{
                                    backgroundColor: editable() ? "white" : "WhiteSmoke",
                                    pointerEvents: editable() ? "auto" : "none",
                                    boxShadow: getInputErrorBoxShadow('night_shift_min_sequence')
                                  }}
                                  defaultValue={employee.nightShiftMinSequence}
                                  {...register("nightShiftMinSequence")}
                                  data-for="employee-nightShiftMinSequence"
                                  data-tip="Her fyller du inn minimum antall nattevakter på rad den ansatte kan ha etter hverandre (rullegardinsmeny)."
                              >
                                <option value="0">0</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </Select>

                              <Tooltip id="employee-nightShiftMinSequence"/>
                            </InputDivider>

                            <InputDivider>
                              <label htmlFor={"nightShiftMaxSequence"}>Maks. nattevakter på rad</label>
                              <Select
                                  id="nightShiftMaxSequence"
                                  style={{
                                    backgroundColor: editable() ? "white" : "WhiteSmoke",
                                    pointerEvents: editable() ? "auto" : "none",
                                    boxShadow: getInputErrorBoxShadow('night_shift_max_sequence')
                                  }}
                                  defaultValue={employee.nightShiftMaxSequence}
                                  {...register("nightShiftMaxSequence")}
                                  data-for="employee-nightShiftMaxSequence"
                                  data-tip="Her fyller du inn maks antall nattevakter på rad den ansatte kan ha etter hverandre (rullegardinsmeny)."
                              >
                                {daySegmentMaxSeqOptions}
                              </Select>

                              <Tooltip id="employee-nightShiftMaxSequence"/>
                            </InputDivider>

                            {ErrorMessage(errors.nightShiftMaxSequence, 'validate',
                                'Minimum nattevakter på rad kan ikke være høyere en maks nattevakter på rad')}
                            <hr/>
                          </>
                      }

                      {selectedDaySegments.includes("L") &&
                          <>
                            <InputDivider>
                              <label htmlFor={"longShiftMinSequence"}>Min. langvakter/mellomvakter på rad</label>
                              <Select
                                  id="longShiftMinSequence"
                                  style={{
                                    backgroundColor: editable() ? "white" : "WhiteSmoke",
                                    pointerEvents: editable() ? "auto" : "none",
                                    boxShadow: getInputErrorBoxShadow('long_shift_min_sequence')
                                  }}
                                  defaultValue={employee.longShiftMinSequence}
                                  {...register("longShiftMinSequence")}
                                  data-for="employee-longShiftMinSequence"
                                  data-tip="Her fyller du inn minimum antall langvakter/mellomvakter på rad den ansatte kan ha etter hverandre (rullegardinsmeny). "
                              >
                                <option value="0">0</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </Select>

                              <Tooltip id="employee-longShiftMinSequence"/>
                            </InputDivider>

                            <InputDivider>
                              <label htmlFor={"longShiftMaxSequence"}>Maks. langvakter/mellomvakter på rad</label>
                              <Select
                                  id="longShiftMaxSequence"
                                  style={{
                                    backgroundColor: editable() ? "white" : "WhiteSmoke",
                                    pointerEvents: editable() ? "auto" : "none",
                                    boxShadow: getInputErrorBoxShadow('long_shift_max_sequence')
                                  }}
                                  name="longShiftMaxSequence"
                                  defaultValue={employee.longShiftMaxSequence}
                                  {...register("longShiftMaxSequence")}
                                  data-for="employee-longShiftMaxSequence"
                                  data-tip="Fyll inn maks antall langvakter/mellomvakter på rad den ansatte kan ha (rullegardinsmeny)."
                              >
                                {daySegmentMaxSeqOptions}
                              </Select>

                              <Tooltip id="employee-longShiftMaxSequence"/>
                            </InputDivider>
                            {ErrorMessage(
                                errors.longShiftMaxSequence,
                                'validate',
                                'Ansatte som skal arbeide langvakter må ha ' +
                                  '"Maks langvakter/mellomvakter på rad" til en verdi høyere enn ' +
                                  '"Min. langvakter/mellomvakter på rad og "0"'
                            )}

                          </>
                      }

                      <h3
                          style={{cursor: "pointer"}}
                          onClick={() => setAdvShiftsSettingsOpen(!advShiftsSettingsOpen)}
                      >{advShiftsSettingsOpen ? "Skjul" : "Vis"} avanserte innstillinger
                      </h3>
                      <Collapsible open={advShiftsSettingsOpen}>
                        <div style={{rowGap: "2em", display: "flex", flexDirection: "column"}}>
                          <InputDivider>
                            <label htmlFor={"shiftMinSequence"}>Min. antall vakter på rad</label>
                            <Select
                                id="shiftMinSequence"
                                style={{
                                  backgroundColor: editable() ? "white": "WhiteSmoke",
                                  pointerEvents: editable() ? "auto": "none",
                                  boxShadow: getInputErrorBoxShadow('min_shift_sequence')
                                }}
                                defaultValue={employee.shiftMinSequence}
                                {...register("shiftMinSequence")}
                                data-for="employee-shiftMinSequence"
                                data-tip="Her fyller du inn min antall vakter på rad den ansatte må ha, uavhengig av vakt type (rullegardinsmeny)"
                            >
                              <option value="0">0</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </Select>

                            <Tooltip id="employee-shiftMinSequence" />
                          </InputDivider>
                          <InputDivider>
                            <label htmlFor="maxShifts">Maks. antall vakter i turnus</label>
                            <InputWithValidation>
                              <MaxShiftInput
                                  readOnly={!editable()}
                                  style={{
                                    backgroundColor: editable() ? "white": "Whitesmoke",
                                    boxShadow: getInputErrorBoxShadow('max_shifts')
                                  }}
                                  id="maxShifts"
                                  defaultValue={employee.maxShifts}
                                  step="any"
                                  {...register("maxShifts", { required: false })}
                                  data-for="employee-maxShifts"
                                  data-tip="Her fyller du inn maks antall vakter den ansatte skal kunne ha i HELE turnusperioden"
                                  onBlur={e => handleFreeNrInputs("maxShifts", e.target.value)}
                              />

                              <Tooltip id="employee-maxShifts" />
                            </InputWithValidation>
                          </InputDivider>
                          <hr/>
                          {selectedDaySegments.includes("D") &&
                            <>
                              <InputDivider>
                                <label htmlFor="dayShiftsMaxPerWeek">Maks. dagvakter per uke</label>
                                <InputWithValidation>
                                  <MaxShiftInput
                                      readOnly={!editable()}
                                      style={{
                                        backgroundColor: editable() ? "white": "Whitesmoke",
                                        boxShadow: getInputErrorBoxShadow('day_shifts_max_per_week')
                                      }}
                                      id="dayShiftsMaxPerWeek"
                                      defaultValue={employee.dayShiftsMaxPerWeek}
                                      step="any"
                                      {...register("dayShiftsMaxPerWeek", { required: false })}
                                      data-for="employee-dayShiftsMaxPerWeek"
                                      data-tip="Her fyller du inn maks antall dagvakter den ansatte skal kunne gå per uke"
                                      onBlur={e => handleFreeNrInputs("dayShiftsMaxPerWeek", e.target.value)}
                                  />
                                  <Tooltip id="employee-dayShiftsMaxPerWeek" />
                                </InputWithValidation>
                              </InputDivider>
                              <InputDivider>
                                <label htmlFor="maxDayShifts">Maks. dagvakter i turnus</label>
                                <InputWithValidation>
                                  <MaxShiftInput
                                      readOnly={!editable()}
                                      style={{
                                        backgroundColor: editable() ? "white": "Whitesmoke",
                                        boxShadow: getInputErrorBoxShadow('max_day_shifts')
                                      }}
                                      id="maxDayShifts"
                                      defaultValue={employee.maxDayShifts}
                                      step="any"
                                      {...register("maxDayShifts", { required: false })}
                                      data-for="employee-maxDayShifts"
                                      data-tip="Her fyller du inn maks antall dagvakter den ansatte skal kunne gå i HELE turnusperioden"
                                      onBlur={e => handleFreeNrInputs("maxDayShifts", e.target.value)}
                                  />
                                  <Tooltip id="employee-maxDayShifts" />
                                </InputWithValidation>
                              </InputDivider>
                              <hr/>
                            </>
                          }

                          {selectedDaySegments.includes("A") &&
                            <>
                              <InputDivider>
                                <label htmlFor="eveningShiftsMaxPerWeek">Maks. kveldsvakter per uke</label>
                                <InputWithValidation>
                                  <MaxShiftInput
                                      readOnly={!editable()}
                                      style={{
                                        backgroundColor: editable() ? "white": "Whitesmoke",
                                        boxShadow: getInputErrorBoxShadow('evening_shifts_max_per_week')
                                      }}
                                      id="eveningShiftsMaxPerWeek"
                                      defaultValue={employee.eveningShiftsMaxPerWeek}
                                      step="any"
                                      {...register("eveningShiftsMaxPerWeek")}
                                      data-for="employee-eveningShiftsMaxPerWeek"
                                      data-tip="Her fyller du inn maks antall kveldsvakter den ansatte skal kunne gå per uke"
                                      onBlur={e => handleFreeNrInputs("eveningShiftsMaxPerWeek", e.target.value)}
                                  />
                                  <Tooltip id="employee-eveningShiftsMaxPerWeek" />
                                </InputWithValidation>
                              </InputDivider>
                              <InputDivider>
                                <label htmlFor="maxEveningShifts">Maks. kveldsvakter i turnus</label>
                                <InputWithValidation>
                                  <MaxShiftInput
                                      readOnly={!editable()}
                                      style={{
                                        backgroundColor: editable() ? "white": "Whitesmoke",
                                        boxShadow: getInputErrorBoxShadow('max_evening_shifts')
                                      }}
                                      id="maxEveningShifts"
                                      defaultValue={employee.maxEveningShifts}
                                      step="any"
                                      {...register("maxEveningShifts")}
                                      data-for="employee-maxEveningShifts"
                                      data-tip="Her fyller du inn maks antall kveldsvakter den ansatte skal kunne gå i HELE turnusperioden"
                                      onBlur={e => handleFreeNrInputs("maxEveningShifts", e.target.value)}
                                  />
                                  <Tooltip id="employee-maxEveningShifts" />
                                </InputWithValidation>
                              </InputDivider>
                              <hr/>
                            </>
                          }
                          {selectedDaySegments.includes("N") &&
                            <>
                              <InputDivider>
                                <label htmlFor="nightShiftsMaxPerWeek">Maks. nattevakter per uke</label>
                                <InputWithValidation>
                                  <MaxShiftInput
                                      readOnly={!editable()}
                                      style={{
                                        backgroundColor: editable() ? "white": "Whitesmoke",
                                        boxShadow: getInputErrorBoxShadow('night_shifts_max_per_week')
                                      }}
                                      id="nightShiftsMaxPerWeek"
                                      defaultValue={employee.nightShiftsMaxPerWeek}
                                      step="any"
                                      {...register("nightShiftsMaxPerWeek")}
                                      data-for="employee-nightShiftsMaxPerWeek"
                                      data-tip="Her fyller du inn maks antall nattevakter den ansatte skal kunne per uke"
                                      onBlur={e => handleFreeNrInputs("nightShiftsMaxPerWeek", e.target.value)}
                                  />
                                  <Tooltip id="employee-nightShiftsMaxPerWeek" />
                                </InputWithValidation>
                              </InputDivider>
                              <InputDivider>
                                <label htmlFor="maxNightShifts">Maks. nattevakter i turnus</label>
                                <InputWithValidation>
                                  <MaxShiftInput
                                      readOnly={!editable()}
                                      style={{
                                        backgroundColor: editable() ? "white": "Whitesmoke",
                                        boxShadow: getInputErrorBoxShadow('max_night_shifts')
                                      }}
                                      id="maxNightShifts"
                                      defaultValue={employee.maxNightShifts}
                                      step="any"
                                      {...register("maxNightShifts")}
                                      data-for="employee-maxNightShifts"
                                      data-tip="Her fyller du inn maks antall nattevakter den ansatte skal kunne gå i HELE turnusperioden"
                                      onBlur={e => handleFreeNrInputs("maxNightShifts", e.target.value)}
                                  />
                                  <Tooltip id="employee-maxNightShifts" />
                                </InputWithValidation>
                              </InputDivider>
                              <InputDivider>
                                <label htmlFor="maxNightShiftWeekends">Maks. nattevakthelger</label>
                                <InputWithValidation>
                                  <MaxShiftInput
                                      readOnly={!editable()}
                                      style={{
                                        backgroundColor: editable() ? "white": "Whitesmoke",
                                        boxShadow: getInputErrorBoxShadow('max_night_shift_weekends')
                                      }}
                                      id="maxNightShiftWeekends"
                                      defaultValue={employee.maxNightShiftWeekends}
                                      step="any"
                                      {...register("maxNightShiftWeekends")}
                                      data-for="employee-maxNightShiftWeekends"
                                      data-tip="Her fyller du inn maks antall nattevakthelger den ansatte skal kunne jobbe"
                                      onBlur={e => handleFreeNrInputs("maxNightShiftWeekends", e.target.value)}
                                  />
                                  <Tooltip id="employee-maxNightShiftWeekends" />
                                </InputWithValidation>
                              </InputDivider>
                              <hr/>
                            </>
                          }

                          {selectedDaySegments.includes("L") &&
                            <>
                              <InputDivider>
                                <label htmlFor="longShiftsMaxPerWeek">Maks. langvakter/mellomvakter per uke</label>
                                <InputWithValidation>
                                  <MaxShiftInput
                                      readOnly={!editable()}
                                      style={{
                                        backgroundColor: editable() ? "white": "Whitesmoke",
                                        boxShadow: getInputErrorBoxShadow('long_shifts_max_per_week')
                                      }}
                                      id="longShiftsMaxPerWeek"
                                      defaultValue={employee.longShiftsMaxPerWeek}
                                      step="any"
                                      {...register("longShiftsMaxPerWeek")}
                                      data-for="employee-longShiftsMaxPerWeek"
                                      data-tip="Her fyller du inn maks antall langvakter/mellomvakter den ansatte skal kunne gå per uke"
                                      onBlur={e => handleFreeNrInputs("longShiftsMaxPerWeek", e.target.value)}
                                  />
                                  <Tooltip id="employee-longShiftsMaxPerWeek" />
                                </InputWithValidation>
                              </InputDivider>
                              <InputDivider>
                                <label htmlFor="maxLongShifts">Maks. langvakter/mellomvakter i turnus</label>
                                <InputWithValidation>
                                  <MaxShiftInput
                                      readOnly={!editable()}
                                      style={{
                                        backgroundColor: editable() ? "white": "Whitesmoke",
                                        boxShadow: getInputErrorBoxShadow('max_long_shifts')
                                      }}
                                      id="maxLongShifts"
                                      defaultValue={employee.maxLongShifts}
                                      step="any"
                                      {...register("maxLongShifts")}
                                      data-for="employee-maxLongShifts"
                                      data-tip="Her fyller du inn maks antall langvakter/mellomvakter den ansatte skal kunne gå i HELE turnusperioden"
                                      onBlur={e => handleFreeNrInputs("maxLongShifts", e.target.value)}
                                  />
                                  <Tooltip id="employee-maxLongShifts" />
                                </InputWithValidation>
                              </InputDivider>
                            </>
                          }

                          {selectedShiftTypes.length > 0 &&
                            <>
                              <p>
                                <label>Maks. per uke per vaktkode</label>
                              </p>
                              <Flex
                                  style={{maxWidth: "520px", marginTop: "-40px"}}
                                  flexWrap={"wrap"}>
                                {selectedShiftTypes?.map(sel => {
                                  return (
                                      <div
                                          style={{marginRight: "7px"}}
                                          key={`shiftTypes.${sel.label}.maxPerWeek`}
                                      >
                                        <p>
                                          <label htmlFor={`shiftTypes.${sel.label}.maxPerWeek`}>{sel.label}</label>
                                        </p>
                                        <MaxShiftInput
                                            readOnly={!editable()}
                                            style={{
                                              backgroundColor: editable() ? "white": "Whitesmoke",
                                              boxShadow: getInputErrorBoxShadow(`shift_types/${sel.label}/max_per_week`)
                                            }}
                                            id={`shiftTypes.${sel.label}.maxPerWeek`}
                                            defaultValue={valOrDefault(employee.shiftTypes?.[sel.label]?.maxPerWeek)}
                                            step="any"
                                            {...register(`shiftTypes.${sel.label}.maxPerWeek`)}
                                            data-for={`shiftTypes.${sel.label}.maxPerWeek`}
                                            data-tip="Her fyller du inn maks antall vakter per uke for den gitte vaktkoden"
                                            onBlur={(e) => handleFreeNrInputs(`shiftTypes.${sel.label}.maxPerWeek`, e.target.value)}
                                        />
                                        <Tooltip id={`shiftTypes.${sel.label}.maxPerWeek`}></Tooltip>
                                      </div>
                                  )
                                })}
                              </Flex>
                            </>
                          }
                        </div>
                      </Collapsible>
                    </InputCard>

                    <InputCard>
                      <h3>Tilgjengelig</h3>
                      <InputDivider>
                        <label htmlFor="enabled">Den ansatte er tilgjengelig for vakter</label>

                        <Tooltip id="employee-enabled" />
                        <div
                            style={{boxShadow: getInputErrorBoxShadow('enabled') }}
                            data-for="employee-enabled"
                            data-tip="Dersom den ansatte skal tas med i generering av turnus må du markere at den ansatte er tilgjengelig for vakter">
                          <Controller
                              name="enabled"
                              control={control}
                              defaultValue={employee.enabled}
                              render={({ field }) =>
                                  <CombinedCheckbox
                                      disabled={!editMode}
                                      defaultChecked={employee.enabled}
                                      {...field}
                                  />
                              }
                          />
                        </div>
                      </InputDivider>
                      <InputDivider>
                        <label htmlFor="worksOnlyWeekend">Den ansatte jobber kun helg</label>

                        <Tooltip id="employee-worksOnlyWeekend" />
                        <div
                            data-for="employee-worksOnlyWeekend"
                            data-tip="Dersom den ansatte kun skal arbeide helgevakter i turnus hakes det av her">
                          <Controller
                              name="worksOnlyWeekend"
                              {...register("worksOnlyWeekend", {
                                onChange: () => validate(getValues())
                              })}
                              control={control}
                              defaultValue={employee.worksOnlyWeekend === true}
                              render={({ field }) =>
                                  <CombinedCheckbox
                                      style={{boxShadow: getInputErrorBoxShadow('works_only_weekend')}}
                                      disabled={!editable()}
                                      defaultChecked={employee.worksOnlyWeekend === true} {...field}
                                  />
                              }
                          />
                        </div>
                      </InputDivider>

                      <InputDivider>
                        <label htmlFor={"sundaysMaxSequence"}>Maks. antall søndager på rad</label>
                        <Select
                            id="sundaysMaxSequence"
                            style={{
                              backgroundColor: editable() ? "white": "WhiteSmoke",
                              pointerEvents: editable() ? "auto": "none",
                              boxShadow: getInputErrorBoxShadow('sundays_max_sequence')
                            }}
                            defaultValue={employee.sundaysMaxSequence}
                            {...register("sundaysMaxSequence")}
                            data-for="employee-sundaysMaxSequence"
                            data-tip="Velg maks. antall søndager på rad den ansatte kan jobbe"
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Select>
                        <Tooltip id="employee-sundaysMaxSequence" />
                      </InputDivider>

                      {eligibleForQuickReturns() &&
                          <>
                            <InputDivider>
                              <label htmlFor={"nrOfQuickReturnsPerWeekOnWeekend"}>Maks. antall sein-tidlig vakter i helgen</label>
                              <Select
                                  id="nrOfQuickReturnsPerWeekOnWeekend"
                                  style={{
                                    backgroundColor: editable() ? "white": "WhiteSmoke",
                                    pointerEvents: editable() ? "auto": "none",
                                    boxShadow: getInputErrorBoxShadow('nr_of_quick_returns_per_week_on_weekend')
                                  }}
                                  defaultValue={employee.nrOfQuickReturnsPerWeekOnWeekend}
                                  {...register("nrOfQuickReturnsPerWeekOnWeekend")}
                                  data-for="employee-nrOfQuickReturnsPerWeekOnWeekend"
                                  data-tip="Her kan du markere hvor mange sein-tidlig vakter den ansatte kan ta i en arbeidshelg helg (helg = seinvakt fredag til søndag)"
                              >
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                              </Select>

                              <Tooltip id="employee-nrOfQuickReturnsPerWeekOnWeekend" />
                            </InputDivider>

                            <InputDivider>
                              <label htmlFor={"nrOfQuickReturnsPerWeekOnWeekdays"}>Maks. antall sein-tidlig vakter på ukedager</label>
                              <Select
                                  id="nrOfQuickReturnsPerWeekOnWeekdays"
                                  style={{
                                    backgroundColor: editable() ? "white": "WhiteSmoke",
                                    pointerEvents: editable() ? "auto": "none",
                                    boxShadow: getInputErrorBoxShadow('nr_of_quick_returns_per_week_on_weekdays')
                                  }}
                                  defaultValue={employee.nrOfQuickReturnsPerWeekOnWeekdays}
                                  {...register("nrOfQuickReturnsPerWeekOnWeekdays")}
                                  data-for="employee-nrOfQuickReturnsPerWeekOnWeekdays"
                                  data-tip="Her kan du markere hvor mange sein-tidlig vakter den ansatte kan ta på ukedager"
                              >
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                              </Select>

                              <Tooltip id="employee-nrOfQuickReturnsPerWeekOnWeekdays" />
                            </InputDivider>
                          </>
                      }
                      <InputDivider>
                        <label htmlFor={"redDaysMaxSequence"}>Maks. antall røde dager på rad</label>
                        <Select
                            id="redDaysMaxSequence"
                            style={{
                              backgroundColor: editable() ? "white": "WhiteSmoke",
                              pointerEvents: editable() ? "auto": "none",
                              boxShadow: getInputErrorBoxShadow('red_days_max_sequence')
                            }}
                            defaultValue={employee.redDaysMaxSequence}
                            {...register("redDaysMaxSequence")}
                            data-for="employee-redDaysMaxSequence"
                            data-tip="Her kan du markere hvor mange røde dager den ansatte kan ha på rad"
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Select>

                        <Tooltip id="employee-redDaysMaxSequence" />
                      </InputDivider>
                      <InputDivider>
                        <label htmlFor={"movableHolidaysMaxPerTurnus"}>Maks. antall bevegelige høytidsdager</label>
                        <Select
                            style={{
                              backgroundColor: editable() ? "white": "WhiteSmoke",
                              pointerEvents: editable() ? "auto": "none",
                              boxShadow: getInputErrorBoxShadow('movable_holidays_max_per_turnus')
                            }}
                            id="movableHolidaysMaxPerTurnus"
                            defaultValue={employee.movableHolidaysMaxPerTurnus}
                            {...register("movableHolidaysMaxPerTurnus")}
                            data-for="employee-movableHolidaysMaxPerTurnus"
                            data-tip="Her kan du sette maks antall bevegelige høytidsdager den ansatte ønsker å arbeide i turnusperioden"
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Select>

                        <Tooltip id="employee-movableHolidaysMaxPerTurnus" />
                      </InputDivider>
                      <h3 style={{ margin: "0px" }}>Kommentar</h3>
                      <span
                          style={{
                            color: "rgb(191, 61, 43)",
                            margin: "-20px 0px -20px 0px"
                      }}>
                        Ikke skriv sensitive personopplysninger her
                      </span>
                      <Tooltip id="employee-comment" />
                      <TextArea
                          name="comment"
                          placeholder="Kommentar"
                          disabled={!editable()}
                          style={{backgroundColor: editable() ? "white": "WhiteSmoke"}}
                          defaultValue={employee.comment}
                          {...register("comment", {
                            pattern: validCharactersPattern()
                          })}
                          rows={4}
                          data-for="employee-comment"
                          data-tip="Dersom du har egne kommentarer på ansattkortet kan du notere dette her. Eksempel - Jobbet 24-26 desember 2022, eller ferieuke 25,26 og 27 2022"
                      />
                      {ErrorMessage(errors.comment, 'pattern', "Du har brukt noen ugyldige tegn")}

                    </InputCard>
                  </CardRow>
                </div>
                {showModal && (
                    <Modal
                        title="Ingen stillinger opprettet"
                        cancel={() => {

                        }}
                        cancelButtonProps={{
                          style: { display: "none" }
                        }}
                        done={() => {
                          setSubmitted(true);
                          setTimeout(function () {
                            history.push(`/avdelinger/${departmentId}/avdelingsoppsett`);
                          }, 200);
                        }}
                        secondaryButtonProps={{
                          children: 'Nei, gå tilbake',
                          onClick: () => {
                            setSubmitted(true);
                            history.goBack();
                          }
                        }}
                        action="Ja, gå til Avdelingsoppsett"
                    >
                      <p>Denne avdelingen har ingen stillinger opprettet, vil du opprette disse nå?</p>
                    </Modal>
                )}
              </div>
          }
        </form >
        {!isLoading && configErrors.length > 0 && dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError)}
        {!isLoading && configWarnings.length > 0 && dynomiteErrorPanel(dynomite, configWarnings, dynomiteInternalError, 0, () => {}, true)}
      </>
  )
}

export default EmployeePage;

const Select = styled.select`
  width: 75px;
`;

const MaxShiftInput = styled(EmployeeNumberInput)`
  width: 70px;
  border: 1px solid #777;
  border-radius: 0px;
  text-align: center;
  padding: 0px;
`;