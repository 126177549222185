import React, {useContext, useState, useMemo} from "react";
import dynomiteContext from "./dynomite";
import Accordion from "./accordion";
import { Box, Flex, Txt, Button, ButtonGroup } from "rendition";
import { Card } from "../lib/styled-components";
import CalendarHeatmap  from './vis-components/calendar-heatmap';
import CalendarDotHeatmap  from './vis-components/calendar-dot-heatmap';
import { parseISO, addDays, addWeeks, subDays, formatISO, eachMonthOfInterval, getISOWeek, getISOWeekYear } from 'date-fns';
import { rollupAndReduce, monthsShort, norwegianShiftNames, dsIdToName } from "../lib/common";
import defaultTheme from "../themes/light-theme";
import EmployeeFilter from "./date_pattern/employee-filter";
import Tooltip from "../lib/tooltip";
import { HorizontalBarChart } from "./vis-components/horizontal-bar-chart";
import { HorizontalBinBarChart } from "./vis-components/horizontal-bin-bar-chart";


const TaskEmployee = ({
    resultVis,
    availabilityVis
}) => {
    const hasResult = Boolean(resultVis);
    const [showResult, setShowResult] = useState(hasResult);

    return <div>
        {hasResult &&
            <ButtonGroup style={{margin: "1em 0"}}>
                <Button
                    style={{padding: "4px 25px"}}
                    primary={showResult}
                    onClick={() => setShowResult(true)}
                    data-for="show-empl-result"
                    data-tip="Her kan du se hvilke vaktkategorier hver ansatt
                        jobber i turnusplanen"
                >Se resultat</Button>
                <Button
                    style={{padding: "4px 25px"}}
                    primary={!showResult}
                    onClick={() => setShowResult(false)}
                >Se tilgjengelighet for arbeid</Button>
            </ButtonGroup>
        }
        {hasResult && <Tooltip id="show-empl-result" />}
        {showResult ? resultVis : availabilityVis}
    </div>
}

const TaskEmployeeLabel = ({
    name,
    vacancyRate,
    position,
    ds,
    result
}) => {
    const dsCounts = (result || []).reduce(
        (a, v) => {a[v] = (a[v] || 0) + 1; return a;}, {}
    )
    return <Flex
        alignItems="center"
        justifyContent="start"
        style={{gap: "1em"}}
    >
        <Box style={{ "flex": "0 1 10%"}}><Txt>{name}</Txt></Box>
        <Box style={{ "flex": "0 1 5%"}}><Txt>{vacancyRate + "%"}</Txt></Box>
        <Box style={{ "flex": "0 1 15%"}}><Txt>{position}</Txt></Box>
        {
            ds &&
            <HorizontalBinBarChart
                style={{ "flex": "0 0 250px"}}
                data={[
                    ['D', ds.D.min, ds.D.max],
                    ['A', ds.A.min, ds.A.max],
                    ['L', ds.L.min, ds.L.max],
                    ['N', ds.N.min, ds.N.max],
                ]}
                xDomain={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                colRange={['#1f78b4', '#e31a1c', '#ff7f00', '#33a02c']}
                width={250}
                height={50}
            />
        }
        {result &&
            <HorizontalBarChart
                style={{ "flex": "0 0 250px"}}
                data={[
                    ['D', dsCounts.D || 0],
                    ['A', dsCounts.A || 0],
                    ['L', dsCounts.L || 0],
                    ['N', dsCounts.N || 0],
                ]}
                xDomain={[0, 0.75 * result.length * (vacancyRate / 100)]}
                colRange={['#1f78b4', '#e31a1c', '#ff7f00', '#33a02c']}
                width={250}
                height={50}
            />
        }
    </Flex>
}


export const TaskEmployees = ({
    task,
    result,
    employees,
    requirements,
    positions
}) => {
    const dynomite = useContext(dynomiteContext);
    const [searchFilters, setSearchFilters] = useState({
        employee: undefined,
        positions: []
    });

    /**
     * Adjust filtered employees (and their date patterns) based on the change
     * of filter parameters.
     * 
     * Filtering is cached for any non-filtering re-render.
     */
    const filteredEmployees = useMemo(
        () => employees
            .filter(e => 
                !searchFilters.employee
                || e.name.toLowerCase().includes(searchFilters.employee.toLowerCase())
            )
            .filter(e =>
                searchFilters.positions.length === 0
                || searchFilters.positions.includes(e.position)
            ),
        [searchFilters]
    )

    /**
     * Prepare input data for visualization (start/end dates, months labels)
     * - start/end date
     * - months list
     * - months labels
     */ 
    let startDate = task.config.startDate;
    let startDateObj = parseISO(startDate, {representation: 'date'})
    let startDateIsoWeek = getISOWeek(startDateObj);
    let startDateIsoYear = getISOWeekYear(startDateObj);
    let endDate = formatISO(
        addWeeks(subDays(startDateObj, 1),task.config.nrOfWeeks),
        {representation: 'date'}
    );
    let inMonths = eachMonthOfInterval({
        start: startDateObj,
        end: parseISO(endDate, {representation: 'date'})
    }).map((month) =>
        formatISO(month, {representation: 'date'})
            .split('-')
            .slice(0, 2)
            .join('-')
    );
    let monthsLabels = inMonths.reduce((a, v) => ({
        ...a,
        [v]: monthsShort[parseInt(v.split('-')[1]) - 1]
    }), {});

    /** Collect Position names for respective ids */
    let posNames = positions.reduce((a, v) => ({...a, [v.id]: v.name}), {});

    let days = Array.from(
        { length: task.config.nrOfWeeks * 7 },
        (_, i) => formatISO(addDays(startDateObj, i), {representation: 'date'})
    );

    return <Card style={{
        borderTopLeftRadius: '0px',
        marginLeft: '0px',
        width: '1350px',
        padding: '25px'

    }}>
        <EmployeeFilter
            name={{
                value: searchFilters.employee,
                onChange: name => setSearchFilters({
                    ...searchFilters,
                    employee: name
                })
            }}
            positions={{
                value: positions,
                width: '300px',
                isMulti: true,
                onChange: positions => setSearchFilters({
                    ...searchFilters,
                    positions: positions
                }) 
            }}
        />
        <hr />
        <Accordion
            hidePanelDivider={false}
            keyFunc={(item) => item.id}
            items={
                filteredEmployees.map(empl => ({
                    key: `bp-employee-row-${empl.id}`,
                    startOpen: false,
                    scrollIntoView: false,
                    label: <TaskEmployeeLabel
                        name={empl.name}
                        vacancyRate={empl.vacancyRate}
                        position={posNames[empl.position]}
                        ds={{
                            D: { min: empl.dayShiftMinSequence, max: empl.dayShiftMaxSequence },
                            A: { min: empl.eveningShiftMinSequence, max: empl.eveningShiftMaxSequence },
                            L: { min: empl.longShiftMinSequence, max: empl.longShiftMaxSequence },
                            N: { min: empl.nightShiftMinSequence, max: empl.nightShiftMaxSequence }
                        }}
                        result={result[empl.id]}
                    />,
                    panel: <TaskEmployee
                        resultVis={(Object.keys(result).length > 0 && empl.id in result) ?
                            <CalendarHeatmap
                                data={rollupAndReduce(
                                    result[empl.id].map((char, i) => ({
                                        Date: days[i],
                                        Value: char === 'F' ? 'Ikke jobbe' : norwegianShiftNames(char)
                                    })),
                                    (day) => ({
                                        Date: day[0].Date,
                                        Value: day[0].Value
                                    })
                                )}
                                inMonths={inMonths}
                                monthsLabels={monthsLabels}
                                width={1300}
                                height={220}
                                ordinalScale={{domain: [
                                    'Ikke jobbe',
                                    'Dagvakt',
                                    'Kveldsvakt',
                                    'Langvakt/Mellomvakt',
                                    'Nattevakt',
                                ]}}
                                colorScheme={[[], [], [], [], [
                                    '#ffffff',
                                    '#1f78b4',
                                    '#e31a1c',
                                    '#ff7f00',
                                    '#33a02c',
                                ]]}
                                undefinedColor = "#ffffff"
                                legendLabel = ''
                                weekSelection={{
                                    year: startDateIsoYear,
                                    start: startDateIsoWeek,
                                    count: task.config.nrOfWeeks,
                                    color: defaultTheme.colors.primary.main
                                }}
                                hidden={false}
                            />
                            : null
                        }
                        availabilityVis={
                            <CalendarDotHeatmap
                                data={rollupAndReduce(
                                    dynomite.dynomite.employeeScheduleAvailability(
                                        {
                                            ...empl,
                                            shiftTypes: [...Object.entries(empl.shiftTypes || {})]
                                        },
                                        requirements
                                    ).map((data) => ({
                                        Date: data[0],
                                        Value: data[1]
                                    })),
                                    (day) => ({
                                        Date: day[0].Date,
                                        Value: new Int8Array(day[0].Value).reduce(
                                            (acc, v , i) => {
                                                acc.push([
                                                    v > 0 ? dsIdToName(i) :
                                                    v === 0 ? dsIdToName(i) + '(kan jobbe)' :
                                                    undefined,
                                                    v >= 0 ? undefined :
                                                    v === -13 || v === -14 ? undefined :
                                                    v <= -5 ? 'andre blokkeringer' :
                                                    'planlagte blokkeringer'
                                                ]);
                                                return acc;
                                            },
                                            []
                                        )
                                    })
                                )}
                                inMonths={inMonths}
                                monthsLabels={monthsLabels}
                                width={1300}
                                height={220}
                                dotScale={{
                                    name: 'ordinal',
                                    domain: [
                                        'Dagvakt', 'Dagvakt(kan jobbe)',
                                        'Kveldsvakt', 'Kveldsvakt(kan jobbe)',
                                        'Langvakt/Mellomvakt', 'Langvakt/Mellomvakt(kan jobbe)',
                                        'Nattevakt', 'Nattevakt(kan jobbe)'
                                    ]
                                }}
                                dotColorScheme={[[], [], [], [], [], [], [], [
                                    '#1f78b4', '#b3cde3',
                                    '#e31a1c', '#fbb4ae',
                                    '#ff7f00', '#fed9a6',
                                    '#33a02c', '#ccebc5'
                                ]]}
                                backScale={{
                                    name: 'ordinal',
                                    domain: ['planlagte blokkeringer', 'andre blokkeringer']
                                }}
                                backColorScheme={[[], ['#ffffff', '#bbbbbb']]}
                                undefinedColor = "#ffffff"
                                dotLegendLabel = ''
                                backLegendLabel = ''
                                weekSelection={{
                                    year: startDateIsoYear,
                                    start: startDateIsoWeek,
                                    count: task.config.nrOfWeeks,
                                    color: defaultTheme.colors.primary.main
                                }}
                                hidden={false}
                            />
                        }
                    />
                })
            )}
        />
    </Card>
};
