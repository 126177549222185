import React, { useEffect, useState } from "react";
import { dynoapeAPI, getSortedCompetences } from "../api/dynoape";
import { useParams } from "react-router-dom";
import {customMaterialReactTableLocalization, validCharactersRegex} from "../lib/common";
import {
  MaterialUIAddModal,
  MaterialUIDeleteModal,
  MaterialUIRoundAddButton
} from "../lib/styled-components";
import Icon from "../lib/icon";
import {MaterialReactTable} from "material-react-table";
import {Button, TextField} from "@mui/material";
import Spinner from 'react-spinner-material';

const CompetencesTable = () => {
  const { departmentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [showCompetenceModal, setShowModal] = useState({ show: false, id: undefined });
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    id: "",
  });
  const [showInvalidNameError, setShowInvalidNameError] = useState({
    show: false,
    msg: "",
  });
  const [competenceName, setNameInput] = useState("");
  const [competences, setCompetences] = useState([]);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Navn',
      size: 450,
      muiTableBodyCellProps: {
        sx: {
          paddingLeft: '50px',
        },
      },
      muiTableHeadCellProps: {
        sx: {
          paddingLeft: '50px',
        },
      }
    },
    {
      header: 'Handlinger',
      size: 50,
      Cell: ({ row }) => (
          <>
            <Button
                variant="contained"
                data-testid={`update-${row.original.id}`}
                sx={{backgroundColor: "rgb(48, 105, 112)", borderRadius: "20px"}}
                onClick={() => {
                  setNameInput(row.original.name)
                  setShowModal({ "show": true, "id": row.original.id });
                }}
                style={{ marginRight: "5px"}}
            >
              <Icon name="edit-w" />
            </Button>
            <Button
                variant="contained"
                sx={{borderRadius: "20px"}}
                data-testid={`delete-${row.original.id}`}
                color="error"
                onClick={() => {
                  setShowDeleteModal({ "show": true, "id": row.original.id })
                }}
            >
              <Icon name="trash-w"/>
            </Button>
          </>
      ),
    }
  ]

  const closeModal = () => {
    setShowInvalidNameError({ show: false, msg: "" });
    setNameInput("");
    setShowModal({ show: false, id: undefined });
  };

  useEffect(() => {
    const setSortedCompetences = async () => {
      let comp = await getSortedCompetences(departmentId);
      setCompetences(comp);
      setIsLoading(false);
    };
    setSortedCompetences();
  }, []);

  const valid = (input) => {
    if (competences.length >= 99 && showCompetenceModal.id === undefined) {
      setShowInvalidNameError({
        show: true,
        msg: "Kan ikke ha flere enn 99 aktive spesialkompetanser",
      });
      return false;
    }
    if (input.trim().length <= 0) {
      setShowInvalidNameError({
        show: true,
        msg: "Navn på spesialkompetanse er påkrevd",
      });
      return false;
    }
    if (
        competences.filter(
            (p) =>
                p.name.toLowerCase() === input.toLowerCase() &&
                p.id !== showCompetenceModal.id
        ).length > 0
    ) {
      setShowInvalidNameError({
        show: true,
        msg: "Det finnes allerede en spesialkompetanse med det navnet",
      });
      return false;
    }

    if (input.length > 30) {
      setShowInvalidNameError({
        show: true,
        msg: "Navn på spesialkompetansen kan ikke være lenger enn 30 tegn",
      });
      return false;
    }

    if (!validCharactersRegex().test(input)) {
      setShowInvalidNameError({
        show: true,
        msg: "Navn på spesialkompetanse kan ikke inneholde ugyldige tegn",
      });
      return false;
    }
    setShowInvalidNameError({ show: false, msg: "" });
    return true;
  }

  const save = async () => {
    if(!valid(competenceName)) return;
    showCompetenceModal.id === undefined
        ? await dynoapeAPI.post(`/api/v1/department/${departmentId}/trait`, {name: competenceName.trim()})
        : await dynoapeAPI.put(`/api/v1/department/${departmentId}/trait/${showCompetenceModal.id}`, {name: competenceName.trim()})

    let comp = await getSortedCompetences(departmentId);
    setCompetences(comp);
    closeModal();
  }

  const deleteCompetence = async (id) => {
    try {
      await dynoapeAPI.delete(`/api/v1/department/${departmentId}/trait/${id}`);
      setCompetences(
        competences.filter((p) => {
          return p.id !== id;
        })
      );
    } catch (error) {
      console.error(error);
    }
    setShowDeleteModal({ show: false, id: "" });
  };

  return (
    <div>
      <MaterialUIRoundAddButton
          onClick={() => setShowModal({ "show": true, "id": undefined })}
          text="Legg til spesialkompetanse"
      />
      {isLoading
          ? <Spinner radius={120} color={"#333"} stroke={2} visible={true} />
          : !competences?.length
              ? <div style={{ background: "white", padding: "10px 20px 20px 10px" }}><h4>Ingen spesialkompetanser funnet</h4></div>
              : <MaterialReactTable
                  columns={columns}
                  data={competences}
                  localization={customMaterialReactTableLocalization}
              />
      }
      <MaterialUIAddModal
          open={showCompetenceModal.show}
          onClose={() => closeModal()}
          title={showCompetenceModal.id !== undefined ? "Endre spesialkompetanse" : "Legg til ny spesialkompetanse"}
          onConfirm={() => save()}
      >
        <div style={{marginBottom: "20px"}}>
          <TextField
              sx={{
                input: {
                  fontFamily: "Montserrat Alternates",
                  height: "5px",
                  width: "300px"
                }
              }}
              onChange={(e) => {
                let name = e.target.value.trim();
                setNameInput(name);
                valid(name)
              }}
              defaultValue={competenceName}
              placeholder="Navn"
              slotProps={{ input: { 'data-testid': 'nameInput' } }}
          />
          {showInvalidNameError.show && (
              <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
          )}
        </div>
      </MaterialUIAddModal>
      <MaterialUIDeleteModal
          open={showDeleteModal.show}
          onClose={() => setShowDeleteModal({ show: false, id: undefined })}
          title={"Fjern spesialkompetanse"}
          confirmationText={`Er du sikker på at du vil slette spesialkompetanse ${competences.filter(sh => sh.id === showDeleteModal.id)?.[0]?.name}?`}
          deleteText={"Slett spesialkompetanse"}
          onConfirm={() => deleteCompetence(showDeleteModal.id)}
          isLoading={isLoading}
      />
    </div>
  );
};

export default CompetencesTable;
