import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "rendition";
import { useHistory, NavLink } from 'react-router-dom';
import Icon from "../lib/icon";
import styled from "styled-components";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Button primary
      ml="auto"
      onClick={() => loginWithRedirect()}
    >
      Logg Inn
    </Button>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Button primary
      ml="auto"
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Log Out
    </Button>
  );
};

const AuthenticationButton = () => {
  const { isAuthenticated, user } = useAuth0();

  if (isAuthenticated) {
    const { name } = user;

    return <div><span style={{ marginRight: "10px" }}>{name}</span> <LogoutButton /></div>;
  }

  return <LoginButton />;
};

const BackButton = () => {
  let history = useHistory();
  return (
    <HeaderButton primary style={{ left: "10px" }} onClick={() => history.goBack()}><Icon name="pil" /></HeaderButton>
  );
};

const BackButtonEmployee = ({ id }) => {
  return (
    <NavLink className="nav-link" to={`../${id ? id : "ansatte"}`}><HeaderButton primary style={{ left: "10px" }}><Icon name="pil" /></HeaderButton></NavLink>
  );
};

const HeaderButton = styled(Button)`
  position: absolute;
  top: 100px;
  background: #306970;
  border-radius: 100px;
  height: 50px;
  width: 50px;
  padding: 5px 0px 0px 0px;
`;

export { LoginButton, LogoutButton, AuthenticationButton, BackButton, BackButtonEmployee };