import React, {useEffect, useState} from "react";
import moment from "moment";
import {dynomiteErrorPanel, getWeekdaysFromTask, monthsShort, scrollHeight, shortenStr} from "../../lib/common";
import {dynoapeAPI} from "../../api/dynoape";
import {PlannerWrapper, ReactGridWrapper} from "./employee-date-pattern-cases";
import {Button, Flex} from "rendition";
import Tooltip from "../../lib/tooltip";
import {WeekJumper} from "../../lib/styled-components";
import {ReactGrid} from "@silevis/reactgrid";
import {CheckboxCellTemplate} from "../grid-components/checkbox-cell-template";


export default ({
                    somePatternCaseIsDirty, setSomePatternCaseIsDirty, viewStartIndex, setViewStartIndex, setViewFlipSize, viewFlipSizeRef,
                    departmentId,
                    taskSelect, setSelectedTaskOption, setSelectedTask, selectedTask,
                    employees, patternWithinPeriod,
                    reset,
                    getReactGridRows, getReactGridHeaderRow,
                    showSavingModal, saveModal,
                    dynomite, validate, configErrors, configWarnings, dynomiteInternalError,
                    startDate, setStartDate, nrOfWeeks, setNrOfWeeks,
                    searchBar, searchFilters, applySearchFiltersToEmployees, routerPrompt,
                    dayWithinSeries, resolveEmployeePatternCaseWeeks, placeholderId,
                    mergePatterns, removeOrSplitFullWeekPatterns, doSave}) => {

    const [toSave, setToSave] = useState([]);
    const nrInView = 12;
    const [columns, setColumns] = useState(undefined);
    const [mondays, setMondays] = useState(undefined);

    /**
     * Method for creating a valid paid leave pattern. Period is set to from a certain Monday until Sunday the same week
     * @param monday
     * @returns {{summary: string, includeDays: *[], optimize: boolean, series: {nth: number, toggled: boolean, from: string, until: string, mask: boolean[]}, kind: string, daySegments: string[], disabled: boolean, id: string, excludeDays: *[], delete: boolean}}
     */
    const validPaidLeavePattern = (monday) => {
        const start = moment(monday).startOf('isoWeek').format('YYYY-MM-DD');
        const end = moment(monday).endOf('isoWeek').format('YYYY-MM-DD');
        return {
            id: placeholderId,
            delete: false,
            summary: "Permisjon " + start + " - " + end,
            series: {
                toggled: true,
                from: start,
                until: end,
                nth: 1,
                mask: [true, true, true, true, true, true, true]
            },
            includeDays: [],
            excludeDays: [],
            daySegments: ["D", "A", "N", "L"],
            disabled: false,
            optimize: false,
            kind: "paidLeave"
        }
    }

    useEffect(() => {
        scrollHeight();
        return () => {
            reset();
        }
    }, []);

    useEffect(() => {
        validate({employeesParam: applySearchFiltersToEmployees(employees).map(e => getLocalOrGlobalEmployee(e.id))});

        if((somePatternCaseIsDirty && toSave.length > 0) || (!somePatternCaseIsDirty && toSave.length === 0)) return;
        setSomePatternCaseIsDirty(toSave.length > 0);
    }, [toSave, employees, searchFilters]);

    /**
     * Helper function for getting employee data. If there are unsaved employee data, we fetch it from local storage.
     * If not, the employee is returned
     * @param employeeId
     * @returns {*}
     */
    const getLocalOrGlobalEmployee = (employeeId) => {
        return toSave.find(e => e.id === employeeId) || employees.find(e => e.id === employeeId);
    }

    /**
     * Returns true if a certain employee is on paid leave on a given week (Monday), false if not
     * @param employeeId
     * @param monday
     * @returns {boolean}
     */
    const hasPaidLeave = (employeeId, monday) => {
        return dayWithinSeries(getLocalOrGlobalEmployee(employeeId), monday)
    }

    /**
     * Method for getting nr of paid leave weeks for an employee in the given period
     * @param employeeId
     * @returns {*}
     */
    const resolveNrOfPaidLeaveWeeks = (employeeId) => {
        return resolveEmployeePatternCaseWeeks(getLocalOrGlobalEmployee(employeeId));
    }

    /**
     * UseEffect for generating the columns in the paid leave planner. The following columns are generated:
     * - Row nr
     * - Employee name
     * - Total number of paid leave weeks for each employee in the given period
     * - One column per week in the given period
     */
    useEffect(() => {
        if(!mondays) return;
        const _employees = applySearchFiltersToEmployees(employees, mondays[0], mondays[mondays.length -1]);
        const cols =
            [
                { columnId: "nr", included: true, width: 40, text: "Rad", item: ({ idx }) => ({ type: "header", text: `${idx + 1}` }), total: "" },
                { columnId: "name", included: true, width: 260, text: "Navn", item: ({ employee }) => ({ type: "header", text: `${shortenStr(employee.name, 30)}` }), total: "Antall på permisjon"},
                { columnId: "nrOfPaidLeaveWeeks", included: true, width: 100, text: "Antall permisjons-uker", item: ({ employee }) => ({ type: "header", text: `${selectedTask ? resolveNrOfPaidLeaveWeeks(employee.id) : ""}` }), total: "" },
            ]
                .concat(mondays.map(d => {
                    const id = d.format("YYYY-MM-DD");
                    const text = `Uke ${d.month() === 11 && d.isoWeek() === 1 ? 53 : d.isoWeek()} \n ${d.year()} ${monthsShort[d.month()]}`
                    return { columnId: id, included: true, width: 100, text: text,
                        item: ({ employee }) => ({ type: "checkbox", checked: hasPaidLeave(employee.id, d), checkedText: "Ja", uncheckedText: "Nei" }),
                        total: _employees.reduce((count, e) => {return count + (hasPaidLeave(e.id, d) ? 1 : 0);}, 0) +""}
                }))
        setColumns(cols)
    }, [mondays, employees, toSave, searchFilters])

    /**
     * Method for resetting mondays following task removal
     */
    const removeSelectedTask = () => {
        setMondays(
            getWeekdaysFromTask({config: {startDate: moment().add(1, 'weeks').startOf('isoWeek'), nrOfWeeks: 157}}, [1])
                .slice(viewStartIndex, viewStartIndex + nrInView)
        )
    }

    /**
     * UseEffect for generating which weeks that should be present in the paid leave planner.
     * Triggered whenever user viewStartIndex is changed (user selects to jump weeks)
     */
    useEffect(() => {
        setMondays(
            getWeekdaysFromTask({config: {startDate: startDate, nrOfWeeks: nrOfWeeks}}, [1])
                .slice(viewStartIndex, viewStartIndex + nrInView)
        )
    }, [viewStartIndex])

    /**
     * Method for handling the changes inside the paid leave planner (react grid)
     * @param changes
     */
    const handleChanges = (changes) => {
        let localToSave = toSave;
        changes.forEach(ch => {
            const employee = (localToSave.find(e => e.id === ch.rowId) || employees.find(e => e.id === ch.rowId));
            const date = moment(ch.columnId).format('YYYY-MM-DD');
            let patterns = (employee.paidLeavePatterns || []);

            if (ch.newCell.checked) {
                const flaggedAsDelete = patterns.find(p => p.delete === true && patternWithinPeriod(p, date, date));
                const pattern = flaggedAsDelete ? {...flaggedAsDelete, delete: false} : validPaidLeavePattern(date);
                patterns = mergePatterns([...patterns.filter(p => p.id !== flaggedAsDelete?.id), pattern], "Permisjon");
            } else {
                patterns = removeOrSplitFullWeekPatterns(patterns, date, "Permisjon")
            }
            employee.paidLeavePatterns = patterns;
            localToSave = [...localToSave.filter(e => e.id !== employee.id), {...employee, paidLeavePatterns: patterns}]
        })
        setToSave(toSave.filter(e => !localToSave.find(le => le.id === e.id)).concat(localToSave))
    }

    /**
     * Method for posting/putting/deleting paidLeave patterns
     * @returns {Promise<void>}
     */
    const save = async () => {
        await doSave(toSave)
        setToSave([]);
    }

    /**
     * Method for handling the select of a BP
     * @param selectedTaskOption
     * @returns {Promise<void>}
     */
    const handleSelectTaskChange = async (selectedTaskOption) => {
        if(!selectedTaskOption) {
            reset();
            return removeSelectedTask();
        }
        setColumns(undefined);
        let t = await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${selectedTaskOption.value}`);
        setStartDate(t.config.startDate);
        setNrOfWeeks(t.config.nrOfWeeks);
        const mon = getWeekdaysFromTask(t, [1]);
        setMondays(mon.slice(0, nrInView));
        setSelectedTask(t);
        setSelectedTaskOption({value: selectedTaskOption.value, label: selectedTaskOption.label});
        setViewStartIndex(0)
        scrollHeight();
    }

    return (
        <PlannerWrapper>
            <p><label style={{fontSize: "16px"}}>
                Her kan du planlegge hvilke uker hver ansatt skal ha permisjon
            </label></p>
            <br/>
            <Flex flexDirection="row">
                <Button primary style={{marginTop: "0px" }} onClick={() => save()}
                        data-for="save"
                        disabled={toSave.length === 0}
                        data-tip="Lagre dine permisjons-spesifikasjoner for de ansatte">Lagre</Button>
                <Tooltip id="save" />
                {taskSelect(handleSelectTaskChange)}
                {searchBar()}
                {columns &&
                    <WeekJumper flipSize={viewFlipSizeRef.current} setFlipSize={setViewFlipSize}
                                viewStartIndex={viewStartIndex} setViewStartIndex={setViewStartIndex}
                                nrInView={nrInView} jumpTitle="Hopp antall uker" totalNrOfColumns={nrOfWeeks}>
                    </WeekJumper>
                }
            </Flex>
            {columns === undefined && <p>Laster...</p>}
            {columns && <ReactGridWrapper>
                <ReactGrid
                    customCellTemplates={{
                        'checkbox': CheckboxCellTemplate
                    }}
                    rows={[
                        getReactGridHeaderRow(columns, 'header', 'text', 80),
                        ...getReactGridRows(columns, employees.map(e => getLocalOrGlobalEmployee(e.id)), mondays[0], mondays[mondays.length -1]).map((row, idx) => idx % 2 !== 0 ?
                            { ...row, cells: row.cells.map(cell => ({ ...cell, style: {  background: 'rgba(0,0,0,0.07)', color: !row._employee.enabled ? "#888" : "black" } })) }
                            : { ...row, cells: row.cells.map(cell => ({ ...cell, style: { color: !row._employee.enabled ? "#888" : "black" } })) }
                        ),
                        getReactGridHeaderRow(columns, 'total', 'total')
                    ]}
                    columns={columns}
                    stickyTopRows={1}
                    stickyBottomRows={1}
                    stickyLeftColumns={3}
                    onCellsChanged={handleChanges}
                    enableFillHandle
                    enableRangeSelection
                    enableRowSelection
                />
            </ReactGridWrapper>}
            {showSavingModal.display && saveModal()}
            {routerPrompt(toSave.length > 0)}
            {configErrors.length > 0 && dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError)}
            {configWarnings.length > 0 && dynomiteErrorPanel(dynomite, configWarnings, dynomiteInternalError, 0, () => {}, true)}
        </PlannerWrapper>
    )
}