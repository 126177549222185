import {PatternCase} from "../../lib/pattern-case";
import React, {useContext, useEffect, useRef, useState} from "react";
import {dynoapeAPI} from "../../api/dynoape";
import {useParams} from "react-router-dom";
import Select from "react-select";
import {
    getDaysFromDatePattern,
    convertToExtendedEmployeeConfig,
    getDatePattern,
    getDaysFromDatePatterns
} from "../../lib/common";
import EmployeeDatePatternWeekendPlanner from "./employee-date-pattern-weekend-planner";
import dynomiteContext from "../dynomite";
import moment from "moment";
import EmployeeDatePatternBlockedPatterns from "./employee-date-pattern-blocked-patterns";
import EmployeeDatePatternVacation from "./employee-date-pattern-vacation";
import {Checkbox, Flex, Input, Modal, ProgressBar} from "rendition";
import styled from "styled-components";
import Tooltip from "../../lib/tooltip";
import EmployeeDatePatternHoliday from "./employee-date-pattern-holiday";
import _ from "lodash";
import {applyPatch} from "rfc6902";
import {RouterPrompt} from "../../lib/confirm/router-prompt";
import EmployeeDatePatternPaidLeave from "./employee-date-pattern-paid-leave";

export default ({somePatternCaseIsDirty, setSomePatternCaseIsDirty, patternCase}) => {
    const { departmentId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [department, setDepartment] = useState()
    const [tasks, setTasks] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employeesAvailabilities, setEmployeesAvailabilities] = useState(new Map());
    const [positions, setPositions] = useState([]);
    const [traits, setTraits] = useState([]);
    const [shiftTypes, setShiftTypes] = useState([])
    const [selectedTask, setSelectedTask] = useState(undefined);
    const [selectedTaskOption, setSelectedTaskOption] = useState(undefined);
    const [requirements, setRequirements] = useState(new Map());
    const [viewFlipSize, setViewFlipSize] = useState(2);
    const viewFlipSizeRef = useRef(viewFlipSize);
    viewFlipSizeRef.current = viewFlipSize;
    const [viewStartIndex, setViewStartIndex] = useState(0);

    const dynomite = useContext(dynomiteContext);
    const [configErrors, setConfigErrors] = useState([]);
    const [configWarnings, setConfigWarnings] = useState([]);
    const [dynomiteInternalError, setDynomiteInternalError] = useState(false);

    const [showSavingModal, setShowSavingModal] = useState({ display: false, saveCount: 0, toSave: 0, employee: null });

    const [defaultStartDate, defaultNrOfWeeks] = [moment().add(1, 'weeks').startOf('isoWeek'), 157]

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [nrOfWeeks, setNrOfWeeks] = useState(defaultNrOfWeeks);
    const [holidays, setHolidays] = useState([]);

    const [openedDropdownLocation, setOpenedDropdownLocation] = useState({rowId: '', columnId: ''});

    const [searchFilters, setSearchFilters] = useState({
        employee: undefined,
        positions: [],
        traits: [],
        daySegments: [],
        showOnlyWithPatterns: false
    });

    const placeholderId = "placeholderId";

    /**
     * Method for setting what search various hardcoded values used by search filtering.
     * @returns {{}}
     */
    const setPatternCaseSearchFilterInfo = () => {
        let obj = {};
        obj[PatternCase.WEEKEND_PATTERN] = {kinds: ["working"], dynomiteKinds: ["working"], limitSearchTooltip: "Vis kun de som jobber helg i perioden"};
        obj[PatternCase.HOLIDAY_PATTERN] = {kinds: ["working"], dynomiteKinds: ["working"], limitSearchTooltip: "Vis kun de som jobber helligdager i perioden"};
        obj[PatternCase.VACATION] = {kinds: ["vacation"], dynomiteKinds: ["vacation"], limitSearchTooltip: "Vis kun de som har ferie i perioden"};
        obj[PatternCase.BLOCKED_PATTERN] = {kinds: ["notWorking", "vacation"], dynomiteKinds: ["notWorking", "vacation"], limitSearchTooltip: "Avgrens"};
        obj[PatternCase.PAID_LEAVE_PATTERN] = {kinds: ["paidLeave"], dynomiteKinds: ["paidLeave"], limitSearchTooltip: "Vis kun de som har permisjon i perioden"};
        return obj;
    }
    const patternCaseSearchFilterInfo = setPatternCaseSearchFilterInfo()

    /**
     * Function for opening the select box containing the start dates used in various sheets
     * @param changes
     * @returns {undefined|{columnId, rowId: *}}
     */
    function findAndSetOpenedDropdownCellLocation(changes) {
        const openedDropdownChanges = changes.filter(
            (change) => change.type === "dropdown" && change.newCell.isOpen
        );
        setOpenedDropdownLocation(
            openedDropdownChanges.length >= 1
                ? [openedDropdownChanges[0]].map(cell => ({ rowId: cell.rowId, columnId: cell.columnId }))[0]
                : undefined
        )
    }

    useEffect(() => {
        validate({});
    }, [startDate, nrOfWeeks])

    /**
     * Method for validating employee through dynomite
     * @param departmentParam
     * @param employeesParam
     * @param positionsParam
     * @param traitsParam
     * @param shiftTypesParam
     * @returns {Promise<void>}
     */
    const validate = async ({departmentParam, employeesParam, positionsParam, traitsParam, shiftTypesParam}) => {
        try {
            const config = convertToExtendedEmployeeConfig({
                department: departmentParam || department,
                employees: employeesParam || employees,
                restTraits: traitsParam || traits,
                restPositions: positionsParam || positions,
                restShiftTypes: shiftTypesParam || shiftTypes,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                nrOfWeeks: nrOfWeeks}
            );
            const conf = dynomite.dynomite.parseEmployeesExtended(JSON.stringify(config));
            setConfigErrors([]);
            setConfigWarnings(conf.warnings ?? [])
            setDynomiteInternalError(undefined);
        } catch (err) {
            if(err.payload) {
                const errs = err.payload.filter(e => !e.tag.includes('Warning::'));
                const warnings = err.payload.filter(e => e.tag.includes('Warning::'));
                setConfigErrors(errs)
                setConfigWarnings(warnings)
            } else {
                setDynomiteInternalError(true);
            }
        }
    }

    /**
     * Get data needed by pattern modules
     * @returns {Promise<void>}
     */
    const getData = async () => {
        setIsLoading(true);
        const [
            departmentData,
            employeesData,
            tasksData,
            positionsData,
            traitsData,
            shiftTypesData,
        ] = await Promise.all([
            dynoapeAPI.get(`/api/v1/department/${departmentId}`),
            dynoapeAPI.get(`/api/v1/department/${departmentId}/employees${PatternCase.getIncludeQueryParams(patternCase)}`),
            dynoapeAPI.get(`/api/v1/department/${departmentId}/tasks`),
            dynoapeAPI.get(`/api/v1/department/${departmentId}/positions`),
            dynoapeAPI.get(`/api/v1/department/${departmentId}/traits`),
            dynoapeAPI.get(`/api/v1/department/${departmentId}/shift-types`)
        ]);
        const employees = employeesData?.sort(function (a, b) {
            if(a.priority === b.priority) {
                return new Date(a.registered) - new Date(b.registered);
            } else {
                return a.priority - b.priority;
            }
        }).filter(e => e.enabled)

        validate({
            departmentParam: departmentData,
            employeesParam: employeesData,
            traitsParam: traitsData,
            positionsParam: positionsData,
            shiftTypesParam: shiftTypesData
        })

        setDepartment(departmentData);
        processAndSetHolidays(departmentData, defaultStartDate, defaultNrOfWeeks);
        setEmployeesWithAvailabilities(employees);
        setTasks(tasksData.elements);
        setPositions(positionsData);
        setTraits(traitsData);
        setShiftTypes(shiftTypesData)
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
        setSearchFilters({employee: undefined, positions: [], traits: [], daySegments: [], showOnlyWithPatterns: false})
    }, [patternCase]);

    useEffect(() => {
        if(department) {
            processAndSetHolidays(department, startDate, nrOfWeeks)
        }
    }, [startDate, nrOfWeeks])

    const setEmployeesWithAvailabilities = (employees) => {
        setEmployees(employees);
        setEmployeesAvailabilities(
            requirements.size === 0 ? 
                new Map(
                    employees.map(e => [
                        e.id,
                        new Map(dynomite.dynomite.employeeAvailability(
                            {
                                ...e,
                                shiftTypes: [...Object.entries(e.shiftTypes || {})]
                            },
                            moment(startDate).format('YYYY-MM-DD'),
                            moment(startDate).add(nrOfWeeks, 'weeks').format('YYYY-MM-DD'),
                        ))
                    ])
                ) :
                new Map(
                    employees.map(e => [
                        e.id,
                        new Map(dynomite.dynomite.employeeScheduleAvailability(
                            {
                                ...e,
                                shiftTypes: [...Object.entries(e.shiftTypes || {})]
                            },
                            [...requirements.entries()]
                        ))
                    ])
                )
        )
    }

    const setSelectedTaskInfo = (task) => {
        setSelectedTask(task);
        let _req = [...processAndSetRequirements(task).entries()];
        setEmployeesAvailabilities(new Map(
            employees.map(e => [
                e.id,
                new Map(
                    _req.length > 0
                    ? dynomite.dynomite.employeeScheduleAvailability(
                        {
                            ...e,
                            shiftTypes: [...Object.entries(e.shiftTypes || {})]
                        },
                        _req
                    )
                    : dynomite.dynomite.employeeAvailability(
                        {
                            ...e,
                            shiftTypes: [...Object.entries(e.shiftTypes || {})]
                        },
                        defaultStartDate.format('YYYY-MM-DD'),
                        moment(defaultStartDate).add(defaultNrOfWeeks, 'weeks').format('YYYY-MM-DD')
                    )
                )
            ])
        ));
    }

    const processAndSetRequirements = (_task) => {
        const requirements = new Map(
            dynomite.dynomite.scheduleRequirements(
                JSON.stringify(_task?.config || {})
            )
        )
        setRequirements(requirements);
        return requirements;
    }

    /**
     * Method for processing and setting the public holidays in a given period. If patternCase is
     * WEEKEND_PATTERN, a given Sunday will be appended if Friday or Saturday is a holiday
     * @param _department
     * @param _startDate
     * @param _nrOfWeeks
     * @returns {string[]}
     */
    const processAndSetHolidays = (_department, _startDate, _nrOfWeeks) => {
        _startDate = moment(_startDate);
        const _endDate = _startDate.clone().add(_nrOfWeeks, 'weeks').subtract(1, 'day');
        let result = Object.keys(_department.countryRules.holidays).filter(h => {
            return moment(h).isBetween(_startDate, _endDate, '[]')
        })

        const years = [... new Set(result.map(d => [moment(d).startOf('isoWeek').year(), moment(d).endOf('isoWeek').year()]).flat())];
        for(const year of years) {
            const christmasEve = moment(`${year}-12-24`);
            const newYearsEve = moment(`${year}-12-31`);
            if(christmasEve.isBetween(_startDate, _endDate, '[]')) result.push(christmasEve.format('YYYY-MM-DD'));
            if(newYearsEve.isBetween(_startDate, _endDate, '[]')) result.push(newYearsEve.format('YYYY-MM-DD'));
        }

        result = patternCase === PatternCase.WEEKEND_PATTERN ? appendDaysToHolidays(result, [0, 6]) : result;
        result.sort((a, b) => moment(a) - moment(b));
        setHolidays(result);
        return result;
    }

    /**
     * Method for appending certain days to a list of holidays.
     * Example:
     *  If there exists a holiday on a sunday, and dayIndexes includes 1 (Monday), then Monday on the same week as the Sunday
     *  will be appended to the holiday list
     * @param hol - list of holiday dates
     * @param dayIndexes - day indexes to be added (0 is Sunday)
     * @returns {*}
     */
    const appendDaysToHolidays = (hol, dayIndexes) => {
        hol.forEach(h => {
            const _h = moment(h);
            if(dayIndexes.includes(_h.day())) {
                const sun = _h.clone().endOf('isoWeek');
                if(!hol.includes(sun.format('YYYY-MM-DD'))) {
                    hol.push(sun.format('YYYY-MM-DD'))
                }
            }
        })
        return hol;
    }

    /**
     * Function for creating a select box for side loading a task
     * @param handleSelectTaskChange
     * @returns {JSX.Element}
     */
    const taskSelect = (handleSelectTaskChange) => {
        return (
            <>
                <Select
                    id="selectTask"
                    data-testid="selectTask"
                    placeholder="Velg bemanningsplan"
                    styles={{
                        control: (base) => ({
                            ...base,
                            border: selectedTask ? "" : "8px solid rgba(235, 8, 0, 0.17)",
                            marginTop: selectedTask ? "" : "-8px"
                        }),
                        container: base => ({
                            ...base,
                            width: "280px",
                            zIndex: 11,
                            marginLeft: "20px",
                        }),
                    }}
                    isSearchable={false}
                    value={selectedTaskOption}
                    options={tasks.map(t => ({value: t.id, label: t.name}))}
                    onChange={handleSelectTaskChange}
                    isClearable={true}
                    data-for="selectTask"
                    data-tip="Se planleggeren i kontekst av en bemanningsplan"
                >
                </Select>
                <Tooltip id="selectTask" />
            </>
        )
    }

    const searchDebounceTimeout = useRef(null);
    const handleSearchChange = (e) => {
        if (searchDebounceTimeout.current) {
            clearTimeout(searchDebounceTimeout.current);
        }
        searchDebounceTimeout.current = setTimeout(() => {
            setSearchFilters(e);
        }, 300);
    };

    const routerPrompt = (isDirty) => (<RouterPrompt when={isDirty} title="Er du sikker på at du vil forlate siden?" onOK={() => true} onCancel={() => false}/>)

    /**
     * Search bar component
     * @returns {JSX.Element}
     */
    const searchBar = () => {
        return (
            <>
                <Flex style={{marginLeft: "20px"}}>
                    <Tooltip id="employee-search" />
                    <Input
                        data-for="employee-search"
                        data-tip="Søk på ansatt"
                        placeholder="Søk på ansatt"
                        style={{width: "160px", borderColor: "hsl(0, 0%, 80%)"}}
                        onChange={(e) => handleSearchChange({...searchFilters, employee: e.target.value})} />
                </Flex>
                <Tooltip id="position-search" />
                <Flex
                    data-for="position-search"
                    data-tip="Søk på stilling"
                    style={{marginLeft: "20px"}}>
                    <Select
                        placeholder="Søk på stilling"
                        options={positions.map(p => ({label: p.name, value: p.id}))}
                        style={{width: "160px", maxWidth: "160px", zIndex: "1000"}}
                        isMulti={true}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                width: '160px',
                                maxWidth: '160px',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 2000,
                            }),
                            multiValue: (provided) => ({
                                ...provided,
                                maxWidth: 'calc(100% - 5px)',
                            }),
                        }}
                        onChange={(e) => handleSearchChange({...searchFilters, positions:  e.map(p => p.value)})}
                    />
                </Flex>
                <Tooltip id="trait-search" />
                <Flex
                    data-for="trait-search"
                    data-tip="Søk på kompetanse"
                    style={{marginLeft: "20px"}}>
                    <Select
                        placeholder="Søk på kompetanse"
                        options={traits.map(p => ({label: p.name, value: p.id}))}
                        style={{width: "200px", maxWidth: "200px", zIndex: "1000"}}
                        isMulti={true}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                width: '200px',
                                maxWidth: '200px',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 2000,
                            }),
                            multiValue: (provided) => ({
                                ...provided,
                                maxWidth: 'calc(100% - 5px)',
                            }),
                        }}
                        onChange={(e) => handleSearchChange({...searchFilters, traits:  e.map(p => p.value)})}
                    />
                </Flex>
                <Tooltip id="daySegment-search" />
                <Flex
                    data-for="daySegment-search"
                    data-tip="Søk på vakttype"
                    style={{marginLeft: "20px"}}>
                    <Select
                        placeholder="Søk på vakttype"
                        options={["D", "A", "N", "L"].map(ds => ({label: ds, value: ds}))}
                        style={{width: "170px", maxWidth: "170px", zIndex: "1000"}}
                        isMulti={true}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                width: '170px',
                                maxWidth: '170px',
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 2000,
                            }),
                            multiValue: (provided) => ({
                                ...provided,
                                maxWidth: 'calc(100% - 20px)',
                            }),
                        }}
                        onChange={(e) => handleSearchChange({...searchFilters, daySegments:  e.map(p => p.value)})}
                    />
                </Flex>
                <Tooltip id="limit-search" />
                <Flex data-for="limit-search"
                      data-tip={patternCaseSearchFilterInfo[patternCase].limitSearchTooltip}
                      style={{marginLeft: "20px"}}>
                    <Checkbox
                        mb="10px"
                        toggle
                        reverse
                        fill
                        label="Avgrens"
                        onChange={(e) => handleSearchChange({...searchFilters, showOnlyWithPatterns: e.target.checked})}
                    />
                </Flex>
            </>
        )
    }

    /**
     * Method for applying search filters to a list of employees
     * @param employees
     * @param visibleStartDate
     * @param visibleEndDate
     * @returns {*}
     */
    const applySearchFiltersToEmployees = (employees, visibleStartDate, visibleEndDate) => {
        const _startDate = moment(selectedTask ? startDate : (visibleStartDate ?? startDate)).format('YYYY-MM-DD');
        const _defaultEnddate = moment(startDate).add(nrOfWeeks, 'weeks').subtract(1, 'day');
        const _endDate = moment(selectedTask ? _defaultEnddate : visibleEndDate ?? _defaultEnddate);
        const holidaysWithWeekendAddition = appendDaysToHolidays(holidays.filter(h => moment(h).isBetween(moment(_startDate), moment(_endDate))), [0, 6]);
        return employees
            .filter(e => {
                const nameOk = searchFilters.employee === undefined
                    || searchFilters.employee.trim() === ""
                    || e.name.toLowerCase().includes(searchFilters.employee.toLowerCase());

                const positionOk = searchFilters.positions === undefined
                    || searchFilters.positions.length === 0
                    || searchFilters.positions.includes(e.position)

                const traitOk = searchFilters.traits === undefined
                    || searchFilters.traits.length === 0
                    || searchFilters.traits.some(t => e.traits.includes(t))

                const daySegmentsOk = searchFilters.daySegments === undefined
                    || searchFilters.daySegments.length === 0
                    || searchFilters.daySegments.some(ds => e.restShiftTypes.map(sh => sh.daySegment).includes(ds))

                let patternsOk = searchFilters.showOnlyWithPatterns === undefined
                    || searchFilters.showOnlyWithPatterns === false
                    || e[patternCase].some(p => patternCaseSearchFilterInfo[patternCase].kinds.includes(p.kind) && p.delete !== true && getDaysFromDatePattern(dynomite, p, _startDate, _endDate).length > 0)

                if(patternsOk && searchFilters.showOnlyWithPatterns === true && patternCase === PatternCase.WEEKEND_PATTERN) {
                    const workingDays = (e[patternCase] ?? []).length > 0 ? getDaysFromDatePatterns(dynomite, e[patternCase], _startDate, _endDate, ['working']) : [];
                    const notWorkingDays = (e[patternCase] ?? []).length > 0 ? getDaysFromDatePatterns(dynomite, e[patternCase], _startDate, _endDate, ['notWorking']) : [];
                    const vacations = (e[PatternCase.VACATION] ?? []).length > 0 ? getDaysFromDatePatterns(dynomite, e[PatternCase.VACATION], _startDate, _endDate, ['vacation']) : []
                    const paidLeave = (e[PatternCase.PAID_LEAVE_PATTERN] ?? []).length > 0 ? getDaysFromDatePatterns(dynomite, e[PatternCase.PAID_LEAVE_PATTERN], _startDate, _endDate, ['paidLeave']) : []
                    patternsOk = workingDays.filter(d => !notWorkingDays.includes(d) && !vacations.includes(d) && !paidLeave.includes(d)).length > 0;
                }
                else if (patternCase === PatternCase.HOLIDAY_PATTERN && searchFilters.showOnlyWithPatterns === true) {
                    const vacations = (e[PatternCase.VACATION] ?? []).length > 0 ? getDaysFromDatePatterns(dynomite, e[PatternCase.VACATION], _startDate, _endDate, ['vacation']) : [];
                    const paidLeave = (e[PatternCase.PAID_LEAVE_PATTERN] ?? []).length > 0 ? getDaysFromDatePatterns(dynomite, e[PatternCase.PAID_LEAVE_PATTERN], _startDate, _endDate, ['paidLeave']) : []
                    const weekends = (e[PatternCase.WEEKEND_PATTERN] ?? []).length > 0 ? getDaysFromDatePatterns(dynomite, e[PatternCase.WEEKEND_PATTERN], _startDate, _endDate, ['working']) : [];
                    const blockedWeekends = (e[PatternCase.WEEKEND_PATTERN] ?? []).length > 0 ? getDaysFromDatePatterns(dynomite, e[PatternCase.WEEKEND_PATTERN], _startDate, _endDate, ['notWorking']) : [];
                    const workingHolidays = (e[PatternCase.HOLIDAY_PATTERN] ?? []).length > 0 ? getDaysFromDatePatterns(dynomite, e[PatternCase.HOLIDAY_PATTERN], _startDate, _endDate, ['working']) : [];
                    patternsOk = [...workingHolidays, ...weekends].some(d => holidaysWithWeekendAddition.includes(d) && !blockedWeekends.includes(d) && !vacations.includes(d) && !paidLeave.includes(d))
                }

                return nameOk && positionOk && traitOk && daySegmentsOk && patternsOk;
            })
    }

    /**
     * Method for getting min and max requirements from weeklyCoverDemands for a certain weekly cover demands key.
     * Returns the following structure:
     * {
     *  <date>:
     *      <daySegment>: {
     *          min: <min>,
     *          max: <max>
     *      }
     * }
     * @param task
     * @param dates
     * @param weeklyCoverDemandsKey
     * @returns {*}
     */
    const getRequirementsFromTask = (task, dates, weeklyCoverDemandsKey) => {
        const initObject = {
            "D": {min: 0, max: 0},
            "A": {min: 0, max: 0},
            "L": {min: 0, max: 0},
            "N": {min: 0, max: 0}
        }
        const sumReq = (weeklyDayEntry) => {
            let result = _.cloneDeep(initObject);
            Object.entries(weeklyDayEntry).forEach(([shiftCode, { minimumNrOfEmployees, maximumNrOfEmployees }]) => {
                const shiftType = shiftTypes.find(shift => shift.code === shiftCode);
                if (shiftType) {
                    const segment = shiftType.daySegment;
                    result[segment].min += minimumNrOfEmployees;
                    result[segment].max += maximumNrOfEmployees;
                }
            });
            return result;
        }

        const considerHolidayWeekendIsWeekendInBPRule =
            weeklyCoverDemandsKey === "HOLIDAYS"
            && task.config.version !== undefined
            && task.config.version !== "VERSION1";

        const defaultReq = sumReq(task.config.weeklyCoverDemands[weeklyCoverDemandsKey]);
        return dates.reduce((obj, s ) => {
            const date = moment(s);
            const _key = (
                considerHolidayWeekendIsWeekendInBPRule && [6,7].includes(date.isoWeekday())
                || (date.month() === 11 && ([24, 31].includes(date.date())))
            )
                ? date.format("dddd").toUpperCase()
                : weeklyCoverDemandsKey;
            const formatted = date.format('YYYY-MM-DD');
            if(Object.keys(task.config.dailyCoverPatches).includes(formatted)) {
                const descs = _.cloneDeep(task.config.weeklyCoverDemands[_key]);
                applyPatch(descs, task.config.dailyCoverPatches[formatted]);
                return {...obj, [formatted]: sumReq(descs)};
            }
            return {...obj, [formatted]: patternCase !== PatternCase.HOLIDAY_PATTERN || _key === 'HOLIDAYS' ? defaultReq : sumReq(task.config.weeklyCoverDemands[_key])};
        }, {});
    }

    /**
     * Function for getting a modal which displays the save progress
     * @returns {JSX.Element}
     */
    const saveModal = () => {
        return (
            <Modal
                title="Lagring pågår"
                cancelButtonProps={{
                    style: { display: "none" }
                }}
                primaryButtonProps={{
                    style: { display: "none" }
                }}>
                <ProgressBar value={(100 * showSavingModal.saveCount) / showSavingModal.toSave} />
                <p>{showSavingModal.saveCount}/{showSavingModal.toSave} {showSavingModal.employee ? (" (Lagrer " + showSavingModal.employee + ")") : ""}</p>
            </Modal>
        )
    }

    /**
     * Method for checking if a pattern has a day within a period
     * @param pattern
     * @param startDay
     * @param endDay
     * @returns {boolean}
     */
    const patternWithinPeriod = (pattern, startDay, endDay) => {
        return dynomite.dynomite.daysFromDatePattern(
            getDatePattern(dynomite, pattern),
            moment(startDay).format('YYYY-MM-DD'),
            moment(endDay).format('YYYY-MM-DD'),
            ['D', 'A', 'L', 'N']
        ).length > 0;
    }

    /**
     * Method for resetting common variables/states shared between the different absence modules
     */
    const reset = () => {
        const start = moment().add(1, 'weeks').startOf('isoWeek');
        const weeks = 157;
        setStartDate(start);
        setNrOfWeeks(weeks);
        processAndSetHolidays(department, start, weeks)
        setSelectedTask(undefined);
        setSelectedTaskOption(undefined);
        setRequirements(new Map());
        setViewFlipSize(2);
        setViewStartIndex(0);
        setShowSavingModal({ display: false, saveCount: 0, toSave: 0, employee: null });
        setSomePatternCaseIsDirty(false);
        setEmployeesWithAvailabilities(employees);
    }

    /**
     * Method for getting react grid rows based on employees
     * @param columns
     * @param employees
     * @returns {*}
     */
    const getReactGridRows = (columns, employees, visibleStartDate, visibleEndate) => applySearchFiltersToEmployees(employees, visibleStartDate, visibleEndate).map((employee, idx) => ({
        rowId: employee.id,
        height: 30,
        cells: columns.map(colDesc => colDesc.item({ idx: idx, employee: employee })),
        _employee: employee,
    }));

    /**
     * Method for getting react grid header row
     * @param columns
     * @param rowId
     * @param field
     * @param height
     * @returns {{cells: *, rowId, height: number}}
     */
    const getReactGridHeaderRow = (columns, rowId, field, height=30) => ({
        rowId: rowId,
        height: height,
        cells: columns.map(colDesc => ({...colDesc, type: "header", text: `${colDesc[field]}` })),
    })

    /**
     * Method which returns a structure saying which employees have patterns of a certain case on some dates. Examples response:
     * {
     *     '2024-01-01': {
     *         'employeeId1': true,
     *         'employeeId2': false
     *     }
     * }
     * @param days
     * @param pCase
     * @returns {*}
     */
    const getPatternsForDaysAndEmployees = (days, pCase) => {
        return days.reduce((acc, day) => {
            const formattedDay = moment(day).format('YYYY-MM-DD')
            acc[formattedDay] = employees.reduce((employeeAcc, employee) => {
                employeeAcc[employee.id] = employee[pCase].some(p => getDaysFromDatePattern(dynomite, p, day, day, false).length > 0)
                return employeeAcc;
            }, {});
            return acc;
        }, {});
    }

    /**
     * Method for checking if a certain day in within the series period any pattern of a certain case
     * @param employee
     * @param day
     * @returns {boolean|*}
     */
    const dayWithinSeries = (employee, day, pc = patternCase) => {
        const patterns = employee[pc].filter(p => p.delete !== true);
        if(patterns.length === 0) return false;
        return patterns.some(p =>
            p.series?.from && moment(p.series.from).diff(moment(day)) <= 0 &&
            (!p.series?.until || moment(p.series.until).diff(moment(day)) >= 0)
        )
    }

    /**
     * Method for resolving nr of weeks of a certain pattern case for an employee
     * @param employee
     * @param excludes - list of patternCases
     * @returns {number|number}
     */
    const resolveEmployeePatternCaseWeeks = (employee, excludes = []) => {
        if(!employee) return 0;
        const endDate = selectedTask
            ? moment(startDate).clone().add(nrOfWeeks, 'weeks').subtract(1, 'day')
            : moment(startDate).clone().endOf('year');
        const patterns = employee[patternCase].filter(p => p.delete !== true);
        const excludeDates = (excludes ?? []).length > 0
            ? getDaysFromDatePatterns(dynomite, excludes.map(e => employee[e]).flat(), startDate, endDate, excludes.map(pc => patternCaseSearchFilterInfo[pc]?.dynomiteKinds).flat())
            : []
        return patterns.length === 0
            ? 0
            : getDaysFromDatePatterns(dynomite, patterns, startDate, endDate, patternCaseSearchFilterInfo[patternCase].dynomiteKinds)
                .filter(d => !excludeDates.includes(d))
                .length / 7;
    }

    /**
     * Function for merging patterns that proceed each other
     * @param patterns
     * @param summaryPrefix
     * @returns {*}
     */
    const mergePatterns = (patterns, summaryPrefix) => {
        const patternsToKeep = patterns.filter(p => p.delete !== true);
        const patternsToDelete = patterns.filter(p => p.delete === true);

        patternsToKeep.sort((a, b) => moment(a.series.from).diff(moment(b.series.from)));

        const mergedPatterns = patternsToKeep.reduce((acc, current) => {
            const last = acc[acc.length - 1];
            if (last && moment(current.series.from).diff(moment(last.series.until), 'days') === 1) {
                last.series.until = current.series.until;
                last.summary = summaryPrefix + " " + last.series.from + " - " + last.series.until
                last.update = true;
                if(!current.id?.includes(placeholderId)) patternsToDelete.push({...current, delete: true})
            } else {
                acc.push(current);
            }
            return acc;
        }, []);
        return mergedPatterns.concat(patternsToDelete);
    }

    /**
     * Function for removing and/or splitting patterns. Scenarios:
     * 1. Single standalone week is ticked off
     * 2. Week at the start of a period is removed
     *      - series.from in the pattern is moved one week ahead
     * 3. Week at the end of a period is removed
     *      - series.until in the pattern is moved one week back
     * 4. Week in the middle of a period is removed
     *      - the pattern is split into two patterns:
     *          - first pattern has series.from set to the initial patterns from date and series.until set to Sunday before the removed week
     *          - second pattern has series.from set to Monday week after the removed patterns week and series.until set to the initial patterns until
     * @param patterns
     * @param monday
     * @param summaryPrefix
     * @returns {*}
     */
    const removeOrSplitFullWeekPatterns = (patterns, monday, summaryPrefix) => {
        const patternsToKeep = patterns.filter(p => p.delete !== true);
        const patternsMarkedAsDelete = patterns.filter(p => p.delete === true)
        const sunday = moment(monday).endOf('isoWeek');
        return patternsToKeep
            .reduce((acc, current) => {
                if (!patternWithinPeriod(current, monday, monday)) {
                    acc.push(current);
                    return acc;
                } else if (current.delete === true) {
                    return acc;
                }
                const days = getDaysFromDatePattern(
                    dynomite,
                    current,
                    moment(current.series.from),
                    moment(current.series.until)
                );
                if (days.length === 7) {
                    acc.push({...current, delete: true})
                } else {
                    if (moment(current.series.from).isSame(moment(monday))) {
                        const pattern = {
                            ...current,
                            update: true,
                            series: {...current.series, from: moment(monday).add(1, 'weeks').format('YYYY-MM-DD')}
                        }
                        pattern.summary = summaryPrefix + " " + pattern.series.from + " - " + pattern.series.until;
                        acc.push(pattern)
                    } else if (moment(current.series.until).diff(sunday, 'days') === 0) {
                        const pattern = {
                            ...current,
                            update: true,
                            series: {...current.series, until: moment(sunday).subtract(1, 'weeks').format('YYYY-MM-DD')}
                        };
                        pattern.summary = summaryPrefix + " " + pattern.series.from + " - " + pattern.series.until;
                        acc.push(pattern)
                    } else {
                        const pattern = {
                            ...current,
                            update: true,
                            series: {...current.series, until: moment(monday).subtract(1, 'days').format('YYYY-MM-DD')}
                        }
                        pattern.summary = summaryPrefix + " " + pattern.series.from + " - " + pattern.series.until;
                        acc.push(pattern)
                        const pattern2 = {
                            ...current,
                            id: placeholderId,
                            series: {...current.series, from: moment(monday).add(1, 'weeks').format('YYYY-MM-DD')}
                        };
                        pattern2.summary = summaryPrefix + " " + pattern2.series.from + " - " + pattern2.series.until;
                        acc.push(pattern2)
                    }
                }

                return acc;
            }, []).concat(patternsMarkedAsDelete);
    }

    /**
     * Method for posting/putting/deleting patterns
     * @param toSave (employees to save)
     * @returns {Promise<void>}
     */
    const doSave = async (toSave) => {
        setShowSavingModal({ display: true, saveCount: 0, toSave: toSave.length });
        for(const e of toSave) {
            setShowSavingModal({ display: true, saveCount: showSavingModal.saveCount++, toSave: toSave.length, employee: e.name});
            for (const pattern of e[patternCase]) {
                const del = pattern.delete === true;
                const update = pattern.update === true;
                const id = pattern.id;
                ['id', 'registered', 'updated', 'delete', 'update'].forEach(field => delete pattern[field])

                if(!id.includes(placeholderId)) {
                    if(del) {
                        await dynoapeAPI.delete(`/api/v1/department/${departmentId}/employee/${e.id}/pattern/${id}?type=${patternCase}`);
                    } else {
                        if(update === true) {
                            await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${e.id}/pattern/${id}?type=${patternCase}`, pattern);
                        }
                    }
                } else if(!del && id.includes(placeholderId)) {
                    await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee/${e.id}/pattern?type=${patternCase}`, pattern);
                }
            }
        }
        setShowSavingModal({ display: false, saveCount: 0, toSave: 0, employee: null })
        setEmployeesWithAvailabilities(
            (await dynoapeAPI.get(`/api/v1/department/${departmentId}/employees${PatternCase.getIncludeQueryParams(patternCase)}`))
                .filter(e => e.enabled)
                .sort(function (a, b) {
                    return a.priority === b.priority ? new Date(a.registered) - new Date(b.registered) : a.priority - b.priority
                })
        );
    }

    if(isLoading) {
        return <div><p>Laster...</p></div>
    } else if(employees.length === 0) {
        return <div><p>Vennligst opprett noen ansatte</p></div>
    }

    const commonProps = {
        setSomePatternCaseIsDirty: setSomePatternCaseIsDirty,
        somePatternCaseIsDirty: somePatternCaseIsDirty,
        taskSelect: taskSelect,
        searchBar: searchBar,
        routerPrompt: routerPrompt,
        departmentId: departmentId,
        selectedTaskOption: selectedTaskOption,
        setSelectedTaskOption: setSelectedTaskOption,
        selectedTask: selectedTask,
        setSelectedTask: setSelectedTask,
        setViewFlipSize: setViewFlipSize,
        viewFlipSizeRef: viewFlipSizeRef,
        viewStartIndex: viewStartIndex,
        setViewStartIndex: setViewStartIndex,
        department: department,
        holidays: holidays,
        processAndSetRequirements: processAndSetRequirements,
        processAndSetHolidays: processAndSetHolidays,
        employees: employees,
        setEmployees: setEmployees,
        reset: reset,
        patternWithinPeriod: patternWithinPeriod,
        patternCase: patternCase,
        getReactGridRows: getReactGridRows,
        getReactGridHeaderRow: getReactGridHeaderRow,
        showSavingModal: showSavingModal,
        setShowSavingModal: setShowSavingModal,
        saveModal: saveModal,
        dynomite: dynomite,
        validate: validate,
        configErrors: configErrors,
        configWarnings: configWarnings,
        dynomiteInternalError: dynomiteInternalError,
        startDate: startDate,
        setStartDate: setStartDate,
        nrOfWeeks: nrOfWeeks,
        setNrOfWeeks: setNrOfWeeks,
        getRequirementsFromTask: getRequirementsFromTask,
        findAndSetOpenedDropdownCellLocation: findAndSetOpenedDropdownCellLocation,
        openedDropdownLocation: openedDropdownLocation,
        searchFilters: searchFilters,
        applySearchFiltersToEmployees: applySearchFiltersToEmployees,
        getPatternsForDaysAndEmployees: getPatternsForDaysAndEmployees,
        dayWithinSeries: dayWithinSeries,
        resolveEmployeePatternCaseWeeks: resolveEmployeePatternCaseWeeks,
        placeholderId: placeholderId,
        mergePatterns: mergePatterns,
        removeOrSplitFullWeekPatterns: removeOrSplitFullWeekPatterns,
        doSave: doSave
    }

    if(patternCase === PatternCase.WEEKEND_PATTERN) {
        return <EmployeeDatePatternWeekendPlanner
            {...commonProps}
            employeesAvailabilities={employeesAvailabilities}
            setEmployeesWithAvailabilities={setEmployeesWithAvailabilities}
            requirements={requirements}
            positions={
                (searchFilters.positions === undefined || searchFilters.positions.length === 0)
                    ? positions
                    : positions.filter(p => searchFilters.positions.includes(p.id))
            }
            setSelectedTaskInfo={setSelectedTaskInfo}
        ></EmployeeDatePatternWeekendPlanner>
    } else if(patternCase === PatternCase.BLOCKED_PATTERN) {
        return <EmployeeDatePatternBlockedPatterns 
            departmentId={departmentId}
            employees={employees}
            setEmployees={setEmployees}
            positions={positions}
            traits={traits}
            reset={reset}
        ></EmployeeDatePatternBlockedPatterns>
    } else if(patternCase === PatternCase.VACATION) {
        return <EmployeeDatePatternVacation
            {...commonProps}
            positions={positions}
            traits={traits}
        ></EmployeeDatePatternVacation>
    } else if(patternCase === PatternCase.HOLIDAY_PATTERN) {
        return <EmployeeDatePatternHoliday
            {...commonProps}
            employeesAvailabilities={employeesAvailabilities}
            setEmployeesWithAvailabilities={setEmployeesWithAvailabilities}
            requirements={requirements}
            positions={
                (searchFilters.positions === undefined || searchFilters.positions.length === 0)
                    ? positions
                    : positions.filter(p => searchFilters.positions.includes(p.id))
            }
            setSelectedTaskInfo={setSelectedTaskInfo}
            ></EmployeeDatePatternHoliday>
    } else if(patternCase === PatternCase.PAID_LEAVE_PATTERN) {
        return <EmployeeDatePatternPaidLeave {...commonProps}></EmployeeDatePatternPaidLeave>
    }
}

export const ReactGridWrapper = styled.div`
    max-width: 3080px;
    width: calc(100% - 20px);
    max-height: calc(69vh - 65px);
    overflow: auto;
    background-color: white;
    margin-top: 30px;
`;

export const PlannerWrapper = styled.div`
    width: calc(100% - 20px);
`;