import React, {useState} from "react";
import {Button, Card} from "rendition";

/**
 * Functional component accessible at path /auth0/entra-link-account-consent. The page is used by an executable
 * action (for linking entra users and auth0 users with the same email) inside auth0. The user flow is the following:
 * - User logs in with Entra id
 * - User is asked to verify their email
 * - Dynamons custom action in auth0 for linking users is executed.
 *      - If an auth0 user with the same email exists, user is redirected to "/auth0/entra-link-account-consent" to consent to account linking
 *      - If user consents, user is redirected back to auth0 with queryParam consent=true
 *      - If user doesnt consent, user is redirected back to auth0 with queryParam consent=false
 *
 * The component reads two query params; state and expire. State is a unique token which will be sent back to auth0 (for authorization) during redirection.
 * Expire is a date in milliseconds which is when the consent link expires. This value is used by the portal to decide which information
 * to be displayed (either consent form or expired link message). This can however be bypassed by changing the query param in the url. This
 * is why the actual value is stored on the user and the actual validation happens server side inside the auth0 action.
 * If the user is too slow consenting, auth0 will reject it.
 *
 * Please see dynopedia for more information!
 * @returns {JSX.Element}
 * @constructor
 */
const LinkUserConsent = () => {
    const [loading, setLoading] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const state = searchParams.get("state");
    const expire = parseInt(searchParams.get("expire"));
    const isExpired = () => isNaN(expire) || expire < Date.now();
    const [error, setError] = useState(isExpired() || !state);

    const handleConsent = async (consent) => {
        if(isExpired()) {
            setError(true)
            return;
        }
        setLoading(true);
        try {
            window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/continue?state=${encodeURIComponent(state)}&consent=${consent ? "true" : "false"}`;
        } catch (err) {
            setError("Failed to process consent. Please try again.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="content">
            <Card style={{ width: "1400px", borderTopLeftRadius: "0px" }}>
                <h3>Samtykke kreves</h3>
                <div style={{fontFamily: "Montserrat Alternates", fontSize: "18px"}}>
                    {
                        error ? (<p>Linken er utløpt.</p>)
                            :
                            <>
                                <p>
                                    Du er i ferd med å logge inn i Dynamon med Entra ID. Vi har samlet inn brukerinformasjonen din
                                    og ser at du allerede har en bruker hos oss. For å kunne fortsette, må du samtykke til at vi
                                    slår sammen kontoene dine til èn. Samtykker du ikke, sletter vi den nye brukeren din. Du vil fortsatt
                                    kunne logge inn med den eksisterende kontoen din.
                                </p>
                                <Button
                                    style={{
                                        padding: "10px 20px",
                                        backgroundColor: "#28a745",
                                        color: "#fff",
                                        border: "none",
                                        cursor: loading ? "not-allowed" : "pointer",
                                    }}
                                    onClick={() => handleConsent(true)}
                                    disabled={loading}
                                >
                                    Ja, jeg samtykker
                                </Button>
                                <Button
                                    style={{
                                        padding: "10px 20px",
                                        backgroundColor: "#dc3545",
                                        color: "#fff",
                                        border: "none",
                                        margin: "0 10px",
                                        cursor: loading ? "not-allowed" : "pointer",
                                    }}
                                    onClick={() => handleConsent(false)}
                                    disabled={loading}
                                >
                                    Nei, jeg samtykker ikke
                                </Button>
                            </>
                    }
                </div>
            </Card>
        </div>
    );
};
export default LinkUserConsent;