import React, {useEffect, useMemo, useState} from "react";
import moment from "moment/moment";
import {
    parseISO,
    getISODay,
    getISOWeek,
    setISOWeek,
    startOfISOWeek
} from 'date-fns';
import {
    rollupAndReduce,
    getDaysFromDatePatterns,
    fullSchemeYlOrRd,
    dynomiteErrorPanel,
    monthsShort,
    scrollHeight,
    shortenStr
} from "../../lib/common";
import {PaginationJumper} from "../../lib/styled-components";
import {ReactGrid} from "@silevis/reactgrid";
import {CheckboxCellTemplate} from "../grid-components/checkbox-cell-template";
import { Button, Box, Txt } from "rendition";
import Tooltip from "../../lib/tooltip";
import {PlannerWrapper, ReactGridWrapper} from "./employee-date-pattern-cases";
import {PatternCase} from "../../lib/pattern-case";
import {DisabledInfoCellTemplate} from "../grid-components/disabled-info-cell-template";
import CalendarHeatmap from "../vis-components/calendar-heatmap";
import EmployeeFilter from "./employee-filter";
import defaultTheme from "../../themes/light-theme";

export default ({
    somePatternCaseIsDirty,
    setSomePatternCaseIsDirty,
    employees,
    patternWithinPeriod,
    reset,
    getReactGridRows,
    getReactGridHeaderRow,
    showSavingModal, saveModal,
    dynomite, validate, configErrors, configWarnings, dynomiteInternalError,
    routerPrompt,
    dayWithinSeries,
    placeholderId,
    mergePatterns,
    removeOrSplitFullWeekPatterns, doSave,
    getPatternsForDaysAndEmployees,
    positions,
    traits
}) => {
    const [toSave, setToSave] = useState([]);
    const [columns, setColumns] = useState(undefined);
    const [searchFilters, setSearchFilters] = useState({
        employee: undefined,
        positions: [],
        traits: [],
        daySegments: []
    });
    const [visYear, setVisYear] = useState(new Date().getFullYear());
    const [visWeeks, setVisWeeks] = useState(1);

    const filteredEmployees = useMemo(
        () => employees
            .map(e => toSave.find(se => se.id === e.id) || e)
            .filter(e => 
                !searchFilters.employee
                || e.name.toLowerCase().includes(searchFilters.employee.toLowerCase())
            )
            .filter(e =>
                searchFilters.positions.length === 0
                || searchFilters.positions.includes(e.position)
            )
            .filter(e =>
                searchFilters.traits.length === 0
                ||  searchFilters.traits.some(t => e.traits.includes(t))
            )
            .filter(e =>
                searchFilters.daySegments.length === 0
                || searchFilters.daySegments.some(ds =>
                    e.restShiftTypes.map(sh => sh.daySegment.toLowerCase()).includes(ds)
                )
            ),
        [searchFilters, toSave]
    );

    const nrInView = 12;
    const mondays = [...Array(nrInView).keys()].map(
        (i) => moment(startOfISOWeek(setISOWeek(new Date(visYear, 0, 4), visWeeks + i)))
    );
    const emplPaidLeaves = getPatternsForDaysAndEmployees(
        mondays, PatternCase.PAID_LEAVE_PATTERN
    );

    /**
     * Method for creating a new vacation week pattern.
     * Period is set to from an input Monday until Sunday the same week
     * @param monday
     * @returns {*}
     */
    const vacationPattern = (monday) => {
        const start = moment(monday).startOf('isoWeek').format('YYYY-MM-DD');
        const end = moment(monday).endOf('isoWeek').format('YYYY-MM-DD');
        return {
            id: placeholderId,
            delete: false,
            summary: "Ferie " + start + " - " + end,
            series: {
                toggled: true,
                from: start,
                until: end,
                nth: 1,
                mask: [true, true, true, true, true, true, true]
            },
            includeDays: [],
            excludeDays: [],
            daySegments: ["D", "A", "N", "L"],
            disabled: false,
            optimize: false,
            kind: "vacation"
        }
    }

    useEffect(() => {
        scrollHeight();
        return () => {
            reset();
        }
    }, []);

    useEffect(() => {
        if((somePatternCaseIsDirty && toSave.length > 0) || (!somePatternCaseIsDirty && toSave.length === 0)) return;
        setSomePatternCaseIsDirty(toSave.length > 0);
    }, [toSave])

    useEffect(() => {
        validate({
            employeesParam: filteredEmployees.map(e => getLocalOrGlobalEmployee(e.id))
        });
    }, [toSave, employees, searchFilters])

    /**
     * Helper function for getting employee data. If there are unsaved employee data, we fetch it from local storage.
     * If not, the employee is returned
     * @param employeeId
     * @returns {*}
     */
    const getLocalOrGlobalEmployee = (employeeId) => 
        toSave.find(e => e.id === employeeId)
        || employees.find(e => e.id === employeeId);
    

    /**
     * Returns true if employee with employeeId is on vacation on a given week
     * set by monday. False if not.
     * 
     * @param {string} employeeId
     * @param {string} monday
     * @returns {boolean}
     */
    const isOnVacation = (employeeId, monday) =>
        !dayWithinSeries(getLocalOrGlobalEmployee(employeeId), monday, PatternCase.PAID_LEAVE_PATTERN)
        && dayWithinSeries(getLocalOrGlobalEmployee(employeeId), monday);

    /**
     * Returns true if employee with employeeId is on paidLeage on a given week
     * set by dateId (string of YYYY-MM-DD). False if not.
     * 
     * @param {string} monday
     * @param {string} employeeId
     * @returns {boolean}
     */
    const isOnPaidLeave = (dateId, employeeId) => 
        emplPaidLeaves[dateId] !== undefined
        && emplPaidLeaves[dateId][employeeId] === true;

    /**
     * Method for getting the number of vacation weeks a certain employee has
     * in a component period (year + startISOWeek + 12 weeks)
     * 
     * Excluding PaidLeave dates.
     * 
     * @param employeeId
     * @returns {number}
     */
    const resolveNrOfVacationWeeks = (employeeId) => {
        return getDaysFromDatePatterns(
            dynomite,
            getLocalOrGlobalEmployee(employeeId).vacationPatterns.filter(vac => !vac.delete),
            moment(`${visYear}-01-01`),
            moment(`${visYear}-12-31`),
            ["vacation"],
        )
        // Get Mondays
        .filter(vac_str => getISODay(parseISO(vac_str, {representation: 'date'})) === 1)
        .filter(vac_str => !isOnPaidLeave(vac_str, employeeId)).length;
    }

    /**
     * UseEffect for generating the columns in the vacation planner. The following columns are generated:
     * - Row nr
     * - Employee name
     * - Total number of vacation weeks for each employee in the given period
     * - One column per week in the given period
     * 
     * TODO: I'm not sure if we need to use useEffect. Maybe useMemo would suffice more.
     */
    useEffect(() => {
        if(!mondays) return;
        const cols =
            [
                { columnId: "nr", included: true, width: 40, text: "Rad", item: ({ idx }) => ({ type: "header", text: `${idx + 1}` }), total: "" },
                { columnId: "name", included: true, width: 260, text: "Navn", item: ({ employee }) => ({ type: "header", text: `${shortenStr(employee.name, 30)}` }), total: "Antall på ferie"},
                { columnId: "nrOfVacationWeeks", included: true, width: 80, text: "Antall ferieuker", item: ({ employee }) => ({ type: "header", text: `${resolveNrOfVacationWeeks(employee.id)}` }), total: "" },
            ]
                .concat(mondays.map(d => {
                    // I believe we have to go FULL ISO here.
                    // in that case we use Thursday (that is always on a correct
                    // isoweek/year).
                    const dateId = d.format("YYYY-MM-DD");
                    const weekThursday = d.add(3, 'days');
                    const text = `Uke ${weekThursday.isoWeek()} \n ${weekThursday.year()} ${monthsShort[weekThursday.month()]}`
                    return {
                        columnId: dateId,
                        included: true,
                        width: 100, text: text,
                        item: ({ employee }) => isOnPaidLeave(dateId, employee.id)
                            ? { type: 'empty-blue', text: 'P', nonEditable: true}
                            : { type: 'checkbox', checked: isOnVacation(employee.id, d), checkedText: 'Ja', uncheckedText: 'Nei' },
                        total: filteredEmployees.reduce((count, e) => count + (isOnVacation(e.id, d) ? 1 : 0), 0).toString()
                    }
                }))
        setColumns(cols)
    }, [employees, toSave, searchFilters, visYear, visWeeks])

    /**
     * Method for handling the changes inside the vacation planner (react grid)
     * @param changes
     */
    const handleChanges = (changes) => {
        let localToSave = toSave;
        changes.forEach(ch => {
            const employee = (localToSave.find(e => e.id === ch.rowId) || employees.find(e => e.id === ch.rowId));
            const date = moment(ch.columnId).format('YYYY-MM-DD');
            let patterns = (employee.vacationPatterns || []);

            if (ch.newCell.checked === true) {
                const flaggedAsDelete = patterns.find(p => p.delete === true && patternWithinPeriod(p, date, date));
                const pattern = flaggedAsDelete ? {...flaggedAsDelete, delete: false} : vacationPattern(date);
                patterns = mergePatterns([...patterns.filter(p => p.id !== flaggedAsDelete?.id), pattern], "Ferie");
            } else {
                patterns = removeOrSplitFullWeekPatterns(patterns, date, "Ferie")
            }
            employee.vacationPatterns = patterns;
            localToSave = [...localToSave.filter(e => e.id !== employee.id), {...employee, vacationPatterns: patterns}]
        })
        setToSave(toSave.filter(e => !localToSave.find(le => le.id === e.id)).concat(localToSave))
    }

    /**
     * Method for posting/putting/deleting vacation patterns
     * @returns {Promise<void>}
     */
    const save = async () => {
        await doSave(toSave)
        setToSave([]);
    }

    return (
        <PlannerWrapper>
            <EmployeeFilter
                // Manually calculated from the columns
                width='1580px'
                name={{
                    value: searchFilters.employee,
                    width: '400px',
                    onChange: name => setSearchFilters({
                        ...searchFilters,
                        employee: name
                    })
                }}
                positions={{
                    value: positions,
                    width: '300px',
                    isMulti: true,
                    onChange: positions => setSearchFilters({
                        ...searchFilters,
                        positions: positions
                    }) 
                }}
                traits={{
                    value: traits,
                    width: '300px',
                    onChange: traits => setSearchFilters({
                        ...searchFilters,
                        traits: traits
                    })
                }}
                daySegments={{
                    value: searchFilters.daySegments,
                    tooltip: 'Knappene under filtrerer visning av ansatte som \
                        har tildelt de aktuelle vaktypene.',
                    onChange: ds => setSearchFilters({
                        ...searchFilters,
                        daySegments: searchFilters.daySegments.includes(ds) ?
                            searchFilters.daySegments.filter(el => el !== ds) :
                            [...searchFilters.daySegments, ds]
                    })
                }}
                customComponents={[
                    <Box
                        key='vacation-planner-vis-year-jumper'
                        style={{ flex: '0 0 95px'}}
                    >
                        <Txt style={{ marginBottom: '1.25em' }}
                            data-for='vacation-planner-vis-year'
                            data-tip='Her kan du navigere frem eller tilbake 
                                for å vise året du ønsker å se.'
                        >Se år</Txt>
                        <Tooltip id='vacation-planner-vis-year' />
                        <PaginationJumper
                            value={visYear}
                            onLeftChange={(value) => {
                                setVisYear(value - 1);
                                setVisWeeks(1);
                            }}
                            onRightChange={(value) => {
                                setVisYear(value + 1);
                                setVisWeeks(1);
                            }}
                        />
                    </Box>,
                    <Box
                        key='vacation-planner-vis-weeks-jumper'
                        style={{ flex: '0 0 115px'}}
                    >
                        <Txt style={{ marginBottom: '1.25em' }}
                            data-for='vacation-planner-vis-weeks'
                            data-tip='Her kan du navigere 12 uker frem eller
                                tilbake. I overgangen mellom år vil den stoppe
                                på siste uken av året. '
                        >Se uker</Txt>
                        <Tooltip id='vacation-planner-vis-weeks' />
                        <PaginationJumper
                            value={`
                                ${visWeeks.toString().padStart(2, '0')}
                                -
                                ${visWeeks + nrInView - 1}`
                            }
                            onLeftChange={() => {
                                // When we hit beginning of the year again, we 
                                // jump to the end of the previous year
                                const prevYear = visWeeks === 1;
                                setVisWeeks(
                                    prevYear
                                    ? Math.max(getISOWeek(new Date(visYear - 1, 11, 28)), 52) - nrInView + 1
                                    : Math.max(visWeeks - nrInView, 1)
                                )
                                setVisYear(prevYear ? visYear - 1 : visYear)
                            }}
                            onRightChange={() => {
                                // When we hit end of the year again, we 
                                // jump to the beginning of the next year
                                // 28th Dec is either 52 or 53 week.
                                const yearMaxISOWeek = Math.max(getISOWeek(new Date(visYear, 11, 28)), 52)
                                const nextYear = visWeeks + nrInView - 1 === yearMaxISOWeek;
                                setVisWeeks(
                                    nextYear
                                    ? 1
                                    : Math.min(visWeeks + nrInView, yearMaxISOWeek - nrInView + 1)
                                )
                                setVisYear(nextYear ? visYear + 1 : visYear)
                            }}
                        />
                    </Box>,
                    <Box key='vacation-planner-save' style={{ flex: '0 0 95px'}}>
                        <Button
                            primary
                            style={{marginTop: '35px' }}
                            onClick={() => save()}
                            data-for="save"
                            disabled={toSave.length === 0}
                            data-tip='Lagre angitt ferie for ansatte.'
                        >Lagre</Button>
                        <Tooltip id="save" />
                    </Box>
                ]}
            />
            <CalendarHeatmap 
                data={rollupAndReduce(
                    filteredEmployees.map(e => 
                        getDaysFromDatePatterns(
                            dynomite,
                            e.vacationPatterns.concat(e.paidLeavePatterns)
                                .filter(p => !p.delete),
                            Math.max(...[
                                moment(`${visYear}-01-01`),
                                searchFilters.startDate ?
                                    moment(searchFilters.startDate)
                                    : moment(`${visYear}-01-01`),
                            ]),
                            Math.min(...[
                                moment(`${visYear}-12-31`),
                                searchFilters.endDate ?
                                    moment(searchFilters.endDate)
                                    : moment(`${visYear}-12-31`),
                            ]),
                            ['vacation', 'paidLeave'],
                        ).map(day => ({
                            Date: day,
                            Value: e.id
                        }))
                    ),
                    (day) => ({
                        Date: day[0].Date,
                        Value: day.reduce(
                            (acc, v) => acc.add(v.Value),
                            new Set()
                        ).size
                    })
                )}
                inMonths={
                    Array.from(Array(12).keys()).map(mId => `${visYear}-${mId + 1}`)
                }
                monthsLabels={Array.from(Array(12).keys()).reduce((a, v) => ({
                        ...a,
                        [`${visYear}-` + `0${v + 1}`.slice(-2)]: monthsShort[v]
                    }),
                    {}
                )}
                width={1580}
                height={280}
                margin={{top: 15, left: 0, bottom: 10, right: 0}}
                thresholdScale={{
                    domain: [
                        Math.round(employees.length * 0.1),
                        Math.round(employees.length * 0.2),
                        Math.round(employees.length * 0.3),
                        Math.round(employees.length * 0.4),
                        Math.round(employees.length * 0.5)
                    ]
                }}
                colorScheme={fullSchemeYlOrRd}
                undefinedColor={'#ffffff'}
                legendLabel={'Antall ansatte som er på ferie/permisjon'}
                weekSelection={{
                    year: visYear,
                    start: visWeeks,
                    count: nrInView,
                    color: defaultTheme.colors.primary.main
                }}
                hidden={false}
            />
            {columns === undefined && <p>Laster...</p>}
            {columns && <ReactGridWrapper>
                <ReactGrid
                    customCellTemplates={{
                        'checkbox': CheckboxCellTemplate,
                        'empty-blue': DisabledInfoCellTemplate
                    }}
                    rows={[
                        getReactGridHeaderRow(columns, 'header', 'text', 60),
                        ...getReactGridRows(
                            columns,
                            filteredEmployees.map(e => getLocalOrGlobalEmployee(e.id)),
                            mondays[0],
                            mondays[mondays.length -1]
                        ).map((row, idx) => ({
                            ...row,
                            cells: row.cells.map(cell => ({
                               ...cell,
                               style: {
                                    // every second row will get a new background definition
                                   ...(idx % 2 !== 0) && {
                                        background: cell.type === 'empty-blue' ? 'lightblue' : 'rgba(0,0,0,0.07)'
                                   },
                                   color: !row._employee.enabled ? "#888" : "black"
                               } 
                            }))
                        })),
                        getReactGridHeaderRow(columns, 'total', 'total')
                    ]}
                    columns={columns}
                    stickyTopRows={1}
                    stickyBottomRows={1}
                    stickyLeftColumns={3}
                    onCellsChanged={handleChanges}
                    enableFillHandle
                    enableRangeSelection
                    enableRowSelection
                />
            </ReactGridWrapper>}
            {showSavingModal.display && saveModal()}
            {routerPrompt(toSave.length > 0)}
            {configErrors.length > 0 && dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError)}
            {configWarnings.length > 0 && dynomiteErrorPanel(dynomite, configWarnings, dynomiteInternalError, 0, () => {}, true)}
        </PlannerWrapper>
    )
}