import React, { useEffect, useState} from "react";
import {dynoapeAPI} from "../../api/dynoape";
import {
    dynomiteErrorPanel,
    scrollHeight,
    shortenStr,
    sortShiftTypesByDaySegment
} from "../../lib/common";
import moment from "moment/moment";
import { format, parseISO, getISOWeek } from "date-fns";
import { nb } from "date-fns/locale";
import {Button, Flex} from "rendition";
import Tooltip from "../../lib/tooltip";
import {
    WeekJumper,
    TableLegendBox,
    TableLegendText,
    TableLegenItem
} from "../../lib/styled-components";
import {PlannerWrapper, ReactGridWrapper} from "./employee-date-pattern-cases";
import {ReactGrid} from "@silevis/reactgrid";
import "../grid-components/grid-styles.css";
import {CheckboxCellTemplate} from "../grid-components/checkbox-cell-template";
import {PatternCase} from "../../lib/pattern-case";
import {DropdownCellTemplate} from "../grid-components/dropdown-cell-template";
import {DropdownSelectCellTemplate} from "../grid-components/dropdown-select-cell-template";
import {DisabledInfoCellTemplate} from "../grid-components/disabled-info-cell-template";
import {holidayOptions, redDaysOptions} from "../../lib/options";
import GroupStackBar from '../vis-components/group-stack-bar';
import { schemeTableau10 } from 'd3-scale-chromatic';
import styled from "styled-components";

export default ({
    somePatternCaseIsDirty, setSomePatternCaseIsDirty, viewStartIndex, setViewStartIndex, setViewFlipSize, viewFlipSizeRef,
    taskSelect, searchBar,
    departmentId,
    setSelectedTaskOption,
    department, holidays, processAndSetHolidays,
    employees,
    employeesAvailabilities,
    setEmployeesWithAvailabilities,
    requirements, processAndSetRequirements,
    positions,
    setSelectedTaskInfo,
    patternCase,
    reset,
    getReactGridRows, getReactGridHeaderRow,
    showSavingModal, setShowSavingModal, saveModal,
    dynomite, validate, configErrors, configWarnings, dynomiteInternalError,
    startDate, setStartDate, nrOfWeeks, setNrOfWeeks,
    findAndSetOpenedDropdownCellLocation,
    searchFilters, applySearchFiltersToEmployees, routerPrompt
}) => {

    const [toSave, setToSave] = useState(new Map());
    const [toSaveAvailabilities, setToSaveAvailabilities] = useState(new Map());
    const [days, setDays] = useState(undefined);
    const nrInView = 18;

    useEffect(() => {
        setDays(holidays.slice(0, nrInView))
        scrollHeight(2000);
        return () => {
            reset();
        }
    }, [])

    useEffect(() => {
        if(
            (somePatternCaseIsDirty && toSave.size > 0)
            || (!somePatternCaseIsDirty && toSave.size === 0)
        ) return;
        setSomePatternCaseIsDirty(toSave.size > 0);
    }, [toSave])

    useEffect(() => {
        validate({employeesParam: applySearchFiltersToEmployees(employees).map(e => getLocalOrGlobalEmployee(e.id))});
    }, [toSave, employees, searchFilters, days])

    /**
     * Helper function for getting employee data. If there are unsaved employee
     * data, we fetch it from local storage. If not, the employee is returned.
     * 
     * @param employeeId
     * @returns {*}
     */
    const getLocalOrGlobalEmployee = (employeeId) => {
        return toSave?.get(employeeId) || employees.find(e => e.id === employeeId);
    }

    /**
     * Helper function to return availability either from local user or parent
     * user.
     * 
     * @param {string} employeeId 
     * @param {string} day 
     * @returns 
     */
    const getEmplAvailability = (employeeId, day) => {
        let employeeAvailability = toSave.has(employeeId) ?
            toSaveAvailabilities?.get(employeeId) :
            employeesAvailabilities?.get(employeeId);
        return [...(employeeAvailability?.get(day) || new Map()).values()]
    }

    /**
     * Function for creating a valid holiday pattern based on either an existing
     * or an empty one.
     * 
     * @param pattern
     * @returns {*}
     */
    const validHolidayPattern = (pattern) => {
        return {
            id: pattern?.id,
            summary: "Helligdager på jobb",
            series: null,
            includeDays: pattern?.includeDays || [],
            excludeDays: [],
            daySegments: ["D", "A", "N", "L"],
            disabled: false,
            optimize: false,
            kind: "working"
        }
    }

    let columns = undefined;
    if(days && employeesAvailabilities?.size > 0) {
        columns = [
            {
                columnId: "nr", width: 40, text: "Rad",
                item: ({ idx }) => ({ type: "header", text: `${idx + 1}` })
            }, {
                columnId: "name", width: 265, text: "Navn",
                item: ({ employee }) => ({ type: "header", text: `${shortenStr(employee.name, 30)}` })
            }, {
                columnId: "position", width: 150, text: "Stilling",
                item: ({ employee }) => ({ type: "header", text: shortenStr(`${employee.restPosition?.alias}`) })
            }, {
                columnId: "daySegment", width: 150, text: "Vaktkategorier",
                item: ({ employee }) => ({ type: "header", text: `${[...new Set(sortShiftTypesByDaySegment(employee.restShiftTypes)?.map(sh => sh.daySegment))]?.join(', ')}` })
            }, {
                columnId: "nrOfWorking", width: 130, text: "Antall helligdager",
                item: ({ employee }) => ({ type: "header", text: resolveNrOfWorkingHolidays(employee.id).toString() })
            }, {
                columnId: "redDaysMaxSequence", width: 120, text: "Maks. antall røde dager på rad",
                item: ({ employee }) => ({ type: "dropdown-number", text: getLocalOrGlobalEmployee(employee.id).redDaysMaxSequence, values: redDaysOptions })
            }, {
                columnId: "movableHolidaysMaxPerTurnus", width: 180, text: "Maks. antall bevegelige høytidsdager",
                item: ({ employee }) => ({ type: "dropdown-number", text: getLocalOrGlobalEmployee(employee.id).movableHolidaysMaxPerTurnus, values: holidayOptions })
            }
        ].concat(days.map(d => {
            const date = parseISO(d, {representation: 'date'})

            return {
                columnId: d,
                width: 130,
                text: `${d} \n uke ${getISOWeek(date)} (${format(date, 'EEE', { locale: nb })}) \n ${
                    d.endsWith('12-24')
                        ? 'Julaften'
                        : d.endsWith('12-31')
                            ? 'Nyttårsaften'
                            : department.countryRules.holidays[d]
                }`,
                item: ({ employee }) => {
                    let emplAvail = getEmplAvailability(employee.id, d);
                    let emplDSAvail = [
                        employee.dayShiftMaxSequence > 0,
                        employee.eveningShiftMaxSequence > 0,
                        employee.longShiftMaxSequence > 0,
                        employee.nightShiftMaxSequence > 0
                    ];
                    if (emplAvail.reduce((a, v) => a && v <= -1, true)) {
                        switch (emplAvail[emplDSAvail.findLastIndex((v) => v)])
                        {
                            case -18:
                            case -13:
                            case -12:
                            case -11: return { type: 'empty-data', text: 'H', nonEditable: true, className: 'orange-cell'}
                            case -14: return { type: 'empty-data', text: 'BH', nonEditable: true, className: 'orange-cell'}
                            case -5: return { type: 'empty-data', text: 'NA', nonEditable: true, className: 'purple-cell'}
                            case -3: return { type: 'empty-data', text: 'P', nonEditable: true, className: 'blue-cell'}
                            case -2: return { type: 'empty-data', text: 'FE', nonEditable: true, className: 'pink-cell'}
                            case -15: return { type: 'empty-data', text: 'Maks', nonEditable: true, className: 'aqua-cell'}
                            case -16: return { type: 'empty-data', text: 'Min', nonEditable: true, className: 'aqua-cell'}
                            case -17: return { type: 'empty-data', text: 'Natt', nonEditable: true, className: 'aqua-cell'}
                            default: return { type: 'empty-data', text: 'AF', nonEditable: true, className: 'aqua-cell'}
                        }
                    } else if (emplAvail.reduce((a, v) => a || (v > 0 && v != 0 && v != 2), false)) {
                        return { type: 'empty-data', text: 'Jobber', nonEditable: true, className: 'gray-cell'}
                    } else {
                        return {
                            type: 'checkbox-data',
                            checked: emplAvail.reduce((a, v) => a || v == 2, false),
                            checkedText: 'Ja',
                            uncheckedText: 'Nei'
                        }
                    }
                }
            }
        }));
    }

    /**
     * Methods for getting the total number of working holidays for an employee
     * in a turnus period.
     * 
     * @param employeeId
     * @returns {number}
     */
    const resolveNrOfWorkingHolidays = (employeeId) => {
        return holidays.reduce(
            (acc, day) =>
                acc + getEmplAvailability(employeeId, day).reduce((a, v) => a || v > 0, false),
            0
        )
    }

    /**
     * Task select handler method. Once a task is selected, we generate
     * the holidays in the turnus period which again triggers generate of
     * the columns displayed in the holiday planner
     * 
     * @param selectedTaskOption
     * @returns {Promise<void>}
     */
    const handleSelectTaskChange = async (selectedTaskOption) => {
        if(!selectedTaskOption) {
            reset();
            setSelectedTaskInfo(undefined);
            setToSaveAvailabilities(new Map(
                [...toSave].map(e => [
                    e[0],
                    new Map(dynomite.dynomite.employeeAvailability(
                        {
                            ...e[1],
                            shiftTypes: [...Object.entries(e[1].shiftTypes || {})]
                        },
                        moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
                        moment().add(157, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
                    ))
                ])
            ));
            let hol = processAndSetHolidays(department, moment().add(1, 'weeks').startOf('isoWeek'), 157);
            setDays(hol.slice(0, nrInView));
            return;
        }

        let t = await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${selectedTaskOption.value}`);
        setStartDate(t.config.startDate);
        setNrOfWeeks(t.config.nrOfWeeks);
        const _startDate = moment(t.config.startDate)
        const hol = processAndSetHolidays(department, _startDate, t.config.nrOfWeeks);
        const req = [...processAndSetRequirements(t).entries()];

        setToSaveAvailabilities(new Map(
            [...toSave].map(e => [
                e[0],
                new Map(dynomite.dynomite.employeeScheduleAvailability(
                    {
                        ...e[1],
                        shiftTypes: [...Object.entries(e[1].shiftTypes || {})]
                    },
                    req
                ))
            ])
        ));
        setDays(hol.slice(0, nrInView));

        setSelectedTaskInfo(t);
        setSelectedTaskOption({value: selectedTaskOption.value, label: selectedTaskOption.label});
        setViewStartIndex(0);
        setViewFlipSize(2);
        scrollHeight();
    }

    /**
     * UseEffect for generating which weeks that should be present in the holiday planner.
     * Triggered whenever user viewStartIndex is changed (user selects to jump weeks)
     */
    useEffect(() => {
        if(!holidays) return;
        setDays(holidays.slice(viewStartIndex, viewStartIndex + nrInView))
    }, [viewStartIndex])

    /**
     * Method for handling the changes in the sheet.
     * 
     * @param changes
     */
    const handleChanges = (changes) => {
        findAndSetOpenedDropdownCellLocation(changes);
        let localToSave = new Map();
        changes.forEach(ch => {
            if(ch.newCell.isOpen && !ch.previousCell.isOpen) return;

            const employee = (localToSave?.get(ch.rowId) || toSave?.get(ch.rowId) || employees.find(e => e.id === ch.rowId));
            if(['redDaysMaxSequence', 'movableHolidaysMaxPerTurnus'].includes((ch.columnId))) {
                const val = Number(ch.newCell.text);
                if (!isNaN(val)) {
                    employee[ch.columnId] = val;
                }
            }
            const date = moment(ch.columnId).format('YYYY-MM-DD');
            const pattern = validHolidayPattern((employee.holidayPatterns || []).find(p => p))
            if (ch.newCell.checked === true) {
                if(!pattern.includeDays.includes(date)) {
                    pattern.includeDays.push(date)
                }
            } else {
                if(pattern.includeDays.includes(date)) {
                    pattern.includeDays = pattern.includeDays.filter(d => d !== date);
                }
            }
            localToSave.set(
                employee.id,
                {...employee, holidayPatterns: [pattern].filter(p => p)}
            );
        });
        const newToSave = new Map([...toSave, ...localToSave]);
        const newToSaveAvailabilities = new Map(
            [...newToSave].map(e => [
                e[0],
                requirements.size === 0 ?    
                    new Map(dynomite.dynomite.employeeAvailability(
                        {
                            ...e[1],
                            shiftTypes: [...Object.entries(e[1].shiftTypes || {})]
                        },
                        moment(startDate).format('YYYY-MM-DD'),
                        moment(startDate).add(nrOfWeeks, 'weeks').format('YYYY-MM-DD'),
                    )) :
                    new Map(dynomite.dynomite.employeeScheduleAvailability(
                        {
                            ...e[1],
                            shiftTypes: [...Object.entries(e[1].shiftTypes || {})]
                        },
                        [...requirements.entries()]
                    ))
            ])
        );
        setToSave(newToSave);
        setToSaveAvailabilities(newToSaveAvailabilities);
    }

    /**
     * Method for saving employees and respective holiday patters
     * @returns {Promise<void>}
     */
    const save = async () => {
        setShowSavingModal({ display: true, saveCount: 0, toSave: toSave.size });
        for(const e of toSave.values()) {
            setShowSavingModal({ display: true, saveCount: showSavingModal.saveCount++, toSave: toSave.size, employee: e.name});
            for (const pattern of e.holidayPatterns) {
                const id = pattern.id;
                ['id', 'registered', 'updated'].forEach(field => delete pattern[field])
                if (id) {
                    await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${e.id}/pattern/${id}?type=${patternCase}`, pattern);
                } else {
                    await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee/${e.id}/pattern?type=${patternCase}`, pattern);
                }
            }
            await dynoapeAPI.emPatch(
                `/api/v1/department/${departmentId}/employee/${e.id}`,
                {
                    redDaysMaxSequence: e.redDaysMaxSequence,
                    movableHolidaysMaxPerTurnus: e.movableHolidaysMaxPerTurnus
                }
            );
        }
        setToSave(new Map());
        setToSaveAvailabilities(new Map());
        setShowSavingModal({ display: false, saveCount: 0, toSave: 0, employee: null })
        setEmployeesWithAvailabilities(
            (await dynoapeAPI.get(`/api/v1/department/${departmentId}/employees${PatternCase.ALL_PATTERNS}`))
                .filter(e => e.enabled)
                .sort(function (a, b) {
                    return a.priority === b.priority ? new Date(a.registered) - new Date(b.registered) : a.priority - b.priority
                })
        );
    }

    // Easier data structures for filtering
    // TODO: Extract to common component to remove duplication
    const posIds = new Set(positions.map(p => p.id));
    const dsSet = new Set(searchFilters.daySegments);

    // main data per date
    const mainVisData = days ?
        days.map(s => 
            ({
                date: s,
                // Available positions (employees)
                req: applySearchFiltersToEmployees(employees)
                    .map(e => getEmplAvailability(e.id, s)
                        .reduce((a, v) => a || v > 0, false) ? e.position : null
                    )
                    .filter(p => p)
                    .reduce((empls_pos, pos) => {
                        empls_pos[pos] = (empls_pos[pos] || 0) + 1;
                        return empls_pos;
                    }, new Map()),
                // Required min. positions (requirements)
                // add only when requirements exist
                ...(requirements.size > 0 ? {
                    plan: (requirements?.get(s) || [])
                        .filter(r => (dsSet.size === 0) || dsSet.has(r[1]))
                        .map(r => r[4]) // positions are stored at 4th index
                        .flat()
                        .reduce((pos_map, [pos, val]) => {
                            if (posIds.has(pos))
                                pos_map[pos] = (pos_map[pos] || 0) + val;
                            return pos_map;
                        }, new Map()
                    )
                } : {})
            })
        ) :
        [];

    const limitVisData = days
        && requirements.size > 0
        && (
            searchFilters.employee === undefined
            && searchFilters.positions.length === 0
            && searchFilters.traits.length === 0
            && searchFilters.daySegments.length === 0
            && !searchFilters.showOnlyWithPatterns
        ) ?
        days.reduce(
            (acc_date, s) => {
                acc_date.set(s, (requirements?.get(s) || []).reduce(
                    (acc_req, shift_req) => {
                        acc_req.min += shift_req[2];
                        acc_req.max += shift_req[3];
                        return acc_req;
                    }, {min: 0, max: 0}
                ));
                return acc_date;
            },
            new Map()
        ) :
        new Map();
    
    // positions colors and information for vis/legend
    const visPos = positions.map((pos, index) => ({
        id: pos.id,
        name: pos.name,
        color: schemeTableau10[index % schemeTableau10.length]
    }));

    return (
        <PlannerWrapper>
            <Flex flexDirection="row">
                <Button primary style={{marginTop: "0px" }} onClick={() => save()}
                        data-for="save"
                        disabled={toSave.size === 0}
                        data-tip="Lagre dine helligdags-spesifikasjoner for de ansatte">Lagre</Button>
                <Tooltip id="save" />
                {taskSelect(handleSelectTaskChange)}
                {searchBar()}
                {columns &&
                    <WeekJumper flipSize={viewFlipSizeRef.current} setFlipSize={setViewFlipSize}
                                viewStartIndex={viewStartIndex} setViewStartIndex={setViewStartIndex}
                                nrInView={nrInView} jumpTitle="Hopp antall uker" totalNrOfColumns={holidays.length}>
                    </WeekJumper>
                }
            </Flex>
            <br></br>
            {columns === undefined && <p>Laster...</p>}
            {columns && <ReactGridWrapper style={{marginTop: '0'}}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontFamily: 'Montserrat Alternates',
                    width: '1075px'
                }}>
                    <div>
                        <b>Tabell beskrivelse:</b>
                        <Flex justifyContent='start' flexWrap='wrap' flexDirection='row' style={{marginTop: '10px', gap:'4px', width: '640px'}}>
                            <TableLegenItem>
                                <TableLegendBox className='blue-cell'><i>P</i></TableLegendBox>
                                <TableLegendText>Ansatt har permisjon</TableLegendText>
                            </TableLegenItem>

                            <TableLegenItem>
                                <TableLegendBox className='pink-cell'><i>FE</i></TableLegendBox>
                                <TableLegendText>Ansatt har ferie</TableLegendText>
                            </TableLegenItem>

                            <TableLegenItem>
                                <TableLegendBox className='orange-cell'><i>H</i></TableLegendBox>
                                <TableLegendText>Ansatt har frihelg</TableLegendText>
                            </TableLegenItem>

                            <TableLegenItem>
                                <TableLegendBox className='orange-cell'><i>BH</i></TableLegendBox>
                                <TableLegendText>Ansatt arbeider bare helg</TableLegendText>
                            </TableLegenItem>

                            <TableLegenItem>
                                <TableLegendBox className='aqua-cell'><i>AF</i></TableLegendBox>
                                <TableLegendText>Ansatt er blokkert i Annet fravær</TableLegendText>
                            </TableLegenItem>

                            <TableLegenItem>
                                <TableLegendBox className='purple-cell'><i>NA</i></TableLegendBox>
                                <TableLegendText>Ingen tilgjengelige vaktkoder for ansatt</TableLegendText>
                            </TableLegenItem>

                            <TableLegenItem>
                                <TableLegendBox className='aqua-cell'><i>Maks</i></TableLegendBox>
                                <TableLegendText>Blokkert på grunn av krav til maksimum vakter på rad</TableLegendText>
                            </TableLegenItem>

                            <TableLegenItem>
                                <TableLegendBox className='aqua-cell'><i>Natt</i></TableLegendBox>
                                <TableLegendText>Blokkert på grunn av nattevakt</TableLegendText>
                            </TableLegenItem>

                            <TableLegenItem>
                                <TableLegendBox className='aqua-cell'><i>Min</i></TableLegendBox>
                                <TableLegendText>Blokkert på grunn av krav til minimum vakter på rad for vaktkategorier den ansatte kan gå</TableLegendText>
                            </TableLegenItem>

                            <TableLegenItem>
                                <TableLegendBox className='gray-cell'><i>Jobber</i></TableLegendBox>
                                <TableLegendText>Ansatt er satt opp til å arbeide</TableLegendText>
                            </TableLegenItem>
                        </Flex>
                    </div>
                    <div>
                        <b>Stillinger:</b>
                        <Flex justifyContent='start' flexWrap='wrap' flexDirection='row' style={{marginTop: '10px', gap:'4px'}}>
                            {visPos.map((pos) => (
                                <VisLegenItem key={'vis-legend-pos-' + pos.id + '-item'}>
                                    <VisLegendBox style={{backgroundColor: pos.color}}></VisLegendBox>
                                    <VisLegendText style={{flex: 'none'}}>{pos.name}</VisLegendText>
                                </VisLegenItem>
                            ))}
                        </Flex>
                    </div>
                    <div>
                        <b>Forklaring til visualisering:</b>
                        <Flex justifyContent='start' flexWrap='wrap' flexDirection='row' style={{marginTop: '10px', gap:'4px'}}>
                            <VisLegenItem key={'vis-legend-other-planned-item'}>
                                <VisLegendBox style={{backgroundColor: 'rgba(215, 215, 215, 0.5)'}}></VisLegendBox>
                                <VisLegendText style={{flex: 'none'}}>Krav i bemanningsplan</VisLegendText>
                            </VisLegenItem>
                            <VisLegenItem key={'vis-legend-other-planned-below'}>
                                <VisLegendBox style={{backgroundColor: 'rgba(255, 215, 215, 0.5)'}}></VisLegendBox>
                                <VisLegendText style={{flex: 'none'}}>Under minimum krav</VisLegendText>
                            </VisLegenItem>
                            <VisLegenItem key={'vis-legend-other-planned-above'}>
                                <VisLegendBox style={{backgroundColor: 'rgba(215, 215, 255, 0.5)'}}></VisLegendBox>
                                <VisLegendText style={{flex: 'none'}}>Over maksimum krav</VisLegendText>
                            </VisLegenItem>
                            <VisLegenItem key={'vis-legend-other-planned-minimum'}>
                                <VisLegendBox style={{height: '2px', backgroundColor: 'red', marginTop: '12px'}}></VisLegendBox>
                                <VisLegendText style={{flex: 'none'}}>Minimum  krav</VisLegendText>
                            </VisLegenItem>
                            <VisLegenItem key={'vis-legend-other-planned-maximum'}>
                                <VisLegendBox style={{height: '2px', backgroundColor: 'blue', marginTop: '12px'}}></VisLegendBox>
                                <VisLegendText style={{flex: 'none'}}>Maksimum krav</VisLegendText>
                            </VisLegenItem>
                        </Flex>
                    </div>
                    <GroupStackBar
                        data={mainVisData}
                        limitData={limitVisData}
                        width={days.length * 130 + 45}
                        height={240}
                        margin={{
                            top: 10,
                            left: 35,
                            right: 5,
                            bottom: 0
                        }}
                        stackDomain={visPos.map((pos) => pos.id)}
                        stackColors={visPos.map((pos) => pos.color)}
                        showLegend={false}
                    />
                </div>
                <ReactGrid
                    customCellTemplates={{
                        'dropdown-number': DropdownCellTemplate,
                        'dropdown': DropdownSelectCellTemplate,
                        'checkbox-data': CheckboxCellTemplate,
                        'empty-data': DisabledInfoCellTemplate
                    }}
                    rows={[
                        getReactGridHeaderRow(columns, 'header', 'text', 70),
                        ...getReactGridRows(columns, employees.map(e => getLocalOrGlobalEmployee(e.id)), days[0], days[days.length -1])
                            .map((row, idx) => ({
                                ...row,
                                cells: row.cells.map(cell => ({
                                    ...cell,
                                    style: {
                                        background: (
                                            cell.type !== 'empty-data'
                                            && cell.type !== 'checkbox-data'
                                            && idx % 2 !== 0
                                        ) 
                                            ? 'rgba(0,0,0,0.07)'
                                            : '',
                                        color: !row._employee.enabled ? '#888' : 'black'
                                    }
                                }))
                            })
                        )
                    ]}
                    columns={columns}
                    stickyTopRows={1}
                    stickyLeftColumns={4}
                    onCellsChanged={handleChanges}
                    enableFillHandle
                    enableRangeSelection
                    enableRowSelection
                />
            </ReactGridWrapper>}
            {showSavingModal.display && saveModal()}
            {routerPrompt(toSave.size > 0)}
            {configErrors.length > 0 && dynomiteErrorPanel(dynomite, configErrors, dynomiteInternalError)}
            {configWarnings.length > 0 && dynomiteErrorPanel(dynomite, configWarnings, dynomiteInternalError, 0, () => {}, true)}
        </PlannerWrapper>
    )
};

export const VisLegenItem = styled.div`
    width: 180px;
    font-size: 11px;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: start;
`;

export const VisLegendBox = styled.div`
    width: 50px;
    height: 28px;
    border-left: unset;
    border-right: 1px solid rgb(232, 232, 232);
    border-top: unset;
    border-bottom: 1px solid rgb(232, 232, 232);
    text-align: center;
    vertical-align: middle;
    line-height: 28px;
    font-family: Roboto;
`;

export const VisLegendText = styled.div`
    padding-left: 5px;
    height: 28px;
    display: flex;
    align-items: center;
    width: 150px;
`;