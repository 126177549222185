import React, { useEffect, useState } from "react";
import {dynoapeAPI} from "../api/dynoape";
import { useParams } from "react-router-dom";
import {
    customMaterialReactTableLocalization, daySegmentNames, daySegmentsPrioritized,
    hoursToLocalTime,
    localTimeToHours, noDecimalRegex,
    numbersAndLettersRegex, roundTimeToNearestTimeRes,
    sortShiftTypesByDaySegment,
    timeRegex
} from "../lib/common";
import Select from "react-select";
import Tooltip from "../lib/tooltip";
import {
    MaterialUIAddModal,
    MaterialUIDeleteModal,
    MaterialUIRoundAddButton,
    StyledInputMask
} from "../lib/styled-components";
import {useForm} from "react-hook-form";
import styled from "styled-components";
import Icon from "../lib/icon";
import {MaterialReactTable} from "material-react-table";
import Spinner from 'react-spinner-material';
import {Button, ButtonGroup, TextField} from "@mui/material"

const ShiftTypesTable = () => {
    const { setValue, register} = useForm({});
    const placeholderId = "placeholder";
    const defaults = {
        "D": {
            "id": placeholderId,
            "start": "07:00",
            "end": "15:00",
            "coreTimeStart": "07:00",
            "coreTimeEnd": "15:00",
            "daySegment": "D",
            "minuteResolution":15,
            "breakMinutes": 0,
            "priority": 0,
        },
        "A": {
            "id": placeholderId,
            "start": "14:45",
            "end": "22:00",
            "coreTimeStart": "14:45",
            "coreTimeEnd": "22:00",
            "daySegment": "A",
            "minuteResolution":15,
            "breakMinutes": 0,
            "priority": 0,
        },
        "L": {
            "id": placeholderId,
            "start": "08:00",
            "end": "21:30",
            "coreTimeStart": "08:00",
            "coreTimeEnd": "21:30",
            "daySegment": "L",
            "minuteResolution":15,
            "breakMinutes": 0,
            "priority": 0,
        },
        "N": {
            "id": placeholderId,
            "start": "21:30",
            "end": "07:00",
            "coreTimeStart": "21:30",
            "coreTimeEnd": "07:00",
            "daySegment": "N",
            "minuteResolution":15,
            "breakMinutes": 0,
            "priority": 0,
        }
    }

    const emptyShiftType = defaults["D"];
    const { departmentId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [showPutShiftTypeModal, setShowPutShiftTypeModal] = useState({ "show": false, "id": undefined});
    const [showDeleteModal, setShowDeleteModal] = useState({ "show": false, "id": "" });
    const [showInvalidTypeError, setShowInvalidTypeError] = useState({ "show": false, "msg": "", "field": "none" });
    const [shiftType, setShiftType] = useState({})
    const [shiftTypes, setShiftTypes] = useState([]);

    const daySegmentOptions = Object.entries(daySegmentNames).map(([ds, name]) => ({ value: ds, label: name}))
    const priorityValues = {
        2: "Høyere",
        1: "Litt høyere",
        0: "Normal"
    };

    const priorityChangeLimit = 5;
    const priorityChangeLimitReachedWhitelist = [0];
    const priorityChangeLimitReached = () =>
        [
            ...[shiftType],
            ...shiftTypes.filter(sh => sh.id !== shiftType.id)
        ]
            .filter(sh => !priorityChangeLimitReachedWhitelist.includes(parseInt(sh.priority)))
            .length >= priorityChangeLimit;

    const closeModal = () => {
        setShowInvalidTypeError({ "show": false, "msg": "", "field": "none" });
        setShiftType({});
        setShowPutShiftTypeModal({ "show": false, "id": undefined });
        ['code', 'start', 'coreTimeStart', 'coreTimeEnd', 'end', 'daySegment', 'breakMinutes'].forEach(f => setValue(f, emptyShiftType[f]));
    }

    useEffect(() => {
        const setSortedShiftTypes = async () => {
            setShiftTypes(sortShiftTypesByDaySegment(await dynoapeAPI.get(`/api/v1/department/${departmentId}/shift-types`)));
            setIsLoading(false);
        }
        setSortedShiftTypes();
    }, []);

    const validTimes = {
        "D": {
            "earliestStart": "06:00",
            "latestStart": "11:00",
            "earliestEnd": "12:00",
            "latestEnd": "17:00"
        },
        "A": {
            "earliestStart": "12:00",
            "latestStart": "19:00",
            "earliestEnd": "19:00",
            "latestEnd": "00:00"
        },
        "L": {
            "earliestStart": "00:00",
            "latestStart": "17:00",
            "earliestEnd": "12:00",
            "latestEnd": "00:00"
        },
        "N": {
            "earliestStart": "17:00",
            "latestStart": "23:45",
            "earliestEnd": "01:00",
            "latestEnd": "09:00"
        }
    }

    const between = (val, min, max) => val >= min && val <= max;


    function valid (type) {
        let code = type.code;
        if(!code || code.trim().length < 1) {
            setShowInvalidTypeError({"show": true, "msg": "Kode er påkrevd", "field": "code"});
            return false;
        }
        if(shiftTypes.filter(sh => !showPutShiftTypeModal.id || sh.id !== showPutShiftTypeModal.id).map(c => c.code).includes(type.code)) {
            setShowInvalidTypeError({"show": true, "msg": "Det finnes allerede en vaktkode med kode " + type.code, "field": "code"});
            return false;
        }
        if(! numbersAndLettersRegex().test(code)) {
            setShowInvalidTypeError({"show": true, "msg": "Kode kan kun inneholde tall og bokstaver", "field": "code"});
            return false;
        }

        let start = type.start;
        let startHours = parseFloat(localTimeToHours(start));
        if(isNaN(startHours) || isNaN(parseFloat(startHours)) || !timeRegex().test(start)) {
            setShowInvalidTypeError({"show": true, "msg": "'Starter' er ugyldig. Følgende format er påkrevd: HH:MM", "field": "start"});
            return false;
        }

        let coreTimeStart = type.coreTimeStart;
        let coreStartHours = parseFloat(localTimeToHours(coreTimeStart));
        if(isNaN(coreStartHours) || isNaN(parseFloat(coreStartHours)) || !timeRegex().test(coreTimeStart)) {
            setShowInvalidTypeError({"show": true, "msg": "'Kjernetid start klokken' er ugyldig. Følgende format er påkrevd: HH:MM", "field": "coreTimeStart"});
            return false;
        }

        let end = type.end;
        let endHours = parseFloat(localTimeToHours(end));
        if(isNaN(endHours) || isNaN(parseFloat(endHours)) || !timeRegex().test(end)) {
            setShowInvalidTypeError({"show": true, "msg": "'Slutter' er ugyldig. Følgende format er påkrevd: HH:MM", "field": "end"});
            return false;
        }

        let coreTimeEnd = type.coreTimeEnd;
        let coreEndHours = parseFloat(localTimeToHours(coreTimeEnd));
        if(isNaN(coreEndHours) || isNaN(parseFloat(coreEndHours)) || !timeRegex().test(coreTimeEnd)) {
            setShowInvalidTypeError({"show": true, "msg": "'Kjernetid slutt klokken' er ugyldig. Følgende format er påkrevd: HH:MM", "field": "coreTimeEnd"});
            return false;
        }

        if(type.daySegment === "N") {
            let genericNError = "Nattevakter må ha 'Kjernetid slutt klokken' and 'Slutter' dagen etter vaktstart, og vakten kan vare maksimalt 24 timer"
            if(startHours < endHours || coreStartHours < coreEndHours) {
                setShowInvalidTypeError({"show": true, "msg": genericNError, "field": "none"});
                return false;
            }
        } else {
            if(startHours > (endHours === 0 ? 24 : endHours)) {
                setShowInvalidTypeError({"show": true, "msg": "'Slutter' må være etter 'Starter'", "field": "end"});
                return false;
            }
            if(coreStartHours > (coreEndHours === 0 ? 24 : coreEndHours)) {
                setShowInvalidTypeError({"show": true, "msg": "'Kjernetid slutt klokken' må være etter 'Kjernetid start klokken'", "field": "coreTimeEnd"});
                return false;
            }
        }

        if(startHours > coreStartHours) {
            setShowInvalidTypeError({"show": true, "msg": "'Kjernetid start klokken' må være etter 'Starter'", "field": "coreTimeStart"});
            return false;
        }
        if((endHours === 0 ? 24 : endHours) < (coreEndHours === 0 ? 24 : coreEndHours)) {
            setShowInvalidTypeError({"show": true, "msg": "'Kjernetid slutt klokken' må være før 'Slutter'", "field": "coreTimeEnd"});
            return false;
        }

        let times = validTimes[type.daySegment];

        if(! (between(startHours, localTimeToHours(times.earliestStart), localTimeToHours(times.latestStart))
            && between(coreStartHours, localTimeToHours(times.earliestStart), localTimeToHours(times.latestStart)))) {
            setShowInvalidTypeError({"show": true, "msg":
                    "'Starter' og 'Kjernetid start klokken' må være mellom " + times.earliestStart + " og " + times.latestStart, "field": "none"});
            return false;
        }

        let earliestEnd = localTimeToHours(times.earliestEnd);
        let latestEnd = localTimeToHours(times.latestEnd);
        if(! (between((endHours === 0 ? 24 : endHours), (earliestEnd === 0 ? 24 : earliestEnd), (latestEnd === 0 ? 24 : latestEnd))
            && between((coreEndHours === 0 ? 24 : coreEndHours), (earliestEnd === 0 ? 24 : earliestEnd), (latestEnd === 0 ? 24 : latestEnd)))) {
            setShowInvalidTypeError({"show": true, "msg":
                    "'Slutter' og 'Kjernetid slutt klokken' må være mellom " + times.earliestEnd + " og " + times.latestEnd, "field": "none"});
            return false;
        }

        let breakMin = parseInt(type.breakMinutes)
        if(isNaN(breakMin) || !noDecimalRegex().test(type.breakMinutes)) {
            setShowInvalidTypeError({"show": true, "msg":
                    "Ubetalt pause må være et heltall" ,"field": "breakMinutes"});
            return false
        }


        setShowInvalidTypeError({"show": false, "msg": "", "field": "none"})
        fixResolutionAndReturnModel(type);
        return true;
    }

    const fixResolutionAndReturnModel = (type) => {
        const res = parseInt(type.minuteResolution);
        ['start', 'coreTimeStart', 'coreTimeEnd', 'end'].forEach(field => type[field] = roundTimeToNearestTimeRes(type[field], res));
        ['start', 'coreTimeStart', 'coreTimeEnd', 'end'].forEach(field => setValue(field, type[field]))
        return type;
    }

    const setShiftHoursAndClean = (type) => {
        let coreTimeEnd = parseFloat(localTimeToHours(type.coreTimeEnd));
        let end = parseFloat(localTimeToHours(type.end));
        let addHours = type.daySegment === "N" ? 24 : 0;
        type.shiftHoursMin = addHours + (coreTimeEnd === 0 ? 24 : coreTimeEnd) - parseFloat(localTimeToHours(type.coreTimeStart));
        type.shiftHoursMax = addHours + (end === 0 ? 24 : end) - parseFloat(localTimeToHours(type.start));
        ['coreTimeEnd', 'end', 'registered', 'updated', 'departmentId', 'id'].forEach(field => delete type[field])
        return type;
    }

    const onDaySegmentChange = (ds) => {
        return fixResolutionAndReturnModel(defaults[ds])
    }

    const save = async () => {
        setIsLoading(true);
        if(!valid(shiftType)) {
            return;
        }
        showPutShiftTypeModal.id !== undefined
            ? await dynoapeAPI.put(`/api/v1/department/${departmentId}/shift-type/${showPutShiftTypeModal.id}`, setShiftHoursAndClean(shiftType))
            : await dynoapeAPI.post(`/api/v1/department/${departmentId}/shift-type`, setShiftHoursAndClean(shiftType))
        setShiftTypes(sortShiftTypesByDaySegment(await dynoapeAPI.get(`/api/v1/department/${departmentId}/shift-types`)));
        setShiftType(emptyShiftType)
        closeModal();
        setIsLoading(false);
    }

    const del = async (id) => {
        setIsLoading(true);
        try {
            await dynoapeAPI.delete(`/api/v1/department/${departmentId}/shift-type/${id}`);
            setShiftTypes(shiftTypes.filter(p => { return p.id !== id }));
        } catch (error) {
            console.error(error);
        }
        setShowDeleteModal({ show: false, id: "" });
        setIsLoading(false);
    };

    const columns = [
        {
            accessorKey: 'code',
            header: 'Kode',
            size: 50,
            muiTableBodyCellProps: {
                sx: {
                    paddingLeft: '50px',
                },
            },
            muiTableHeadCellProps: {
                sx: {
                    paddingLeft: '50px',
                },
            },
            Cell: ({ cell }) => (<strong>{cell.getValue()}</strong>)
        },
        {
            accessorKey: 'daySegment',
            header: 'Vaktkategori',
            size: 50,
            Cell: ({ cell }) => (<strong>{daySegmentNames[cell.getValue()]}</strong>),
            sortingFn: (rowA, rowB, columnId) => {
                return String(daySegmentsPrioritized.indexOf(rowA.getValue(columnId)))
                    .localeCompare(String(daySegmentsPrioritized.indexOf(rowB.getValue(columnId)))
                );
            },
            filterFn: (row, columnId, filterValue) => {
                const label = daySegmentNames[row.getValue(columnId)];
                return label.toLowerCase().includes(filterValue.toLowerCase());
            }
        },
        {
            accessorKey: 'start',
            header: 'Starter',
            size: 50,
            enableColumnFilter: false,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'coreTimeStart',
            header: 'Kjernetid start',
            size: 50,
            enableColumnFilter: false,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'shiftHoursMin',
            header: 'Kjernetid slutt',
            size: 50,
            enableSorting: false,
            enableColumnFilter: false,
            enableGlobalFilter: false,
            Cell: ({ row, cell }) => {
                let coreStart = localTimeToHours(row.original.coreTimeStart);
                let coreEnd = parseFloat(coreStart) + cell.getValue()
                return hoursToLocalTime(coreEnd)
            }},
        {
            accessorKey: 'shiftHoursMax',
            header: 'Slutter',
            size: 50,
            enableSorting: false,
            enableColumnFilter: false,
            enableGlobalFilter: false,
            Cell: ({ row, cell }) => {
                let start = localTimeToHours(row.original.start);
                let end = parseFloat(start) + cell.getValue()
                return hoursToLocalTime(end)
            }},
        {
            accessorKey: 'breakMinutes',
            header: 'Ubetalt pause',
            size: 50,
            Cell: ({ cell }) => cell.getValue() + " minutter",
            enableColumnFilter: false,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'priority',
            header: 'Prioritet',
            size: 50,
            Cell: ({ cell }) => priorityValues[parseInt(cell.getValue())],
            enableColumnFilter: false,
            enableGlobalFilter: false,
        },
        {
            accessorKey: 'shiftLength',
            header: 'Vaktlengde',
            size: 50,
            enableSorting: false,
            enableColumnFilter: false,
            enableGlobalFilter: false,
            Cell: ({ row }) =>
                hoursToLocalTime(parseFloat(row.original.shiftHoursMax) - parseFloat(row.original.breakMinutes) / 60)
        },
        {
            header: 'Handlinger',
            Cell: ({ row }) => (
                <>
                    <Button
                        variant="contained"
                        sx={{backgroundColor: "rgb(48, 105, 112)", borderRadius: "20px"}}
                        onClick={() => {
                            const type = {
                                ...row.original,
                                end: hoursToLocalTime(localTimeToHours(row.original.start) + parseFloat(row.original.shiftHoursMax)),
                                coreTimeEnd: hoursToLocalTime(localTimeToHours(row.original.coreTimeStart) + parseFloat(row.original.shiftHoursMin))
                            }
                            setShiftType(type);
                            ['start', 'coreTimeStart', 'coreTimeEnd', 'end', 'breakMinutes'].forEach(field => setValue(field, type[field]))
                            setShowPutShiftTypeModal({ "show": true, "id": type.id});

                        }}
                        style={{ marginRight: "5px"}}
                    >
                        <Icon name="edit-w" />
                    </Button>
                    <Button
                        variant="contained"
                        sx={{borderRadius: "20px"}}
                        color="error"
                        onClick={() => setShowDeleteModal({ "show": true, "id": row.original.id })}
                    >
                        <Icon name="trash-w"/>
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div style={{maxWidth: "1800px"}}>
            <MaterialUIRoundAddButton
                onClick={() => {
                    setShiftType(emptyShiftType);
                    setShowPutShiftTypeModal({ "show": true, "id": undefined });
                }}
                text="Legg til vaktkode"
            >
            </MaterialUIRoundAddButton>
            {isLoading
                ? <Spinner radius={120} color={"#333"} stroke={2} visible={true} />
                : !shiftTypes?.length
                    ? <div style={{ background: "white", padding: "10px 20px 20px 10px" }}><h4>Ingen vaktkoder funnet</h4></div>
                    : <MaterialReactTable
                        columns={columns}
                        data={shiftTypes}
                        localization={customMaterialReactTableLocalization}
                        filterFns={{myCustomFilterFn: (row, id, filterValue) => {
                                return !['daySegment', 'code'].includes(id)
                                    ? false
                                    : id === 'daySegment'
                                        ? daySegmentNames[String(row.getValue(id))].toLowerCase().includes(filterValue.toLowerCase())
                                        : String(row.getValue(id)).toLowerCase().includes(filterValue.toLowerCase())
                            }}}
                        globalFilterFn='myCustomFilterFn'
                        initialState={{
                            columnVisibility: {
                                coreTimeStart: false,
                                shiftHoursMin: false,
                            },
                            pagination: {
                                pageSize: 20,
                                pageIndex: 0
                            }
                        }}
                    />
            }
            <MaterialUIAddModal
                open={showPutShiftTypeModal.show}
                onClose={() => closeModal()}
                title={showPutShiftTypeModal.id !== undefined ? "Endre vaktkode" : "Legg til ny vaktkode"}
                onConfirm={() => save()}
                isLoading={isLoading}
            >
                <Button
                    onClick={() =>
                        setShowPutShiftTypeModal(
                            {...showPutShiftTypeModal, showAdvanced: !showPutShiftTypeModal.showAdvanced === true}
                        )}
                    sx={{
                        position: "absolute",
                        top: 18,
                        right: 10,
                        margin: "8px",
                        color: "rgb(48, 105, 112)",
                        textTransform: "none",
                        fontFamily: "Roboto",
                        fontSize: "18px"
                    }}
                    disabled={showInvalidTypeError.show}
                >
                    {`${showPutShiftTypeModal.showAdvanced ? "Skjul" : "Vis"} avanserte innstillinger`}
                </Button>
                <div>
                    <label htmlFor={"code"}>Kode (kun tall og bokstaver tillatt)</label>
                    <div style={{marginTop: "10px", marginBottom: "20px"}}>
                        <TextField
                            placeholder={"For eks: D1"}
                            id="code"
                            sx={{
                                input: {
                                    backgroundColor: showPutShiftTypeModal.id !== undefined ? "hsl(0, 0%, 95%)" : "white",
                                    color: showPutShiftTypeModal.id !== undefined ? "hsl(0, 0%, 60%)" : "black",
                                    textTransform: "uppercase",
                                    fontFamily: "Montserrat Alternates",
                                    height: "5px"
                                }
                            }}
                            disabled={showPutShiftTypeModal.id !== undefined}
                            defaultValue={shiftType.code}
                            data-for="code"
                            data-tip="Her velger du koden for vaktkoden"
                            {...register("code")}
                            onInput={(e) => {
                                if (e.target.value.length > 4) {
                                    e.target.value = e.target.value.slice(0, 4);
                                }
                            }}
                            onChange={(e) => {
                                let newType = {...shiftType, code: e.target.value.toUpperCase()};
                                if(!valid(newType)) {
                                    return;
                                }
                                setShiftType(newType);
                            }}
                        />
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "code") &&
                            <Err>{showInvalidTypeError.msg}</Err>
                        }
                    </div>
                    <Tooltip id="code" />

                    <label htmlFor={"daySegment"}>Vaktkategori</label>
                    <div style={{ width: "300px", marginBottom: "20px", marginTop: "10px"}}>
                        <Select id="daySegment"
                                data-for="daySegment"
                                data-tip="Her velger du hvilken vaktkategori (D, A, L, N)"
                                value={{value: shiftType.daySegment, label: daySegmentNames[shiftType.daySegment]}}
                                {...register("daySegment")}
                                onChange={(e) => {
                                    let type = e.value.trim();
                                    let newType = {
                                        ...shiftType,
                                        ...onDaySegmentChange(type),
                                        daySegment: type};
                                    setShiftType(newType);
                                    valid(newType)
                                }}
                                options={daySegmentOptions}>
                        </Select>
                        <Tooltip id="daySegment" />
                    </div>

                    <hr style={{border: "2px solid #EAEEF3", borderBottom: 0, margin: "20px 0"}} />
                    <label htmlFor={"start"}>Starter:</label>
                    <div style={{ width: "300px", marginBottom: "20px", marginTop: "10px"}}>
                        <StyledInputMask
                            id="start"
                            key="start"
                            defaultValue={shiftType.start}
                            mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                            maskPlaceholder="-"
                            alwaysShowMask={true}
                            data-for="start"
                            {...register("start" )}
                            data-tip="Her angir du når vakten tidligst kan starte"
                            onChange={(e) => {
                                const newType = {
                                    ...shiftType,
                                    start: e.target.value,
                                    coreTimeStart: timeRegex().test(e.target.value) ? e.target.value : shiftType.coreTimeStart
                                }
                                if(!valid(newType)) return;
                                setShiftType(fixResolutionAndReturnModel(newType));
                            }}
                        />
                        <Tooltip id="start" />
                    </div>
                    {(showInvalidTypeError.show && showInvalidTypeError.field === "start") &&
                        <Err>{showInvalidTypeError.msg}</Err>
                    }
                    <div style={{display: `${showPutShiftTypeModal.showAdvanced ? "block" : "none"}`}}>
                        <label htmlFor={"coreTimeStart"}>Kjernetid start klokken:</label>
                        <div
                            style={{
                                width: "300px",
                                marginBottom: "20px",
                                marginTop: "10px"
                            }}>
                            <StyledInputMask
                                id="coreTimeStart"
                                defaultValue={shiftType.coreTimeStart}
                                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                                maskPlaceholder="-"
                                alwaysShowMask={true}
                                data-for="coreTimeStart"
                                {...register("coreTimeStart")}
                                data-tip="Her angir du når vakten senest kan starte"
                                onChange={(e) => {
                                    const newType = {
                                        ...shiftType,
                                        coreTimeStart: e.target.value
                                    };
                                    if (!valid(newType)) return;
                                    setShiftType(fixResolutionAndReturnModel(newType));
                                }}
                            />
                            <Tooltip id="coreTimeStart"/>
                        </div>
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "coreTimeStart") &&
                            <Err>{showInvalidTypeError.msg}</Err>
                        }

                        <label htmlFor={"coreTimeEnd"}>Kjernetid slutt klokken:</label>
                        <div
                            style={{
                                width: "300px",
                                marginBottom: "20px",
                                marginTop: "10px",
                            }}>
                            <StyledInputMask
                                id="coreTimeEnd"
                                defaultValue={shiftType.coreTimeEnd}
                                mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                                maskPlaceholder="-"
                                alwaysShowMask={true}
                                data-for="coreTimeEnd"
                                {...register("coreTimeEnd")}
                                data-tip="Her angir du når vakten senest kan starte"
                                onChange={(e) => {
                                    let newType = {
                                        ...shiftType,
                                        coreTimeEnd: e.target.value
                                    };
                                    if (!valid(newType)) return;
                                    setShiftType(fixResolutionAndReturnModel(newType));
                                }}
                            />
                            <Tooltip id="coreTimeEnd"/>
                        </div>
                        {(showInvalidTypeError.show && showInvalidTypeError.field === "coreTimeEnd") &&
                            <Err>{showInvalidTypeError.msg}</Err>
                        }
                    </div>

                    <label htmlFor={"end"}>Slutter:</label>
                    <div style={{ width: "300px", marginBottom: "20px", marginTop: "10px"}}>
                        <StyledInputMask
                            id="end"
                            key="end"
                            defaultValue={shiftType.end}
                            mask={[/^([0-2])/, /([0-9])/, ":", /[0-5]/, /[0-9]/]}
                            maskPlaceholder="-"
                            alwaysShowMask={true}
                            data-for="end"
                            {...register("end" )}
                            data-tip="Her angir du når vakten senest kan slutte"
                            onChange={(e) => {
                                const newType = {
                                    ...shiftType,
                                    end: e.target.value,
                                    coreTimeEnd: timeRegex().test(e.target.value) ? e.target.value : shiftType.coreTimeEnd
                                }
                                if(!valid(newType)) return;
                                setShiftType(fixResolutionAndReturnModel(newType));
                            }}
                        />
                        <Tooltip id="end" />
                    </div>
                    {(showInvalidTypeError.show && showInvalidTypeError.field === "end") &&
                        <Err>{showInvalidTypeError.msg}</Err>
                    }

                    <label htmlFor={"breakMinutes"}>Ubetalt pause i minutter:</label>
                    <div style={{ width: "80px", marginBottom: "20px", marginTop: "10px"}}>
                        <TextField
                            id="breakMinutes"
                            key="breakMinutes"
                            sx={{
                                input: {
                                    height: "5px",
                                    fontFamily: "Montserrat Alternates"
                                }
                            }}
                            type="number"
                            defaultValue={shiftType.breakMinutes}
                            data-for="breakMinutes"
                            {...register("breakMinutes" )}
                            data-tip="Her angir du den passive tiden"
                            onChange={(e) => {
                                let newType = {...shiftType, breakMinutes: e.target.value};
                                if(!valid(newType)) return;
                                setShiftType(fixResolutionAndReturnModel(newType));
                            }}
                        />
                        <Tooltip id="breakMinutes" />
                    </div>
                    {(showInvalidTypeError.show && showInvalidTypeError.field === "breakMinutes") &&
                        <Err>{showInvalidTypeError.msg}</Err>
                    }

                    <div style={{display: `${showPutShiftTypeModal.showAdvanced ? "block" : "none"}`}}>
                        <label htmlFor={"priority"}>Prioritet</label>
                        <div>
                            <ButtonGroup
                                style={{marginTop: "15px"}}
                                data-for="priority"
                                data-tip="Her setter du hvordan du ønsker at vaktkoden skal prioriteres når turnusplanen lages"
                                key={`dayButtonGroup-${name}`}>
                                {Object.entries(priorityValues).map(([pri, transl]) => {
                                    const isPri = parseInt(shiftType.priority) === parseInt(pri);
                                    return <Button key={`priInput-${pri}`}
                                                   sx={{
                                                       borderRadius: "20px" ,
                                                       background: isPri ? 'rgb(48, 105, 112)' : '#fff',
                                                       color: isPri ? '#fff' : '#333'
                                                   }}
                                                   style={{
                                                       padding: "4px 25px",
                                                   }}
                                                   disabled={priorityChangeLimitReached() && priorityChangeLimitReachedWhitelist.includes(parseInt(shiftType.priority))}
                                                   onClick={() => {
                                                       shiftType.priority = pri;
                                                       if (!valid(shiftType)) return;
                                                       setShiftType(fixResolutionAndReturnModel(shiftType));
                                                   }}
                                    >{transl}</Button>
                                })
                                }
                            </ButtonGroup>
                        </div>
                        {
                            priorityChangeLimitReached() && priorityChangeLimitReachedWhitelist.includes(parseInt(shiftType.priority)) &&
                            <div style={{marginTop: "10px"}}><i>Du kan ikke endre prioritet på flere vaktkoder</i></div>
                        }
                        <Tooltip id="priority" />
                    </div>

                    <hr style={{border: "2px solid #EAEEF3", borderBottom: 0, margin: "20px 0"}} />
                    {(showInvalidTypeError.show && showInvalidTypeError.field === "none") && (
                        <Err>{showInvalidTypeError.msg}</Err>
                    )}
                </div>
            </MaterialUIAddModal>
            <MaterialUIDeleteModal
                open={showDeleteModal.show}
                onClose={() => setShowDeleteModal({ show: false, id: undefined })}
                title={"Slett vaktkode"}
                confirmationText={`Er du sikker på at du vil slette vaktkode ${shiftTypes.filter(sh => sh.id === showDeleteModal.id)?.[0]?.code}?`}
                deleteText={"Slett vaktkode"}
                onConfirm={() => del(showDeleteModal.id)}
                isLoading={isLoading}
            >
            </MaterialUIDeleteModal>
        </div>
    );
};

const Err = styled.p`
  color: #BF3D2B
`;

export default ShiftTypesTable;
