import React, {useContext, useEffect, useState} from "react";
import dynomiteContext from "./dynomite";
import GroupStackBar from '../components/vis-components/group-stack-bar';
import styled from "styled-components";
import { Button } from "rendition";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {dynoapeAPI} from "../api/dynoape";
import { parseISO, format, formatISO, addWeeks, addDays, nextSunday } from "date-fns";
import { nb } from "date-fns/locale";
import { schemeTableau10 } from 'd3-scale-chromatic';

const TaskWeekendVisualization = ({
    departmentId,
    taskId
}) => {
    const dynomite = useContext(dynomiteContext);
    const [weekOffset, setWeekOffset] = useState(0);
    const [fetching, setFetching] = useState(true);
    const [config, setConfig] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [requirements, setRequirements] = useState(new Map());
    const [emplAvail, setEmplAvail] = useState(new Map());
    const [visData, setVisData] = useState({});
    const [visLimits, setVisLimits] = useState({});
    const [visPos, setVisPos] = useState([]);
    const weeksCount = 12;

    /** Force re-fetch of the data any time we load the page 
     * The idea is to get all BP related data.
     * 
     * - In case of creating BP, those are the copy of current empls, pos, etc.
     * - In case of already finished BP, data used to generate are visualized.
    */
    useEffect(() => { getData();}, [])
    const getData = async () => {
        let t = await dynoapeAPI.get(
            `/api/v1/department/${departmentId}/task/${taskId}`
            + "?includeTaskEmployees=true"
            + "&includeTaskShiftTypes=true"
            + "&includeTaskTraits=true"
            + "&includeTaskPositions=true"
            + "&includeTaskHolidays=true"
        );
        setConfig(t.config);
        
        const requirementsMap = new Map(
            dynomite.dynomite.scheduleRequirements(
                JSON.stringify(t.config || {})
            )
        );
        const requirements = [...requirementsMap.entries()];
        const availabilities = new Map(
            t.config.employees.map(e => [
                e.id,
                new Map(dynomite.dynomite.employeeScheduleAvailability(
                    {
                        ...e,
                        shiftTypes: [...Object.entries(e.shiftTypes || {})]
                    },
                    requirements
                ))
            ])
        );

        setStartDate(parseISO(t.config.startDate, {representation: 'date'}));
        setRequirements(requirementsMap);
        setEmplAvail(availabilities);
        setVisPos([...Object.entries(t.config.positions)].map((pos, index) => ({
            id: pos[0],
            name: pos[1],
            color: schemeTableau10[index % schemeTableau10.length]
        })));
        setAllVisData(weekOffset, t.config, availabilities, requirementsMap);
        setFetching(false);
    }

    /** Recalculate visualization based data based on the change of the viewed
     * week offset.
    */
    const setAllVisData = (weekOffset, conf, avail, req) => {
        if(Object.keys(conf).length === 0) return;

        const firstSunday = nextSunday(parseISO(conf.startDate, {representation: 'date'}));
        const lastSunday = formatISO(addWeeks(firstSunday, conf.nrOfWeeks - 1), {representation: 'date'});
        const sundays = [...Array(weeksCount).keys()]
            .map(d => formatISO(addWeeks(firstSunday, weekOffset + d), {representation: 'date'}));

        const mainVisData = sundays
            .filter(s => s <= lastSunday)
            .map(s => ({
            date: s,
            // Available positions (employees)
            req: conf.employees.map(
                    e => [...(avail?.get(e.id)?.get(s) || new Map()).values()]
                            .reduce((a, v) => a || v >= 0, false) ? e.position : null
                )
                .filter(p => p)
                .reduce(
                    (empls_pos, pos) => {
                        empls_pos[pos] = (empls_pos[pos] || 0) + 1;
                        return empls_pos;
                    },
                    new Map()
                ),
            // Required min. positions (requirements)
            plan: (req?.get(s) || [])
                .map(r => r[4]) // positions are stored at 4th index
                .flat()
                .reduce(
                    (pos_map, [pos, val]) => {
                        pos_map[pos] = (pos_map[pos] || 0) + val;
                        return pos_map;
                    },
                    new Map()
                )
        }));

        // min/max lines per date
        const limitVisData = sundays.reduce(
            (acc_date, s) => {
                acc_date.set(s, (req?.get(s) || []).reduce(
                    (acc_req, shift_req) => {
                        acc_req.min += shift_req[2];
                        acc_req.max += shift_req[3];
                        return acc_req;
                    }, {min: 0, max: 0}
                ));
                return acc_date;
            },
            new Map()
        );
        setVisData(mainVisData);
        setVisLimits(limitVisData);
    }

    /** Update week offset and vis data at the same time. */
    const SetWeekPeriod = (newWeekOffset) => {
        setWeekOffset(newWeekOffset);
        setAllVisData(newWeekOffset, config, emplAvail, requirements);
    }

    return (!fetching &&
        <RuleCard style={{ borderTopLeftRadius: "0px" }}>
            <InputRow>
                <Button
                    style={{ "padding": "1em" }}
                    disabled={(weekOffset - weeksCount) < 0}
                    onClick={() => SetWeekPeriod(weekOffset - weeksCount)}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <h4 style={{ "margin": 0, "textAlign": "center", "width": 175}}>
                    {format(
                        addWeeks(startDate, weekOffset),
                        'do MMM yyyy',
                        {locale: nb}
                    )}
                </h4>
                <h4 style={{ "margin": 0}}>:</h4>
                <h4 style={{ "margin": 0, "textAlign": "center", "width": 175}}>
                    {format(
                        Math.min(
                            addWeeks(startDate, weekOffset + weeksCount),
                            addDays(startDate, config.nrOfWeeks * 7 - 1)
                        ),
                        'do MMM yyyy',
                        {locale: nb}
                    )}
                </h4>
                <Button
                    style={{ "padding": "1em" }}
                    disabled={(weekOffset + weeksCount) > config.nrOfWeeks}
                    onClick={() => SetWeekPeriod(weekOffset + weeksCount)}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </InputRow>
            <GroupStackBar
                data={visData}
                limitData={visLimits}
                width={Object.keys(visData).length * 110 + 40}
                height={330}
                margin={{
                    top: 90,
                    left: 35,
                    right: 5,
                    bottom: 30
                }}
                stackDomain={visPos.map((pos) => pos.id)}
                stackLegend={visPos.map((pos) => pos.name)}
                stackColors={visPos.map((pos) => pos.color)}
                showLegend={true}
            />
        </RuleCard>
    )
  };
  
export default TaskWeekendVisualization;

const RuleCard = styled.div`
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    margin-bottom: 25px;
    padding: 25px;
    width: 1350px;
`;
const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`