import React from "react";
import {EmployeeCard, Header} from "../components";
import {BackButtonEmployee} from "../components/buttons";

const Employees = () => {
    const defaultEmployee = {
        vacancyRate: 100,
        enabled: true,
        shiftMinSequence: 0,
        shiftMaxSequence: 5,
        maxShifts: null,
        dayShiftMinSequence: 0,
        dayShiftMaxSequence: 0,
        maxDayShifts: null,
        dayShiftsMaxPerWeek: null,
        eveningShiftMinSequence: 0,
        eveningShiftMaxSequence: 0,
        maxEveningShifts: null,
        eveningShiftsMaxPerWeek: null,
        nightShiftMinSequence: 0,
        nightShiftMaxSequence: 0,
        maxNightShifts: null,
        nightShiftsMaxPerWeek: null,
        maxNightShiftWeekends: null,
        longShiftMinSequence: 0,
        longShiftMaxSequence: 0,
        maxLongShifts: null,
        longShiftsMaxPerWeek: null,
        worksOnlyWeekend: false,
        nrOfQuickReturnsPerWeekOnWeekend: 1,
        nrOfQuickReturnsPerWeekOnWeekdays: 1,
        redDaysMaxSequence: 5,
        movableHolidaysMaxPerTurnus: 5,
        comment: "",
        weekendShiftMaxSequence: -1,
        traitNames: {},
        shiftTypes: {},
        restShiftTypes: [],
        sundaysMaxSequence: 2
    }
    return (
        <div>
            <Header title={"Legg til ny ansatt"} showLicenseEmployeeInfo={true}>
                <BackButtonEmployee/>
            </Header>
            <div className="content">
                <EmployeeCard initialEmployee={defaultEmployee} isNew={true}/>
            </div>
        </div>
    );
};

export default Employees;
