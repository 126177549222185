import styled from "styled-components";
import {Flex, Input, Button} from "rendition";
import InputMask from "react-input-mask";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import React from "react";
import Tooltip from "./tooltip";
import {Add} from "@mui/icons-material";
import {Box, Button as MuiButton, Modal, Stack, Tab, Tabs, Typography} from "@mui/material"

export const Card = styled.div`
  min-height: 180px;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin: 0 10px;
  padding-left: 25px;
`;

export const CardRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputDivider = styled.div`
  min-width: 300px;
  display: flex;
  justify-content: space-between;
`;

export const InputWithValidation = styled.div`  
  display: flex;
  flex-flow: wrap;  
  position: relative;

  span {
    width: ${props => props.width || "400px"};   
    color: #BF3D2B;
    top: ${props => props.top || "40px"};    
    position: absolute;
  }
`;

export const CardHeader = styled.h3`
  margin-left: 85px;
  margin-top: 10px;
`;

export const CardMessage = styled.span`
  float: right;
  width: 1000px;
  margin: 18px 24px 8px 0px;
  font-size: 16px;
  color: red;
`;

export const CardLetter = styled.span`
  font-size: 26px;
  font-weight: 500;
  color: #FFF;
  padding-left: 14px;
`;

export const CardLetterBox = styled.div`
  position: absolute;
  color: white;
  background: #26565B;
  border-radius: 10px 0px 10px 0px;
  width: 100px;
  height: 45px;
  padding-top: 5px;
`;

export const AvatarCardEmployeePosition = styled.div`
  border-radius: 20px;
  width: 260px;
  padding: 5px;
  text-align: center;
  overflow-wrap: break-word;
`;

export const SolidInput = styled(Input).attrs({className: 'SolidInput'})`
  border-color: rgb(204, 204, 204);
  padding: 10px;
`;

export const NumberInput = styled(SolidInput).attrs({type: "number", className: 'NumberInput'})`
  ::-webkit-inner-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }
  ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }
  -moz-appearance: textfield;
`;

export const SettingsContainer = styled.div`
  background-color: white;
  padding: 0px 20px 20px 20px;
`;

export const InputCard = styled(Card)`
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  padding: 20px 50px 30px 50px;
  min-width: 464px;
  max-width: 464px;
  min-height: 50px;
`;

export const TransparentCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 50px 0px 50px;
  min-width: 464px;
  max-width: 464px;
  min-height: 40px;
`;

export const EmployeeNumberInput = styled(SolidInput)`
  width: 100px;
  float: right;
`;

export const StyledInputMask = styled(InputMask).attrs({ className: 'StyledInputMask' })`
  background-color: #FFF;
  width: 80px;
  height: 38px;
  padding: 10px;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
  border: 1px solid #E5E7f0;
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  &: focus {
    outline: none !important;
    border:1px solid #306970;
    box-shadow: 0 0 5px #306970;
  }
`;
export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 2em;
  background-color: #E2ECED;
  padding-left: 20px;
  padding-bottom: 10px;
`;
export const TextArea = styled.textarea`
    padding: 14px 20px;
    font-size: inherit;
    border: 1px solid rgb(229, 231, 240);
    border-radius: 4px;
    width: 100%;
    resize: vertical;
     width: inherit;
`;

export const WeekBox = styled.div`
    border: 1px solid #ccc;
    box-sizing: border-box;
    text-align: center;
    width: ${props => props.width}px;
    height: 30px;
    line-height: 30px;
    font-family: Montserrat Alternates;
    color: ${props => props.fontColor};
`;

export const WeekJumper = ({flipSize, setFlipSize, viewStartIndex, setViewStartIndex, nrInView, bottom="10px", marginLeft="20px",
                                jumpTitle="Hopp antall uker", totalNrOfColumns}) => {
    return (
        <div style={{ bottom: bottom, marginLeft: `${marginLeft}`}}>
            <Flex data-for="weekJumper"
                  data-tip={jumpTitle}>
                <FontAwesomeIcon
                    onClick={() => {
                        if(viewStartIndex > 0) {
                            setViewStartIndex(Math.max(viewStartIndex - parseInt(flipSize), 0))
                        }
                    }}
                    icon={faChevronLeft}
                    size="3x"
                    style={{ color: viewStartIndex > 0 ? "#26565B" : "#E5E7f0", cursor: "pointer"}} />
                <div style={{marginLeft: "10px", height: "50px"}}>
                    <Input
                        min={1}
                        max={52}
                        defaultValue={flipSize}
                        style={{height: "40px", width: "78px", fontSize: "20px", fontFamily: "Montserrat Alternates"}}
                        type="number" onBlur={(e) => {
                            e.target.value = Math.min(Math.max(1, e.target.value), 52);
                            setFlipSize(e.target.value);
                        }}>
                    </Input>
                </div>
                <FontAwesomeIcon
                    onClick={() => {
                        if(viewStartIndex < (totalNrOfColumns - nrInView)) {
                            let val = viewStartIndex + parseInt(flipSize);
                            setViewStartIndex(Math.min(totalNrOfColumns - nrInView, val));
                        }}}
                    icon={faChevronRight}
                    size="3x"
                    style={{marginLeft: "10px", color: viewStartIndex < (totalNrOfColumns - nrInView) ? "#26565B" : "#E5E7f0",  cursor: "pointer"}} />
            </Flex>
            <Tooltip id="weekJumper" />
        </div>
    )
}

export const PaginationJumper = ({
    value,
    onLeftChange,
    onRightChange,
    leftDisabled=false,
    rightDisabled=false
}) =>
    <Flex justifyContent='left' alignItems='center'>
        <Button
            primary
            disabled={leftDisabled}
            plain
            style={{ paddingRight: '1em' }}
            onClick={() => onLeftChange(value)}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <h4 style={{ margin: 0, textAlign: 'center' }}>
            {value}
        </h4>
        <Button
            primary
            disabled={rightDisabled}
            plain
            style={{ paddingLeft: '1em' }}
            onClick={() => onRightChange(value)}>
            <FontAwesomeIcon icon={faChevronRight} />
        </Button>
    </Flex>

export const TableLegenItem = styled.div`
    width: 310px;
    font-size: 11px;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: start;
`;

export const TableLegendBox = styled.div`
    width: 50px;
    height: 28px;
    border-left: unset;
    border-right: 1px solid rgb(232, 232, 232);
    border-top: unset;
    border-bottom: 1px solid rgb(232, 232, 232);
    text-align: center;
    vertical-align: middle;
    line-height: 28px;
    font-family: Roboto;
`;

export const TableLegendText = styled.div`
    padding-left: 5px;
    height: 28px;
    display: flex;
    align-items: center;
    width: 300px;
`;

export const MaterialUIRoundAddButton = ({
                                   primary = true,
                                   variant = "contained",
                                   size = "large",
                                   text = "Legg til",
                                   mt = "20px",
                                   mb = "20px",
                                   onClick,
                               }) => {
    if (typeof primary !== "boolean") {
        throw new Error("The param 'primary' must be a boolean.");
    }

    if (!['text', 'outlined', 'contained'].includes(variant)) {
        throw new Error("The param 'variant' must be one of the following: ['text', 'outlined', 'contained'].");
    }

    if (!['small', 'medium', 'large'].includes(size)) {
        throw new Error("The param 'sixe' must be one of the following: ['small', 'medium', 'large'].");
    }

    if (typeof text !== "string") {
        throw new Error("The param 'text' must be a String.");
    }

    if (typeof onClick !== "function") {
        throw new Error("The 'onClick' prop must be a function.");
    }

    return (
        <MuiButton
            primary={primary}
            variant={variant}
            size={size}
            style={{ marginTop: mt, marginBottom: mb }}
            sx={{ borderRadius: "20px", backgroundColor: "rgb(48, 105, 112)", textTransform: "none" }}
            onClick={onClick}
        >
            {text}
            <Add />
        </MuiButton>
    );
};

export const MaterialUIDeleteModal = ({
    open,
    onClose,
    title="Fjern",
    confirmationText="Er du sikker på at vil slette",
    deleteText="Slett",
    onConfirm,
    isLoading=false
}) => {
    if (typeof open !== "boolean") {
        throw new Error("The param 'open' must be a boolean.");
    }
    if (typeof onClose !== "function") {
        throw new Error("The param 'onClose' must be a function.");
    }
    if (typeof title !== "string") {
        throw new Error("The param 'title' must be a String.");
    }
    if (typeof confirmationText !== "string") {
        throw new Error("The param 'confirmationText' must be a String.");
    }
    if (typeof deleteText !== "string") {
        throw new Error("The param 'deleteText' must be a String.");
    }
    if (typeof onConfirm !== "function") {
        throw new Error("The param 'onConfirm' must be a function.");
    }
    if (typeof isLoading !== "boolean") {
        throw new Error("The param 'isLoading' must be a boolean.");
    }
    return <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="delete-modal-title"
    >
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2
            }}
        >
            <Typography id="delete-modal-title" variant="h6" component="h2" gutterBottom>{title}</Typography>
            <Typography>{confirmationText}</Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 3 }}>
                <MuiButton
                    sx={{ borderRadius: "20px", color: "rgb(48, 105, 112)", borderColor: "rgb(48, 105, 112)", textTransform: "none" }}
                    variant="outlined"
                    size="large"
                    onClick={onClose}
                >
                    Avbryt
                </MuiButton>
                <MuiButton
                    size="large"
                    sx={{ borderRadius: "20px", textTransform: "none" }}
                    loading={isLoading}
                    variant="contained"
                    color="error"
                    onClick={onConfirm}
                >
                    {deleteText}
                </MuiButton>
            </Box>
        </Box>
    </Modal>
}

export const MaterialUIAddModal = ({
    open,
    onClose,
    title="",
    onConfirm,
    isLoading=false,
    children
}) => {
    if (typeof open !== "boolean") {
        throw new Error("The param 'open' must be a boolean.");
    }
    if (typeof onClose !== "function") {
        throw new Error("The param 'onClose' must be a function.");
    }
    if (typeof title !== "string") {
        throw new Error("The param 'title' must be a String.");
    }
    if (typeof onConfirm !== "function") {
        throw new Error("The param 'onConfirm' must be a function.");
    }
    if (typeof isLoading !== "boolean") {
        throw new Error("The param 'isLoading' must be a boolean.");
    }
    if (children instanceof HTMLElement) {
        throw new Error("The param 'children' must be html.");
    }
    return <Modal open={open} onClose={onClose} aria-labelledby="put-modal-title">
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,
                maxHeight: "90vh",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                overflowY: "auto",
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Stack spacing={2}>
                <Typography id="put-modal-title" variant="h6" component="h2" gutterBottom>
                    {title}
                </Typography>
                <div>{children}
                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 3 }}>
                        <MuiButton
                            variant="outlined"
                            size="large"
                            sx={{ borderRadius: "20px", color: "rgb(48, 105, 112)", borderColor: "rgb(48, 105, 112)", textTransform: "none" }}
                            onClick={onClose}
                        >
                            Avbryt
                        </MuiButton>
                        <MuiButton
                            loading={isLoading}
                            style={{marginLeft: "10px"}}
                            sx={{ borderRadius: "20px", backgroundColor: "rgb(48, 105, 112)", textTransform: "none" }}
                            variant="contained"
                            color="info"
                            size="large"
                            onClick={onConfirm}
                        >
                            Lagre
                        </MuiButton>
                    </Box>
                </div>
            </Stack>
        </Box>
    </Modal>
}

export const MaterialUITabs = ({
                                   activeIndex,
                                   onChange,
                                   tabDescriptions,
                                   tabWidth="230px"
                               }) => {
    if (typeof activeIndex !== "string") {
        throw new Error("The param 'activeIndex' must be a String.");
    }
    if (typeof onChange !== "function") {
        throw new Error("The param 'onChange' must be a function.");
    }
    if (typeof tabDescriptions !== 'object' || tabDescriptions === null || Array.isArray(tabDescriptions)) {
        throw new TypeError("The param 'tabDescriptions' must be a key value object of strings.");
    }
    for (const [key, value] of Object.entries(tabDescriptions)) {
        if (typeof key !== 'string' || typeof value !== 'string') {
            throw new TypeError("The param 'tabDescriptions' must be a key value object of strings.");
        }
    }
    return <Tabs
        style={{marginLeft: "10px"}}
        sx = {{
            "& .MuiTabs-indicator": {
                backgroundColor: "rgb(48, 105, 112)",
                height: "4px",
            },
        }}
        value={activeIndex} onChange={onChange}>
        {Object.entries(tabDescriptions).map(([value, label]) => {
            return (<Tab
                TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
                key={label}
                style={{color: activeIndex !== value ? "white" : "rgb(48, 105, 112)"}}
                sx={{
                    border: activeIndex !== value ? "2px solid rgb(48, 105, 112)" : "2px solid transparent",
                    borderRadius: 2,
                    backgroundColor: activeIndex !== value ? "rgb(48, 105, 112)" : "transparent",
                    mx: 1,
                    px: 2,
                    width: tabWidth,
                    height: "60px",
                    textTransform: "none",
                    fontSize: "18px"
                }}
                value={value} label={label} />)
        })}
    </Tabs>
}