import React, {useEffect, useState} from "react";
import {dynoapeAPI, rawDynoapeAPI} from "../api/dynoape";
import styled from "styled-components";
import Icon from "../lib/icon";
import Tooltip from "../lib/tooltip";
import {useParams} from "react-router-dom";
import {MaterialReactTable} from "material-react-table";
import {customMaterialReactTableLocalization} from "../lib/common";
import Spinner from 'react-spinner-material';
import {MaterialUIAddModal, MaterialUIDeleteModal, MaterialUIRoundAddButton} from "../lib/styled-components";
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";

export const DepartmentSharing = () => {
    const { departmentId } = useParams();
    const [sharedInfo, setSharedInfo] = useState(undefined);
    const [sharedInfoWithEmails, setSharedInfoWithEmails] = useState(false);
    const [loadError, setLoadError] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [sharingInput, setSharingInput] = useState({email: undefined, permissions: ["DEPARTMENT_READ"]});
    const [showShareModal, setShowShareModal] = useState({show: false, id: undefined});
    const [showDeleteModal, setShowDeleteModal] = useState({show: false, id: undefined, email: undefined});

    const resetToDefault = () => {
        setSharingInput({email: undefined, permissions: ["DEPARTMENT_READ"]});
        setShowShareModal({show: false, id: undefined});
        setShowDeleteModal({show: false, id: undefined, email: undefined});
    }

    const getData = async (includeEmail) => {
        setIsLoading(true);
        const response = await rawDynoapeAPI.get(`api/v1/department/${departmentId}/shared-info?includeUserEmail=${includeEmail}`);
        if(response.status === 200) {
            setSharedInfo(response.data.body)
        } else {
            setLoadError(true)
        }
        setIsLoading(false)
        setSharedInfoWithEmails(includeEmail);
    }

    const save = async () => {
        setIsLoading(true)
        const body = {...sharingInput};
        if(body.permissions.includes("DEPARTMENT_OWNER")) body.permissions = ["DEPARTMENT_OWNER"]
        !showShareModal.id
            ? await dynoapeAPI.post(`api/v1/department/${departmentId}/share`, body)
            : await dynoapeAPI.put(`api/v1/department/${departmentId}/share/${showShareModal.id}`, {elements: body.permissions});
        await getData(true);
        resetToDefault();
        setIsLoading(false);
    }

    const del = async () => {
        await dynoapeAPI.delete(`api/v1/department/${departmentId}/share/${showDeleteModal.id}`)
        await getData(true)
        resetToDefault()
    }

    useEffect(() => {
        getData(false);
    }, []);

    const permissions = {
        DEPARTMENT_READ: "Lesetilgang",
        DEPARTMENT_UPDATE: "Skrivetilgang",
        DEPARTMENT_OWNER: "Eiertilgang"
    }

    const columns = [
        {
            accessorKey: 'email',
            header: 'E-post',
            size: 150,
            muiTableBodyCellProps: {
                sx: {
                    paddingLeft: '50px',
                },
            },
            muiTableHeadCellProps: {
                sx: {
                    paddingLeft: '50px',
                },
            },
            Cell: ({ cell, row }) =>
                <span>
                    <Info>
                        {row.original.userDeleted ? <i>Bruker slettet</i> : cell.getValue()}
                    </Info>
                </span>
        },
        {
            accessorKey: 'permissions',
            header: 'Tilganger',
            size: 200,
            Cell: ({ cell }) =>
                <span>
                    <Info>
                        {cell.getValue().map(p => permissions[p]).join(", ")}
                    </Info>
                </span>,
            enableSorting: false,
            filterFn: (row, columnId, filterValue) => {
                return row.getValue(columnId)
                    .filter(p => String(permissions[p]).toLowerCase().includes(String(filterValue).toLowerCase()))
                    .length > 0
            }
        },
        {
            header: 'Handlinger',
            size: 50,
            Cell: ({ row }) => (
                <>
                    <Button
                        variant="contained"
                        data-testid={`update-${row.original.id}`}
                        sx={{backgroundColor: "rgb(48, 105, 112)", borderRadius: "20px"}}
                        onClick={() => {
                            setSharingInput({email: row.original.email, permissions: row.original.permissions})
                            setShowShareModal({show: true, id: row.original.id})
                        }}
                        style={{ marginRight: "5px"}}
                    >
                        <Icon name="edit-w" />
                    </Button>
                    <Button
                        variant="contained"
                        sx={{borderRadius: "20px"}}
                        data-testid={`delete-${row.original.id}`}
                        color="error"
                        onClick={() => setShowDeleteModal({ "show": true, "id": row.original.id, email: row.original.email })}
                    >
                        <Icon name="trash-w"/>
                    </Button>
                </>
            ),
        }
    ]

    if(isLoading) return (<Spinner radius={120} color={"#333"} stroke={2} visible={true} />)

    return (
        <div>
            <h3>Brukere med tilgang</h3>
            {loadError &&
                <BigInfo style={{color: "red"}}>Du har ikke tilgang til å se hvem denne avdelingen er delt med</BigInfo>
            }
            {sharedInfo !== undefined &&
                <div>
                    <MaterialUIRoundAddButton
                        data-for="shareButton"
                        data-tip="Trykk på denne hvis du ønsker å gi en bruker tilgang til din avdeling"
                        text={"Del med bruker"}
                        onClick={() => setShowShareModal({show: true})}
                    />
                    <Tooltip id="shareButton" />
                    <hr/>

                    <BigInfo>Denne avdelingen er delt med {sharedInfo.elements.length} bruker(e)</BigInfo>
                    <Button
                        data-tip="Trykk her hvis du ønsker å se hvilke brukere avdelingen din er delt med"
                        data-for="showUsers"
                        primary={true}
                        color={"inherit"}
                        variant={"text"}
                        size={"medium"}
                        style={{ marginLeft: "-8px", marginTop: "10px", marginBottom: "10px", textTransform: "none" }}
                        onClick={() => getData(true)}
                    >
                        <BigInfo>Last inn brukere </BigInfo>
                    </Button>
                    <Tooltip id="showUsers" />

                    {sharedInfoWithEmails &&
                        <MaterialReactTable
                            columns={columns}
                            data={sharedInfo.elements}
                            localization={customMaterialReactTableLocalization}
                            filterFns={{myCustomFilterFn: (row, _, filterValue) => {
                                return String(row.original.email).toLowerCase().includes(String(filterValue).toLowerCase())
                                    || row.original.permissions
                                        .filter(p => String(permissions[p])
                                            .toLowerCase()
                                            .includes(String(filterValue).toLowerCase()))
                                        .length >0
                                }}}
                            globalFilterFn='myCustomFilterFn'
                        />
                    }
                </div>
            }

            <MaterialUIAddModal
                open={showShareModal.show}
                onClose={() => resetToDefault()}
                title={showShareModal.id !== undefined ? "Endre tilganger" : "Legg til ny tilgant"}
                onConfirm={() => save()}
            >
                <div style={{marginBottom: "10px"}}>
                    <div>
                        <p><label htmlFor={"emailInput"}>E-post</label></p>
                        <TextField
                            style={{width: "300px"}}
                            sx={{
                                input: {
                                    height: "5px",
                                    width: "300px",
                                    borderColor: "#d3d3d34f",
                                    backgroundColor: `${showShareModal.id ? "#d3d3d34f": ""}`
                                }
                            }}
                            type="email"
                            data-testid={`email-input`}
                            data-for="emailInput"
                            data-tip="Skriv inn epost-adresse"
                            id={"emailInput"}
                            disabled={isLoading || showShareModal.id}
                            value={sharingInput.email}
                            onChange={(e) => setSharingInput({...sharingInput, email: (e.target.value + "").toLowerCase()})}
                        ></TextField>
                        <Tooltip id="emailInput" />
                        <p style={{marginTop: "30px"}}><label htmlFor={"permissionInput"}>Velg tilganger</label></p>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {Object.entries(permissions).map(([name, transl]) => {
                                return (<div
                                    key={`${name}-input`}
                                    data-for={`${name}-input`}
                                    data-tip={`Klikk på denne for å velge ${transl.toLowerCase()}`}>
                                    <FormControlLabel
                                        style={{marginBottom: "10px"}}
                                        control={
                                            <Checkbox
                                                checked={sharingInput.permissions.includes(name)}
                                                onChange={(e) => {
                                                    let sharing = {...sharingInput,
                                                        permissions:
                                                            sharingInput.permissions.includes(name)
                                                                ? sharingInput.permissions.filter(p => p !== name)
                                                                : [...sharingInput.permissions, name]}
                                                    if(name === 'DEPARTMENT_OWNER' && e.target.checked) {
                                                        sharing.permissions = ['DEPARTMENT_OWNER'];
                                                    }
                                                    if(name !== 'DEPARTMENT_OWNER' && e.target.checked) {
                                                        sharing.permissions = sharing
                                                            .permissions
                                                            .filter(p => p !== 'DEPARTMENT_OWNER')
                                                    }
                                                    setSharingInput(sharing)
                                                }}
                                            />
                                        }
                                        label={`${transl}`}
                                    />
                                    <Tooltip id={`${name}-input`} />
                                </div>)
                            })}
                        </div>
                    </div>
                    {sharingInput.permissions.includes("DEPARTMENT_OWNER") &&
                        <p style={{color: "red"}}>
                            <Info>
                                <b>NB!</b> Ved å velge eiertilgang, vil bruker få full
                                lese -og skriverett til hele avdelingen. Dette inkluderer
                                også tilgang til epost-adressene til brukerne som denne
                                avdelingen er delt med.
                            </Info>
                        </p>
                    }
                    <p><Info><b>Husk!</b> For at en bruker skal ha skrivetilgang, må også lesetilgang gis.</Info></p>
                </div>
            </MaterialUIAddModal>
            <MaterialUIDeleteModal
                open={showDeleteModal.show}
                onClose={() => setShowDeleteModal({ show: false, id: undefined })}
                title={"Fjern tilganger"}
                confirmationText={`Er du sikker på at du vil fjerne tilgangene til ${showDeleteModal.email}?`}
                deleteText={"Ja, fjern tilganger"}
                onConfirm={() => del()}
                isLoading={isLoading}
            />
        </div>
    )
}

const BigInfo = styled.div`
  font-size: 18px;
`;

const Info = styled.div`
  font-size: 14px;
`;